import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, GridContainer, GridItem, Modal, TextField } from '../../../components/shared';
import { Box, Grid, Chip } from '@mui/material';
import { MESSAGE } from '../../../constants/message';
import { Add } from '@mui/icons-material';
import ConfirmationModal from '../../../components/ConfirmationModel';
import { useUpdateVendorByIdMutation } from '../../../services/Vendors/VendorService';
import { emailValidationSchema } from '../../../schema/validationSchemas';

const EditVendor = ({ selectedVendor, handleClose, handleConfirm }) => {
  const [customerName, setCustomerName] = useState(selectedVendor?.customerName || '');
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState(new Set());
  const [emailToDelete, setEmailToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [updateVendorById] = useUpdateVendorByIdMutation();

  useEffect(() => {
    if (selectedVendor?.emails) {
      const emailList = selectedVendor.emails.split(',').map((email) => email.trim());
      setEmails(new Set(emailList));
    }
  }, [selectedVendor]);

  const handleAddEmail = useCallback(() => {
    const trimmedEmail = email.trim();
    if (!trimmedEmail) return;

    if (emails.has(trimmedEmail)) {
      setEmailErrorMessage('This email is already available.');
      return;
    }

    setEmails((prev) => new Set([...prev, trimmedEmail]));
    setEmail('');
    setEmailErrorMessage('');
  }, [email, emails]);

  const handleDeleteEmail = useCallback((email) => {
    setEmailToDelete(email);
    setShowDeleteModal(true);
  }, []);

  const onDeleteModalClickHandler = useCallback(
    (value) => {
      if (value === MESSAGE.DELETE) {
        setEmails((prev) => {
          const newEmails = new Set(prev);
          newEmails.delete(emailToDelete);
          return newEmails;
        });
      }
      setEmailToDelete(null);
      setShowDeleteModal(false);
    },
    [emailToDelete],
  );

  const handleEditEmail = useCallback(
    (emailToEdit) => {
      if (email.trim()) {
        setEmailErrorMessage('Please add the email before editing another.');
        return;
      }
      setEmail(emailToEdit);
      setEmails((prev) => {
        const newEmails = new Set(prev);
        newEmails.delete(emailToEdit);
        return newEmails;
      });
      setEmailErrorMessage('');
    },
    [email],
  );

  const handleSave = async () => {
    if (customerName.trim() === '') {
      setErrorMessage('This is a required field.');
      return;
    }

    if (email.trim()) {
      setEmailErrorMessage('Please add the email before saving.');
      return;
    }

    const updatedVendorData = {
      customerName: customerName.trim(),
      emails: [...emails].map((email) => ({ email })),
      vendorCode: selectedVendor.vendorCode,
    };

    try {
      const response = await updateVendorById({ id: selectedVendor.id, ...updatedVendorData });

      if (response.error?.status === 409) {
        setErrorMessage(response.error.data?.message || 'Conflict error: Vendor already exists.');
      } else {
        handleConfirm();
      }
    } catch (error) {
      console.error('Error updating vendor:', error);
    }
  };

  const disableAddEmail = useMemo(() => {
    const trimmedEmail = email.trim();
    return !trimmedEmail || !emailValidationSchema.isValidSync(trimmedEmail);
  }, [email]);

  const hasChanges = useMemo(() => {
    const initialEmails = new Set(
      (selectedVendor?.emails || '')
        .split(',')
        .filter(Boolean)
        .map((email) => email.trim()),
    );
    return customerName.trim() !== (selectedVendor?.customerName?.trim() || '') || !areSetsEqual(emails, initialEmails);
  }, [customerName, emails, selectedVendor]);

  function areSetsEqual(set1, set2) {
    if (set1.size !== set2.size) return false;
    for (let item of set1) {
      if (!set2.has(item)) return false;
    }
    return true;
  }

  return (
    <>
      <Modal
        open={Boolean(selectedVendor)}
        onClose={handleClose}
        title="Edit Vendor"
        titleVariant="subtitle10"
        actionButtons={
          <Box my={3}>
            <Button variant="contained" size="large" onClick={handleSave} disabled={!hasChanges}>
              {MESSAGE.CONFIRM}
            </Button>
            <Button size="large" onClick={handleClose} variant="text">
              {MESSAGE.CANCEL}
            </Button>
          </Box>
        }
        maxWidth="md"
      >
        <Grid>
          <GridContainer spacing={{ xs: 1, sm: 2, md: 3 }}>
            <GridItem xs={12} md={6}>
              <TextField
                label="Vendor Name"
                variant="outlined"
                InputProps={{ 'data-testid': 'form-elements' }}
                value={customerName}
                onChange={(e) => {
                  setCustomerName(e.target.value);
                  setErrorMessage('');
                }}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Vendor Code"
                variant="outlined"
                InputProps={{ 'data-testid': 'form-elements' }}
                value={selectedVendor?.vendorCode}
                disabled
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailErrorMessage('');
                }}
                error={Boolean(emailErrorMessage)}
                helperText={emailErrorMessage}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={handleAddEmail}
                style={{ marginLeft: '10px' }}
                disabled={disableAddEmail}
              >
                Add Email
              </Button>
            </GridItem>
            <GridItem xs={12}>
              {[...emails].map((email, index) => (
                <Chip
                  key={index}
                  label={email}
                  onClick={() => handleEditEmail(email)}
                  onDelete={() => handleDeleteEmail(email)}
                  sx={{ margin: '2px' }}
                />
              ))}
            </GridItem>
          </GridContainer>
        </Grid>
      </Modal>

      <ConfirmationModal
        isOpen={showDeleteModal}
        title={MESSAGE.CONFIRMATION}
        msg={`Are you sure you want to delete email ${emailToDelete}?`}
        buttons={[
          { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
          { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
        ]}
        onClick={onDeleteModalClickHandler}
      />
    </>
  );
};

export default React.memo(EditVendor);
