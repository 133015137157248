import * as Yup from 'yup';
import { MESSAGE } from '../constants/message';
import { isArrayWithLength } from '../utils/common-methods';
import { REGEX } from '../constants/regexConstants';
import { METER_TYPES } from '../constants/MeterConstants';
import dayjs from 'dayjs';
import { INDEPENDENT_REP_OF_SUBLOCATION } from '../constants/CommonConstants';

const emptyStringToZero = (value) => (isNaN(value) ? 0 : value);
const {
  REQUIRED,
  VALID_EMAIL,
  VALID_PHONE_NUMBER,
  INVALID_DATE,
  INVALID_TIME,
  INVALID_YEAR,
  ADD_COLLECTION_DATA_NOT_ENTERED,
  REQUIRED_MIN_NUMBER,
  RANGE_NOT_REQUIRED,
  REQUIRED_MORE_THAN_NUMBER,
  DATE_COMPARISON,
} = MESSAGE;

const STRING_VALIDATION = {
  REQUIRED: Yup.string().trim().required(REQUIRED),
  NOT_REQUIRED: Yup.string().notRequired(),
};

const BOOLEAN_VALIDATION = {
  REQUIRED: Yup.boolean()
    .transform((value) => Boolean(value))
    .required(REQUIRED),
  NOT_REQUIRED: Yup.boolean()
    .transform((value) => Boolean(value))
    .notRequired(),
};

const PHONE_VALIDATION = {
  REQUIRED: Yup.string().trim().min(12, VALID_PHONE_NUMBER).required(REQUIRED),
  NOT_REQUIRED: Yup.string().trim().min(12, VALID_PHONE_NUMBER).notRequired(),
};

const EMAIL_VALIDATION = {
  REQUIRED: Yup.string().email(VALID_EMAIL).required(VALID_EMAIL),
  NOT_REQUIRED: Yup.string().email(VALID_EMAIL).notRequired(),
};

const DATE_VALIDATION = {
  REQUIRED: Yup.date(INVALID_DATE).typeError(INVALID_DATE).nullable().required(REQUIRED),
  NOT_REQUIRED: Yup.date(INVALID_DATE).typeError(INVALID_DATE).nullable().notRequired(),
  REQUIRED_YEAR: Yup.date(INVALID_YEAR).typeError(INVALID_YEAR).nullable().required(REQUIRED),
  DATE_RANGE_VALIDATION: (name) =>
    Yup.date()
      .required(REQUIRED)
      .nullable()
      .typeError(INVALID_DATE)
      .test('', DATE_COMPARISON, (val, props) => {
        const parentValue = props.parent[name];
        const currentDate = dayjs(val);
        const prevDate = dayjs(parentValue);
        const dayAfter = prevDate.add(1, 'day');
        if (!dayAfter.isAfter(currentDate)) {
          return true;
        }
      }),
};

const HOUR_VALIDATION = {
  REQUIRED: Yup.date(INVALID_TIME).typeError(INVALID_TIME).required(REQUIRED),
};

const patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;

const NUMBER_VALIDATION = {
  REQUIRED: Yup.number().transform(emptyStringToZero).required(REQUIRED),
  NOT_REQUIRED: Yup.number().transform(emptyStringToZero).notRequired(),
  COLLECTION_REQUIRED: Yup.number().transform(emptyStringToZero).required(ADD_COLLECTION_DATA_NOT_ENTERED),
  MIN_NUMBER_NOT_REQUIRED: (number = 0) =>
    Yup.number()
      .transform(emptyStringToZero)
      .min(number, REQUIRED_MIN_NUMBER(number))
      .typeError(MESSAGE.REQUIRED_NUMBER)
      .notRequired(),
  MORE_THAN_NUMBER_REQUIRED: (number = 0) =>
    Yup.number()
      .transform(emptyStringToZero)
      .moreThan(number, REQUIRED_MORE_THAN_NUMBER(number))
      .typeError(MESSAGE.REQUIRED_NUMBER)
      .required(REQUIRED),
  MORE_THAN_NUMBER_NOT_REQUIRED: (number = 0) =>
    Yup.number()
      .transform(emptyStringToZero)
      .moreThan(number, REQUIRED_MORE_THAN_NUMBER(number))
      .typeError(MESSAGE.REQUIRED_NUMBER)
      .notRequired(),
  REQUIRED_WITH_POSITIVE_NUMBER: (number = 0) =>
    Yup.number().transform(emptyStringToZero).required(REQUIRED).positive().min(number, REQUIRED_MIN_NUMBER(number)),
  NOT_REQUIRED_WITH_POSITIVE_NUMBER: (number = 0) =>
    Yup.number().transform(emptyStringToZero).notRequired().positive().min(number, REQUIRED_MIN_NUMBER(number)),
  RANGE_NOT_REQUIRED: (number1 = 0, number2 = 0) =>
    Yup.number()
      .transform(emptyStringToZero)
      .integer()
      .min(number1, RANGE_NOT_REQUIRED(number1, number2))
      .max(number2, RANGE_NOT_REQUIRED(number1, number2))
      .notRequired(),
  RANGE_NOT_REQUIRED_NULLABLE: (number1 = 0, number2 = 0) =>
    Yup.number()
      .transform(emptyStringToZero)
      .integer()
      .min(number1, RANGE_NOT_REQUIRED(number1, number2))
      .max(number2, RANGE_NOT_REQUIRED(number1, number2))
      .notRequired()
      .nullable(),
  TWO_DIGITS_NOT_REQUIRED_NULLABLE: Yup.number()
    .transform(emptyStringToZero)
    .min(0, REQUIRED_MIN_NUMBER(0))
    .test('is-decimal', 'Values should have a maximum of two digits.', (val) => {
      if (val != undefined) {
        return patternTwoDigitsAfterComma.test(val);
      }
      return true;
    })
    .notRequired()
    .nullable(),
};

const ZIPCODE_VALIDATION = {
  REQUIRED: Yup.string().trim().required(REQUIRED),
  NOT_REQUIRED: Yup.string().trim().notRequired(),
};

export const emailValidationSchema = Yup.string().email(VALID_EMAIL);

export const AddNewLocationOwnerSchema = Yup.object().shape({
  name: STRING_VALIDATION.REQUIRED,
  address1: STRING_VALIDATION.REQUIRED,
  address2: STRING_VALIDATION.REQUIRED,
  countryId: STRING_VALIDATION.REQUIRED,
  stateId: STRING_VALIDATION.REQUIRED,
  cityId: STRING_VALIDATION.REQUIRED,
  postcode: STRING_VALIDATION.REQUIRED,
  phone1: PHONE_VALIDATION.REQUIRED,
  phone2: PHONE_VALIDATION.NOT_REQUIRED,
  email: EMAIL_VALIDATION.REQUIRED,
});

export const addMachineSchema = Yup.object().shape({
  title: STRING_VALIDATION.REQUIRED,
  statusId: NUMBER_VALIDATION.REQUIRED,
  modelId: Yup.object()
    .shape({
      text: STRING_VALIDATION.REQUIRED,
    })
    .nullable()
    .required(MESSAGE.REQUIRED),
  subLocationId: Yup.object()
    .nullable()
    .test('conditional-validation', function (value) {
      const { statusId } = this.parent;

      if (!statusId) return true; // Skip validation if statusId is not selected

      if ([1, 5].includes(statusId) && !value?.value) {
        return this.createError({ message: 'Sub Location is mandatory' });
      }

      if (statusId === 4 && value?.value) {
        return this.createError({ message: 'Sub Location must be removed for Archived status' });
      }

      return true; // Pass for other statuses
    }),
  costPerPlay: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  clicksPerPlay: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  price: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  installedDate: DATE_VALIDATION.REQUIRED,
});

export const machineUpdateSchema = Yup.object().shape({
  title: STRING_VALIDATION.REQUIRED,
  statusId: NUMBER_VALIDATION.REQUIRED,
  modelId: Yup.object()
    .shape({
      text: STRING_VALIDATION.REQUIRED,
      value: STRING_VALIDATION.REQUIRED,
    })
    .nullable()
    .required(MESSAGE.REQUIRED),
  subLocationId: Yup.object()
    .shape({
      text: STRING_VALIDATION.REQUIRED,
      value: STRING_VALIDATION.REQUIRED,
    })
    .nullable()
    .test('required-for-status', 'Sub Location is mandatory field', function (value) {
      const { statusId } = this.parent;
      if (statusId === 1 || statusId === 5) {
        return value?.value;
      }
      return true;
    })
    .test('clear-for-archive', 'Sub Location must be removed for Archived status.', function (value) {
      const { statusId } = this.parent;
      if (statusId === 4) {
        return value === null || value?.value === '';
      }
      return true;
    }),
  costPerPlay: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  clicksPerPlay: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  price: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  installedDate: DATE_VALIDATION.REQUIRED,
  legacyAssetId: STRING_VALIDATION.REQUIRED,
});

export const AddNewLocationSchema = {
  locationDetailsSchema: Yup.object().shape({
    locationName: STRING_VALIDATION.REQUIRED,
    locationType: NUMBER_VALIDATION.REQUIRED,
    zipCode: ZIPCODE_VALIDATION.NOT_REQUIRED,
    phone1: PHONE_VALIDATION.NOT_REQUIRED,
    phone2: PHONE_VALIDATION.NOT_REQUIRED,
    currency: NUMBER_VALIDATION.REQUIRED,
    country: NUMBER_VALIDATION.NOT_REQUIRED,
    state: NUMBER_VALIDATION.NOT_REQUIRED,
    city: NUMBER_VALIDATION.NOT_REQUIRED,
    address1: STRING_VALIDATION.NOT_REQUIRED,
    address2: STRING_VALIDATION.NOT_REQUIRED,
    taxExemptFlag: BOOLEAN_VALIDATION.NOT_REQUIRED,
    X3_LocationsID: STRING_VALIDATION.NOT_REQUIRED,
    X3_VendorsID: STRING_VALIDATION.NOT_REQUIRED,
    X3_LocationName: STRING_VALIDATION.NOT_REQUIRED,
    X3_DepartmentID: STRING_VALIDATION.NOT_REQUIRED,
    X3_IndustryID: STRING_VALIDATION.NOT_REQUIRED,
    X3_OwnerID: STRING_VALIDATION.NOT_REQUIRED,
  }),
  addSubLocationSchema: Yup.object().shape({
    subLocationName: STRING_VALIDATION.REQUIRED,
    subLocationType: NUMBER_VALIDATION.REQUIRED,
    address1: STRING_VALIDATION.NOT_REQUIRED,
    address2: STRING_VALIDATION.NOT_REQUIRED,
    phone1: PHONE_VALIDATION.NOT_REQUIRED,
    phone2: PHONE_VALIDATION.NOT_REQUIRED,
    openHours: HOUR_VALIDATION.NOT_REQUIRED,
    closeHours: HOUR_VALIDATION.NOT_REQUIRED,
    startDate: DATE_VALIDATION.NOT_REQUIRED,
    endDate: DATE_VALIDATION.NOT_REQUIRED,
    stateTaxRate: NUMBER_VALIDATION.NOT_REQUIRED,
    areaTaxRate: NUMBER_VALIDATION.NOT_REQUIRED,
    miscTax: NUMBER_VALIDATION.NOT_REQUIRED,
    productType: Yup.object()
      .shape({
        text: STRING_VALIDATION.REQUIRED,
      })
      .nullable()
      .required(MESSAGE.REQUIRED),
    areaTaxLocation: Yup.object()
      .shape({
        text: STRING_VALIDATION.NOT_REQUIRED,
        value: STRING_VALIDATION.NOT_REQUIRED,
      })
      .nullable(),
  }),
};

const LEASE_BEGINS_ENDS_DATE = {
  leaseBegins: DATE_VALIDATION.REQUIRED,
  leaseEnds: DATE_VALIDATION.DATE_RANGE_VALIDATION('leaseBegins'),
};

export const leaseSchema = {
  leaseDetailSchema: Yup.object().shape({
    leaseName: STRING_VALIDATION.REQUIRED,
    location: Yup.object()
      .shape({
        name: STRING_VALIDATION.REQUIRED,
      })
      .nullable()
      .required(MESSAGE.REQUIRED),
    subLocation: Yup.array()
      .of(
        Yup.object().shape({
          id: NUMBER_VALIDATION.REQUIRED,
          name: STRING_VALIDATION.REQUIRED,
        }),
      )
      .notRequired(),
    city: NUMBER_VALIDATION.NOT_REQUIRED,
    state: NUMBER_VALIDATION.NOT_REQUIRED,
    zipCode: NUMBER_VALIDATION.NOT_REQUIRED,
    address1: STRING_VALIDATION.NOT_REQUIRED,
    address2: STRING_VALIDATION.NOT_REQUIRED,
    country: NUMBER_VALIDATION.NOT_REQUIRED,
    boothInfo: NUMBER_VALIDATION.NOT_REQUIRED,
    space: NUMBER_VALIDATION.NOT_REQUIRED,
    dba: STRING_VALIDATION.NOT_REQUIRED,
    salesPerson: STRING_VALIDATION.NOT_REQUIRED,
    leaseType: STRING_VALIDATION.NOT_REQUIRED,
    status: STRING_VALIDATION.NOT_REQUIRED,
    ...LEASE_BEGINS_ENDS_DATE,
  }),
  rentDetailsSchema: Yup.object().shape({
    rentType: STRING_VALIDATION.REQUIRED,
    rentFee: Yup.number().when('rentType', {
      is: (rentType) => rentType !== 'RATE_ONLY',
      then: NUMBER_VALIDATION.REQUIRED_WITH_POSITIVE_NUMBER(0),
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
    rentCalculationTiming: STRING_VALIDATION.REQUIRED,
    rentRate: Yup.number().when('rentType', {
      is: (rentType) => rentType !== 'FEE_ONLY',
      then: NUMBER_VALIDATION.REQUIRED_WITH_POSITIVE_NUMBER(0),
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
    calculationStarts: DATE_VALIDATION.NOT_REQUIRED,
    calculationEnds: DATE_VALIDATION.NOT_REQUIRED,
    breakpointNatural: Yup.boolean().when('rentType', {
      is: (rentType) => rentType !== 'FEE_ONLY' && rentType !== 'RATE_ONLY',
      then: BOOLEAN_VALIDATION.REQUIRED,
      otherwise: BOOLEAN_VALIDATION.NOT_REQUIRED,
    }),
    breakpointValue: Yup.number().when('rentType', {
      is: (rentType) => rentType !== 'FEE_ONLY' && rentType !== 'RATE_ONLY',
      then: NUMBER_VALIDATION.REQUIRED_WITH_POSITIVE_NUMBER(0),
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
    CAMFee: NUMBER_VALIDATION.REQUIRED_WITH_POSITIVE_NUMBER(0),
    CAMRate: NUMBER_VALIDATION.REQUIRED_WITH_POSITIVE_NUMBER(0),
    modalTypes: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          name: Yup.string(),
          rate: NUMBER_VALIDATION.REQUIRED_WITH_POSITIVE_NUMBER(0),
        }),
      )
      .notRequired(),
    thresholdRevenue: NUMBER_VALIDATION.NOT_REQUIRED,
    coinCost: NUMBER_VALIDATION.TWO_DIGITS_NOT_REQUIRED_NULLABLE,
  }),
  generalDetailsSchema: Yup.object().shape({
    workersComp: BOOLEAN_VALIDATION.NOT_REQUIRED,
    genLiability: BOOLEAN_VALIDATION.NOT_REQUIRED,
    umbrella: BOOLEAN_VALIDATION.NOT_REQUIRED,
    terminationDays: NUMBER_VALIDATION.NOT_REQUIRED,
    relocationDays: NUMBER_VALIDATION.NOT_REQUIRED,
    salesReportsBy: STRING_VALIDATION.NOT_REQUIRED,
    percentRentBy: STRING_VALIDATION.NOT_REQUIRED,
    fixedRentDue: STRING_VALIDATION.NOT_REQUIRED,
    addendum: BOOLEAN_VALIDATION.NOT_REQUIRED,
    securityDeposit: NUMBER_VALIDATION.MORE_THAN_NUMBER_NOT_REQUIRED(0),
    grossSales: NUMBER_VALIDATION.MORE_THAN_NUMBER_NOT_REQUIRED(0),
    OCC: NUMBER_VALIDATION.MORE_THAN_NUMBER_NOT_REQUIRED(0),
  }),
  approvalRequestSchema: Yup.object().shape({
    ...LEASE_BEGINS_ENDS_DATE,
  }),
  terminationRequestSchema: Yup.object().shape({
    reason: STRING_VALIDATION.REQUIRED,
  }),
};

// Cash and bill use this schema
export const fourConditionSchema = (condition1, condition2, condition3, condition4) => {
  const billSchema = {
    [condition1]: Yup.number().when([condition2, condition3, condition4], {
      is: (condition2, condition3, condition4) => condition2 || condition3 || condition4,
      then: NUMBER_VALIDATION.COLLECTION_REQUIRED,
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
    [condition2]: Yup.number().when([condition1, condition3, condition4], {
      is: (condition1, condition3, condition4) => condition1 || condition3 || condition4,
      then: NUMBER_VALIDATION.COLLECTION_REQUIRED,
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
    [condition3]: Yup.number().when([condition1, condition2, condition4], {
      is: (condition1, condition2, condition4) => condition1 || condition2 || condition4,
      then: NUMBER_VALIDATION.COLLECTION_REQUIRED,
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
    [condition4]: Yup.number().when([condition1, condition2, condition3], {
      is: (condition1, condition2, condition3) => condition1 || condition3 || condition2,
      then: NUMBER_VALIDATION.COLLECTION_REQUIRED,
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
  };
  const dependency = [
    [condition1, condition2],
    [condition1, condition3],
    [condition1, condition4],
    [condition2, condition1],
    [condition2, condition3],
    [condition2, condition4],

    [condition3, condition1],
    [condition3, condition2],
    [condition3, condition4],

    [condition4, condition1],
    [condition4, condition2],
    [condition4, condition3],
  ];
  return { schema: billSchema, dependency: dependency };
};

// Coin and Token use this schema
export const threeConditionSchema = (condition1, condition2, condition3) => {
  const coinSchema = {
    [condition1]: Yup.number().when([condition2, condition3], {
      is: (condition2, condition3) => condition2 || condition3,
      then: NUMBER_VALIDATION.COLLECTION_REQUIRED,
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
    [condition2]: Yup.number().when([condition1, condition3], {
      is: (condition1, condition3) => condition1 || condition3,
      then: NUMBER_VALIDATION.COLLECTION_REQUIRED,
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
    [condition3]: Yup.number().when([condition1, condition2], {
      is: (condition1, condition2) => condition1 || condition2,
      then: NUMBER_VALIDATION.COLLECTION_REQUIRED,
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
  };
  const dependency = [
    [condition1, condition2],
    [condition1, condition3],
    [condition2, condition1],
    [condition2, condition3],
    [condition3, condition1],
    [condition3, condition2],
  ];
  return { schema: coinSchema, dependency: dependency };
};

// Prize schema
export const twoConditionSchema = (condition1, condition2) => {
  const coinSchema = {
    [condition1]: Yup.number().when([condition2], {
      is: (condition2) => condition2,
      then: NUMBER_VALIDATION.COLLECTION_REQUIRED,
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
    [condition2]: Yup.number().when([condition1], {
      is: (condition1) => condition1,
      then: NUMBER_VALIDATION.COLLECTION_REQUIRED,
      otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
    }),
  };
  const dependency = [
    [condition1, condition2],
    [condition2, condition1],
  ];
  return { schema: coinSchema, dependency: dependency };
};

export const AddNewModelSchema = Yup.object().shape({
  title: STRING_VALIDATION.REQUIRED,
  categoryId: STRING_VALIDATION.REQUIRED,
  yearMade: DATE_VALIDATION.REQUIRED_YEAR,
  height: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  weight: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  width: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
});

export const subLocAddUpdateSchema = Yup.object({
  name: STRING_VALIDATION.REQUIRED,
  zipCode: ZIPCODE_VALIDATION.NOT_REQUIRED,
  phone1: PHONE_VALIDATION.NOT_REQUIRED,
  phone2: PHONE_VALIDATION.NOT_REQUIRED,
  openHours: HOUR_VALIDATION.NOT_REQUIRED,
  closeHours: HOUR_VALIDATION.NOT_REQUIRED,
  type: STRING_VALIDATION.REQUIRED,
  startDate: DATE_VALIDATION.NOT_REQUIRED,
  endDate: DATE_VALIDATION.NOT_REQUIRED,
  address1: STRING_VALIDATION.NOT_REQUIRED,
  address2: STRING_VALIDATION.NOT_REQUIRED,
  stateTaxRate: NUMBER_VALIDATION.NOT_REQUIRED,
  areaTaxRate: NUMBER_VALIDATION.NOT_REQUIRED,
  miscTax: NUMBER_VALIDATION.NOT_REQUIRED,
  spaceIdentifier: STRING_VALIDATION.NOT_REQUIRED,
  productType: Yup.object()
    .shape({
      text: STRING_VALIDATION.REQUIRED,
    })
    .nullable()
    .required(MESSAGE.REQUIRED),
  areaTaxLocation: Yup.object()
    .shape({
      text: STRING_VALIDATION.NOT_REQUIRED,
      value: STRING_VALIDATION.NOT_REQUIRED,
    })
    .nullable(),
});
export const EditRemitSchema = Yup.object({
  firstName: STRING_VALIDATION.NOT_REQUIRED,
  lastName: STRING_VALIDATION.NOT_REQUIRED,
  zipCode: ZIPCODE_VALIDATION.NOT_REQUIRED,
  phone1: PHONE_VALIDATION.NOT_REQUIRED,
  phone2: PHONE_VALIDATION.NOT_REQUIRED,
  email: EMAIL_VALIDATION.NOT_REQUIRED,
  address1: STRING_VALIDATION.NOT_REQUIRED,
  address2: STRING_VALIDATION.NOT_REQUIRED,
  country: NUMBER_VALIDATION.NOT_REQUIRED,
  state: NUMBER_VALIDATION.NOT_REQUIRED,
  city: NUMBER_VALIDATION.NOT_REQUIRED,
});

export const editUserSchema = Yup.object().shape(
  {
    firstName: STRING_VALIDATION.NOT_REQUIRED,
    lastName: STRING_VALIDATION.NOT_REQUIRED,
    phone: PHONE_VALIDATION.NOT_REQUIRED.matches(REGEX.validPhNo, MESSAGE.VALID_PHONE_NUMBER_WITH_FORMAT),
    managementRole: Yup.number()
      .transform(emptyStringToZero)
      .when(['collectionRole'], {
        is: (collectionRole) => collectionRole && isArrayWithLength(collectionRole),
        then: Yup.number().transform(emptyStringToZero).notRequired(),
        otherwise: Yup.number()
          .transform(emptyStringToZero)
          .required('Either management role or collection role is a required field'),
      }),
    collectionRole: Yup.array()
      .of(
        Yup.object().shape({
          id: NUMBER_VALIDATION.REQUIRED,
          value: NUMBER_VALIDATION.REQUIRED,
          name: STRING_VALIDATION.REQUIRED,
        }),
      )
      .when(['managementRole'], {
        is: (managementRole) => managementRole && JSON.stringify(managementRole).length > 0,
        then: Yup.array()
          .of(
            Yup.object().shape({
              id: NUMBER_VALIDATION.REQUIRED,
              value: NUMBER_VALIDATION.REQUIRED,
              name: STRING_VALIDATION.REQUIRED,
            }),
          )
          .notRequired(),
        otherwise: Yup.array()
          .of(
            Yup.object().shape({
              id: NUMBER_VALIDATION.REQUIRED,
              value: NUMBER_VALIDATION.REQUIRED,
              name: STRING_VALIDATION.REQUIRED,
            }),
          )
          .required('Either Management Role or Collection Role is a required field'),
      }),
    country: NUMBER_VALIDATION.NOT_REQUIRED,
    state: NUMBER_VALIDATION.NOT_REQUIRED,
    city: NUMBER_VALIDATION.NOT_REQUIRED,
    status: Yup.bool(),
  },
  [['managementRole', 'collectionRole']],
);

export const locationUpdateSchema = Yup.object().shape(
  {
    locationName: STRING_VALIDATION.REQUIRED,
    postcode: STRING_VALIDATION.NOT_REQUIRED,
    address1: STRING_VALIDATION.NOT_REQUIRED,
    address2: STRING_VALIDATION.NOT_REQUIRED,
    phone1: PHONE_VALIDATION.NOT_REQUIRED,
    phone2: PHONE_VALIDATION.NOT_REQUIRED,
    currencyId: STRING_VALIDATION.REQUIRED,
    countryId: STRING_VALIDATION.NOT_REQUIRED,
    stateId: STRING_VALIDATION.NOT_REQUIRED,
    cityId: STRING_VALIDATION.NOT_REQUIRED,
    locationTypeId: STRING_VALIDATION.NOT_REQUIRED,
    X3_LocationsID: STRING_VALIDATION.NOT_REQUIRED,
    X3_VendorsID: STRING_VALIDATION.NOT_REQUIRED,
    X3_LocationName: STRING_VALIDATION.NOT_REQUIRED,
    X3_DepartmentID: STRING_VALIDATION.NOT_REQUIRED,
    X3_IndustryID: STRING_VALIDATION.NOT_REQUIRED,
    X3_OwnerID: STRING_VALIDATION.NOT_REQUIRED,
  },
  [],
);

export const meterUpdateSchema = Yup.object().shape({
  meterName: STRING_VALIDATION.NOT_REQUIRED,
  maxCount: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  denomination: Yup.object()
    .shape({
      text: STRING_VALIDATION.REQUIRED,
      value: STRING_VALIDATION.REQUIRED,
    })
    .when('meterName', (meterName, schema) => {
      if (meterName === METER_TYPES.TOKEN_CHANGER_METER) {
        return schema.nullable().required(MESSAGE.REQUIRED);
      } else {
        return schema.nullable().notRequired();
      }
    }),
  machineAcceptedPaymentType: Yup.object()
    .shape({
      text: STRING_VALIDATION.REQUIRED,
      value: STRING_VALIDATION.REQUIRED,
    })
    .when('meterName', (meterName, schema) => {
      if (meterName === METER_TYPES.TOKEN_CHANGER_METER) {
        return schema.nullable().required(MESSAGE.REQUIRED);
      } else {
        return schema.nullable().notRequired();
      }
    }),
  tokenPayout: Yup.number().when('meterName', (meterName) => {
    if (meterName === METER_TYPES.TOKEN_CHANGER_METER) {
      return NUMBER_VALIDATION.MORE_THAN_NUMBER_REQUIRED(0);
    } else {
      return NUMBER_VALIDATION.NOT_REQUIRED;
    }
  }),
});

export const addMeterSchema = Yup.object().shape({
  meters: Yup.object()
    .shape({
      text: STRING_VALIDATION.REQUIRED,
      value: STRING_VALIDATION.NOT_REQUIRED,
    })
    .nullable(),
  maxCount: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  currentMeter: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
  fieldArray: Yup.array().when('meters', (meters, schema) =>
    schema.of(
      Yup.object().shape({
        maxCount: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
        currentMeter: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
        denomination:
          meters?.text === METER_TYPES.TOKEN_CHANGER_METER
            ? Yup.object()
                .shape({
                  text: STRING_VALIDATION.REQUIRED,
                  value: STRING_VALIDATION.REQUIRED,
                })
                .nullable()
                .required(MESSAGE.REQUIRED)
            : Yup.object().nullable().notRequired(),
        type:
          meters?.text === METER_TYPES.TOKEN_CHANGER_METER
            ? Yup.object()
                .shape({
                  text: STRING_VALIDATION.REQUIRED,
                  value: STRING_VALIDATION.REQUIRED,
                })
                .nullable()
                .required(MESSAGE.REQUIRED)
            : Yup.object().nullable().notRequired(),
        tokenPayout:
          meters?.text === METER_TYPES.TOKEN_CHANGER_METER
            ? NUMBER_VALIDATION.MORE_THAN_NUMBER_REQUIRED(0)
            : NUMBER_VALIDATION.NOT_REQUIRED,
      }),
    ),
  ),
});

export const addNewAccountSummaryRow = Yup.object().shape({
  accountSummaryState: Yup.array()
    .of(
      Yup.object().shape(
        {
          accountNumber: Yup.object()
            .shape({
              value: NUMBER_VALIDATION.NOT_REQUIRED.when(['debit', 'credit', 'description'], {
                is: (debit, credit, description) => debit || credit || description,
                then: NUMBER_VALIDATION.REQUIRED,
                otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
              }),
            })
            .when(['debit', 'credit', 'description'], {
              is: (debit, credit, description) => Number(debit) || Number(credit) || description,
              then: (schema) => schema.nullable().required(REQUIRED),
              otherwise: (schema) => schema.nullable(),
            }),
          debit: NUMBER_VALIDATION.NOT_REQUIRED.when(['accountNumber', 'credit', 'description'], {
            is: (accountNumber, credit, description) => accountNumber || credit || description,
            then: NUMBER_VALIDATION.REQUIRED,
            otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
          }),
          credit: NUMBER_VALIDATION.NOT_REQUIRED.when(['debit', 'accountNumber', 'description'], {
            is: (debit, accountNumber, description) => debit || accountNumber || description,
            then: NUMBER_VALIDATION.REQUIRED,
            otherwise: NUMBER_VALIDATION.NOT_REQUIRED,
          }),
        },
        [
          ['debit', 'credit', 'description'],
          ['accountNumber', 'credit', 'description'],
          ['debit', 'accountNumber', 'description'],
        ],
      ),
    )
    .notRequired(),
});
export const modemUpdateSchema = Yup.object().shape({
  type: STRING_VALIDATION.REQUIRED,
  loginId: STRING_VALIDATION.NOT_REQUIRED,
  password: STRING_VALIDATION.NOT_REQUIRED,
  serialNumber: STRING_VALIDATION.REQUIRED,
  MACAddress: Yup.string().matches(REGEX.macAddress, MESSAGE.MAC_VALIDATION_MESSAGE),
  price: NUMBER_VALIDATION.MIN_NUMBER_NOT_REQUIRED(0),
});

export const AddNewModemSchema = Yup.object().shape({
  type: Yup.object()
    .shape({
      text: STRING_VALIDATION.REQUIRED,
      value: STRING_VALIDATION.REQUIRED,
    })
    .nullable()
    .required(MESSAGE.REQUIRED),
  loginId: STRING_VALIDATION.NOT_REQUIRED,
  password: STRING_VALIDATION.NOT_REQUIRED,
  serialNumber: STRING_VALIDATION.REQUIRED,
  installedDate: DATE_VALIDATION.NOT_REQUIRED,
});

export const addCurrencyRateSchema = Yup.object().shape({
  selectedCurrency: Yup.object()
    .shape({
      text: STRING_VALIDATION.REQUIRED,
      value: STRING_VALIDATION.REQUIRED,
    })
    .nullable()
    .required(MESSAGE.REQUIRED),
  exchangeRate: NUMBER_VALIDATION.REQUIRED,
});

export const reportModalSchemaForIssue = Yup.object().shape(
  {
    issue: Yup.string().when(['reason'], {
      is: (reason) => !!reason,
      then: STRING_VALIDATION.REQUIRED,
      otherwise: STRING_VALIDATION.NOT_REQUIRED,
    }),
    reason: Yup.string().when(['issue'], {
      is: (issue) => !!issue,
      then: STRING_VALIDATION.REQUIRED,
      otherwise: STRING_VALIDATION.NOT_REQUIRED,
    }),
  },
  [['issue', 'reason']],
);
export const reportModalSchemaForResolve = Yup.object().shape({
  issue: STRING_VALIDATION.NOT_REQUIRED,
  reason: STRING_VALIDATION.NOT_REQUIRED,
});

export const intercardValidationSchema = Yup.object().shape({
  fromDate: DATE_VALIDATION.REQUIRED,
  toDate: Yup.date()
    .required(MESSAGE.REQUIRED)
    .when('fromDate', (fromDate, Yup) => fromDate && Yup.min(fromDate, 'To date cannot be before from date')),
});

export const addMissingAssetsSchema = Yup.object().shape({
  assetIdsArr: Yup.array()
    .of(
      Yup.object().shape({
        id: NUMBER_VALIDATION.REQUIRED,
        name: STRING_VALIDATION.REQUIRED,
      }),
    )
    .min(1, REQUIRED)
    .required(),
});

export const getIndependentRepSchema = (sublocationId) => {
  const schema = {
    [`${INDEPENDENT_REP_OF_SUBLOCATION.FEE}_${sublocationId}`]: NUMBER_VALIDATION.NOT_REQUIRED_WITH_POSITIVE_NUMBER(0),
    [`${INDEPENDENT_REP_OF_SUBLOCATION.RATE}_${sublocationId}`]: NUMBER_VALIDATION.REQUIRED_WITH_POSITIVE_NUMBER(0),
  };
  return schema;
};
