const BREADCRUMB_NAMES = {
  USER: {
    USERS: 'Users',
    USER_DETAILS: 'User Details',
    EDIT_USERS: 'Edit User',
  },
  VENDOR: {
    VENDORS: 'Vendors',
    ADD_VENDOR: 'Add Vendor',
  },
  LOCATION: {
    LOCATION: 'Locations',
    ADD_LOCATION: 'Add New Location',
    LOCATION_DETAIL: 'Location Details',
    EDIT_LOCATION_DETAIL: 'Edit Locations Details',
    SUB_LOCATION_DETAIL: 'Sub Locations Details',
    EDIT_SUB_LOCATION_DETAIL: 'Edit Sub Location Details',
    EDIT_LEASE_AND_RENT: 'Edit Lease & Rent Details',
    EDIT_REMIT: 'Edit Remit Details',
  },
  LOCATION_OWNER: {
    LOCATION_OWNER: 'Location Owners',
    LOCATION_OWNER_DETAIL: 'Location Owner Details',
    EDIT_LOCATION_OWNER: 'Edit Location Owner',
  },
  MODEL: {
    MODEL: 'Models',
    MODEL_DETAIL: 'Model Details',
    EDIT_MODEL: 'Edit Model Details',
    ADD_MODEL: 'Add Model',
  },
  OPEN_COLLECTION: {
    OPEN_COLLECTION: 'Open Collections',
    ADD_COLLECTION: 'Add Collection',
  },
  RECONCILIATION_COLLECTION: {
    RECONCILIATION_COLLECTION_LIST: 'Reconciliation',
    RECONCILIATION_DETAIL: 'Reconciliation Details',
  },
  APPROVED_COLLECTION: {
    APPROVED_COLLECTION_LIST: 'Approved Collection',
    APPROVED_COLLECTION_DETAIL: 'Approved Collection Details',
  },
  DEPOSIT: {
    DEPOSIT: 'Deposit',
    RECONCILIATION_DETAIL: 'Reconciliation Detail',
    APPROVED_DETAIL: 'Approved Detail',
  },
  MODEM: {
    MODEM: 'Modems',
    MODEM_DETAIL: 'Modem Details',
    EDIT_MODEM: 'Edit Modem Details',
  },
  MACHINE: {
    MACHINE: 'Machines',
    MACHINE_DETAIL: 'Machine Details',
    EDIT_MACHINE: 'Edit Machine Details',
    EDIT_MODEM_DETAIL: 'Edit Modem Details',
    ADD_MACHINE: 'Add Machine',
  },
  LEASE: {
    LEASE: 'Lease',
    LEASE_DETAILS: 'Lease Details',
    EDIT_LEASE: 'Lease Details Edit',
    ADD_LEASE: 'Add New Lease',
  },
  SETTINGS: {
    SETTINGS: 'Settings',
    CURRENCY_RATES: 'Currency Rate',
    UPDATE_CURRENCY_RATES: 'Update Currency Rate',
    IMPORT_INTERCARD_FEED: 'Intercard Feed',
    UPDATE_STATE_TAX_RATE: 'Update State Tax rate',
    SEEDLIVE_FEED: 'Seedlive Feed',
  },
};

export default BREADCRUMB_NAMES;
