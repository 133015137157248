import { Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const FieldWarning = ({ field }) => (
  <Typography variant="body2" color="warning.main" display="flex" alignItems="center" sx={{ mt: 0.5 }}>
    <WarningAmberIcon fontSize="small" sx={{ mr: 0.5 }} />
    {`${field} selection is recommended.`}
  </Typography>
);

export default FieldWarning;
