import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '../TextField';
import { IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const Datepicker = (props) => {
  const {
    size = 'normal',
    error = false,
    readOnly = true,
    helperText = '',
    value,
    onChange,
    disabled,
    ...others
  } = props;

  const handleClear = (event) => {
    event.stopPropagation();
    onChange(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        {...others}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            size={size}
            {...params}
            error={error}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              readOnly: readOnly,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled && value && (
                    <IconButton size="small" onClick={handleClear} edge="end">
                      <ClearIcon />
                    </IconButton>
                  )}
                  {params.InputProps?.endAdornment}
                </InputAdornment>
              ),
            }}
            disabled={disabled}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;
