import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { Grid, Typography } from '@mui/material';
import { GridContainer, GridItem } from '../../../components/shared';
import useStyles from './style';
import { useSelector } from 'react-redux';
import {
  useUpdateNotesMutation,
  useGetAccountSummaryNotesHistoryQuery,
} from '../../../services/CollectionService/CollectionService';

import { getSortedAccountSummaryForApprovedTab } from '../../../utils/collection-methods';

const GLSummaryButtons = ({
  isEditing,
  setIsEditing,
  accountSummaryState,
  collectionId,
  sublocationId,
  handleAccountSummaryStateUpdate,
  isApproved,
  productCategories,
}) => {
  const { accountSummaryFormDirty } = useSelector((state) => state.reconciliationCollection);
  const classes = useStyles();
  const [showHistory, setShowHistory] = useState(false);
  const [initialNotes, setInitialNotes] = useState(accountSummaryState);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [updateNotes] = useUpdateNotesMutation();
  const [historyData, setHistoryData] = useState([]);

  // Store IDs in refs to persist them for refetch
  const storedCollectionId = useRef(null);
  const storedSublocationId = useRef(null);

  // Store IDs when they're initially available
  useEffect(() => {
    if (collectionId && sublocationId && !storedCollectionId.current && !storedSublocationId.current) {
      storedCollectionId.current = collectionId;
      storedSublocationId.current = sublocationId;
    }
  }, [collectionId, sublocationId]);

  // Use the stored IDs for the query if available, otherwise use the props
  const queryCollectionId = storedCollectionId.current || collectionId;
  const querySublocationId = storedSublocationId.current || sublocationId;
  const shouldFetchHistory = Boolean(queryCollectionId && querySublocationId);

  const { data, refetch } = useGetAccountSummaryNotesHistoryQuery(
    { collectionId: queryCollectionId, sublocationId: querySublocationId },
    { skip: !shouldFetchHistory },
  );
  useEffect(() => {
    if (data && data?.length > 0 && productCategories && productCategories?.length > 0) {
      const modifiedData = data.map((item) =>
        item.map((accountSummary) => {
          if (accountSummary.accountNumber && accountSummary.accountNumber.text) {
            if (accountSummary.accountNumber.text) {
              if (
                !accountSummary.accountNumber.text.startsWith('1') &&
                !accountSummary.accountNumber.text.startsWith('2') &&
                !accountSummary.accountNumber.text.startsWith('3')
              ) {
                const updatedAccountNumber = {
                  ...accountSummary.accountNumber,
                  text: accountSummary.accountNumber.text + productCategories[0].code,
                };
                return { ...accountSummary, accountNumber: updatedAccountNumber };
              } else {
                return accountSummary;
              }
            }
          }
          return accountSummary;
        }),
      );
      setHistoryData(modifiedData);
      setInitialNotes(getSortedAccountSummaryForApprovedTab(modifiedData?.[0]));
      handleAccountSummaryStateUpdate(getSortedAccountSummaryForApprovedTab(modifiedData?.[0]));
    }
  }, [data, productCategories]);

  const EditNotesHandler = () => {
    setInitialNotes(accountSummaryState);
    setIsEditing(false);
  };

  const UpdateNotesHandler = async (accountSummaryState) => {
    setIsEditing(true);
    if (!accountSummaryFormDirty) {
      return;
    }
    await updateNotes(accountSummaryState).unwrap();

    await refetch(); // Refetch after the update is successful
  };

  const cancelHandler = () => {
    handleAccountSummaryStateUpdate(initialNotes);
    setIsEditing(true);
  };

  const showHistoryHandler = () => {
    if (historyData?.length < 1) {
      return;
    }
    setShowHistory(true);
    setInitialNotes(accountSummaryState);
    const sortedAccountSummaryForApprovedTab = getSortedAccountSummaryForApprovedTab(historyData?.[0]);
    handleAccountSummaryStateUpdate(sortedAccountSummaryForApprovedTab);
    setHistoryIndex(0);
  };
  const latestHandler = () => {
    setShowHistory(false);
    handleAccountSummaryStateUpdate(initialNotes);
  };

  const prevHandler = () => {
    if (historyIndex < historyData.length - 1) {
      const sortedAccountSummaryForApprovedTab = getSortedAccountSummaryForApprovedTab(historyData?.[historyIndex + 1]);
      handleAccountSummaryStateUpdate(sortedAccountSummaryForApprovedTab);
      setHistoryIndex(historyIndex + 1);
    }
  };

  const nextHandler = () => {
    if (historyIndex > 0) {
      const sortedAccountSummaryForApprovedTab = getSortedAccountSummaryForApprovedTab(historyData?.[historyIndex - 1]);
      handleAccountSummaryStateUpdate(sortedAccountSummaryForApprovedTab);
      setHistoryIndex(historyIndex - 1);
    }
  };
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear().toString().slice(2);
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  formatDate(accountSummaryState[0]?.createdAt);

  return (
    <GridContainer item="true" xs={6} className={classes.editContainer}>
      {showHistory && (
        <GridItem className={classes.historyButtonsContainer}>
          <Grid className={classes.historyGridContainer}>
            <Typography className={classes.UpdatedByTimeContainerItems}>
              <span style={{ fontWeight: 'bold' }}>Updated By:&nbsp;</span> {accountSummaryState[0]?.userName}
            </Typography>
            <Typography className={classes.UpdatedByTimeContainerItems}>
              <span style={{ fontWeight: 'bold' }}>Update Date and Time:&nbsp;</span>
              {formatDate(accountSummaryState[0]?.createdAt)}
            </Typography>
          </Grid>
          <Grid className={classes.historyGridContainer}>
            <Button sx={{ color: historyIndex < data?.length - 1 ? 'black' : 'GrayText' }} onClick={prevHandler}>
              Prev
            </Button>
            <Button sx={{ color: historyIndex > 0 ? 'black' : 'GrayText' }} onClick={nextHandler}>
              Next
            </Button>
          </Grid>
        </GridItem>
      )}
      {showHistory ? (
        <Button color="primary" onClick={latestHandler}>
          Latest
        </Button>
      ) : (
        <>{isEditing && <Button onClick={showHistoryHandler}>History</Button>}</>
      )}
      {isApproved && !showHistory && (
        <>
          {isEditing ? (
            <Button variant="contained" color="primary" onClick={EditNotesHandler}>
              Edit
            </Button>
          ) : (
            <>
              <Button variant="contained" color="primary" onClick={() => UpdateNotesHandler(accountSummaryState)}>
                Update
              </Button>
              <Button color="primary" onClick={cancelHandler}>
                Cancel
              </Button>
            </>
          )}
        </>
      )}
    </GridContainer>
  );
};

export default GLSummaryButtons;
