import { Box, Link, Typography } from '@mui/material';
import React, { useMemo, useEffect, useState } from 'react';
import { Button, Breadcrumbs, ReactTable, NoContent } from '../../components/shared';
import BREADCRUMB_NAMES from '../../constants/breadcrumbNames';
import { routeConstants } from '../../constants/routeConstants';
import useStyles from './style';
import { getDateFormat } from '../../utils/common-methods';
import { useSeedliveProcessQuery } from '../../services/Seedlive/SeedliveService';
import { SEEDLIVE_TRACKINGS_TABLE } from '../../constants/tableColumns';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import LoadingSpinner from '../../components/LoadingSpinner';
import NewFilterDrawer from '../../components/shared/FilterDrawer/NewFilterDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { resetSeedliveFilterState, setSeedliveFilters } from '../../redux-slice/seedliveFilters';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../constants/FilterConstants';
import { useSearchFilterParam } from '../../hooks';
import { Download } from '@mui/icons-material';

const SeedliveFeed = () => {
  const classes = useStyles();
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [perPageNumber, setPerPageNumber] = useState(DEFAULT_PER_PAGE);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const { seedlive } = useSelector((state) => state.seedliveFilter);

  const {
    SETTINGS: { SEEDLIVE_FEED, SETTINGS },
  } = BREADCRUMB_NAMES;

  const breadcrumbData = useMemo(
    () => [{ text: SETTINGS, redirection: routeConstants.SETTING_ROUTE }, { text: SEEDLIVE_FEED }],
    [],
  );

  const COLLECTION_SEEDLIVE = useMemo(() => {
    const filters = {
      INITIAL_PARAMS: FILTER_DATA.SEEDLIVE_TRACKINGS.INITIAL_PARAMS,
      FILTER: FILTER_DATA.SEEDLIVE_TRACKINGS.FILTER,
    };
    return filters;
  }, []);

  const [filterParams, setFilterParams] = useState(COLLECTION_SEEDLIVE.INITIAL_PARAMS);

  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.SEEDLIVE_TRACKINGS,
    filterParams,
    searchText,
  });

  const {
    data: latestData,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    error,
  } = useSeedliveProcessQuery({
    searchParams: searchParams,
    page: currentPage,
    perPage: perPageNumber,
  });

  const column = React.useMemo(
    () => [
      ...SEEDLIVE_TRACKINGS_TABLE(),
      {
        Header: () => 'Action',
        id: 'view',
        Cell: ({ row }) => {
          const fileName = row.original.s3FileUrl ? row.original.s3FileUrl.split('/') : '';
          const extractFileName = fileName[fileName?.length - 1]?.split('?')[0]?.split('.')[0]?.split('_')?.join('');
          return row.original.s3FileUrl ? (
            <Link
              href={row.original.s3FileUrl}
              download
              title={extractFileName}
              target="_blank"
              rel="noreferrer"
              key={row.original.s3FileUrl}
            >
              <Download sx={{ marginLeft: '8px' }} />
            </Link>
          ) : (
            <Button sx={{ paddingLeft: 0 }} disabled>
              <Download />
            </Button>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (isSuccess && latestData) {
      const data = latestData.data.seedliveTracking.map((seedlive) => {
        const transformProcessData = { ...seedlive };
        transformProcessData.createdAt = seedlive?.createdAt ? getDateFormat(seedlive?.createdAt) : 'N/A';
        transformProcessData.s3FileName = seedlive?.s3FileName ? seedlive?.s3FileName.split('_')[1] : 'N/A';
        transformProcessData.status = filterParams[FILTER_VALUES.SEEDLIVE_MISSING_DATES] ? 'Missing' : 'Present';
        return transformProcessData;
      });
      setTableData(data);
      let total = latestData?.pagination?.total || 0;
      setTotalCount(total);
    } else if (isError) {
      setTotalCount(0);
    }
  }, [isError, isSuccess, latestData]);

  const pageChangeHandler = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    setPerPageNumber(newPerPage);
  };

  // const searchHandler = (params) => {
  //   setSearchText(params);
  //   dispatch(
  //     setSeedliveFilters({
  //       ...seedlive,
  //       searchText: params,
  //       pageNumber: DEFAULT_PAGE,
  //       rowsPerPage: DEFAULT_PER_PAGE,
  //     }),
  //   );
  // };

  const resetFilterParams = () => {
    setFilterParams(COLLECTION_SEEDLIVE.INITIAL_PARAMS);
    dispatch(resetSeedliveFilterState());
  };

  const filterData = useMemo(
    () =>
      COLLECTION_SEEDLIVE.FILTER.map((item) => ({
        ...item,
      })),
    [COLLECTION_SEEDLIVE],
  );

  useEffect(() => {
    const storedFilterParams = {
      [FILTER_VALUES.DATE_RANGE]: seedlive[FILTER_VALUES.DATE_RANGE],
      [FILTER_VALUES.SEEDLIVE_MISSING_DATES]: seedlive[FILTER_VALUES.SEEDLIVE_MISSING_DATES],
    };

    setSearchText(seedlive.searchText);
    setPerPageNumber(seedlive.rowsPerPage);
    setCurrentPage(seedlive.pageNumber);
    setFilterParams(storedFilterParams);
  }, [seedlive]);

  const onApplyFilterHandler = (v) => {
    const newFilterValue = { ...seedlive, ...v, pageNumber: DEFAULT_PAGE, rowsPerPage: DEFAULT_PER_PAGE };
    dispatch(setSeedliveFilters(newFilterValue));
    setFilterParams(newFilterValue);
  };

  // const onReset = () => {
  //   setSearchText('');
  //   dispatch(
  //     setSeedliveFilters({
  //       ...seedlive,
  //       searchText: '',
  //       pageNumber: DEFAULT_PAGE,
  //       rowsPerPage: DEFAULT_PER_PAGE,
  //     }),
  //   );
  // };

  return (
    <React.Fragment>
      <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      <Typography mt={'34px'} variant="h1">
        Seedlive Feed
      </Typography>
      <Box className={classes.searchFilterWrap}>
        <Box pl={9}></Box>
        <Box pl={3}>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            filterData={filterData}
            onApplyFilter={onApplyFilterHandler}
            emptyFilterLength={
              COLLECTION_SEEDLIVE.INITIAL_PARAMS ? JSON.stringify(COLLECTION_SEEDLIVE.INITIAL_PARAMS)?.length : 0
            }
          />
        </Box>
      </Box>
      <Box>
        {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
        {isError && (
          <NoContent
            title="No Seedlive Data Found"
            desc={(isError && error?.data?.message) || <>There are no seedlive processes present with this filter.</>}
          />
        )}
        {!isLoading && !isError && !isFetching && tableData && (
          <ReactTable
            data={tableData}
            columns={column}
            pagination
            manualPagination
            totalCount={totalCount}
            pageNumberForTablePagination={currentPage - 1}
            onPageChange={pageChangeHandler}
            onPerPageChange={perPageChangeHandler}
            perPage={perPageNumber}
            renderRows
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default SeedliveFeed;
