import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { VENDOR_STATUS } from '../../constants/CommonConstants';
export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getVendors: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.vendors}?perPage=${perPage || DEFAULT_PER_PAGE}&page=${
            page || DEFAULT_PAGE
          }&${searchParams}`;
        }
        return `${apiUrl.userList}`;
      },
      transformResponse: (responseData) => {
        const pagination = responseData?.pagination;
        const loadVendors = responseData?.data?.vendors;

        const transformedVendors = loadVendors.map((vendor) => {
          const sublocations =
            vendor?.vendorSublocations
              ?.filter((sublocation) => sublocation.x3VendorId === vendor.id)
              ?.map((sublocation) => ({
                id: sublocation.id,
                name: sublocation.name,
              })) || [];

          const formattedVendor = {
            id: vendor.id,
            customerName: vendor?.customerName,
            vendorCode: vendor?.vendorCode,
            status: vendor?.vendorStatus ? VENDOR_STATUS.ACTIVE : VENDOR_STATUS.ARCHIVE,
            sublocations,
            emails: Array.from(new Set(vendor.X3_vendorRentLetterEmails.map((emailObj) => emailObj.email)))
              .sort()
              .join(', '),
          };

          return formattedVendor;
        });
        return { vendors: transformedVendors, pagination };
      },
      providesTags: ['Vendors'],
    }),
    getVendorById: build.query({
      query: (id) => `${apiUrl.vendors}/${id}`,
      providesTags: ['Vendor'],
    }),
    updateVendorById: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.vendors}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Vendor', 'Vendors'],
    }),
    createVendor: build.mutation({
      // eslint-disable-next-line no-unused-vars
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.vendors}`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: ['Vendor', 'Vendors'],
    }),
    updateStatusVendorById: build.mutation({
      query: (id) => ({
        url: `${apiUrl.vendors}/${id}/status`,
        method: 'PUT',
      }),
      invalidatesTags: ['Vendor', 'Vendors'],
    }),
    deleteVendorById: build.mutation({
      query: (id) => ({
        url: `${apiUrl.vendors}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User', 'Users'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetVendorsQuery,
  useCreateVendorMutation,
  useGetVendorByIdQuery,
  useUpdateVendorByIdMutation,
  useDeleteVendorByIdMutation,
  useUpdateStatusVendorByIdMutation,
} = extendedApi;
