import React from 'react';
import { Box, Switch as MUISwitch, Typography } from '@mui/material';

const Switch = (props) => {
  const { title, ...rest } = props;

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      {title && (
        <Typography variant="buttonText" noWrap>
          {title}
        </Typography>
      )}
      <MUISwitch {...rest} />
    </Box>
  );
};

export default Switch;
