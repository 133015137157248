import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';
import {
  setCollection,
  setDraftSublocationDates,
  resetCollectionByLocationId,
  reduceDraftDatesBySublocationIds,
} from '../../redux-slice/storedCollections';
import {
  reduceDraftDatesBySublocationIdsInReconciliation,
  resetCollectionByTransactionIdInReconciliation,
  setDraftSublocationDatesInReconciliation,
  setReconciliationCollection,
} from '../../redux-slice/storedReconciliationCollections';
import { COLLECTION_TYPES } from '../../constants/CommonConstants';

const { PENDING: PENDING_COLLECTION_TYPE } = COLLECTION_TYPES;
const { RECONCILIATION: RECONCILIATION_COLLECTION_TYPE } = COLLECTION_TYPES;

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getDraftDetailsById: build.query({
      query: ({ userId, locationId }) => `${apiUrl.drafts}/${userId}_${locationId}`,

      transformResponse: (response) => {
        if (!Array.isArray(response) || response.length === 0) {
          return { error: 'Invalid or empty response data' };
        }

        const draft = response[0]; // Extract the first object from the array

        return {
          userId: draft.userId ?? null,
          locationId: draft.locationId ?? null,
          locationName: draft.locationName ?? '',
          formData: draft.formData ?? {},
          exchangeRate: draft.exchangeRate ?? 1,
          playcards: draft.playcards ?? {},
          intercardData: draft.intercardData ?? {},
          collectionType: draft.collectionType ?? PENDING_COLLECTION_TYPE,
          sublocationDates: draft.sublocationDates ?? {},
        };
      },

      async onQueryStarted({ locationId, subLocationIds }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.error || data?.status === 404) {
            dispatch(resetCollectionByLocationId(locationId));
          } else {
            dispatch(
              setCollection({
                locationId: data.locationId,
                formData: data.formData,
                exchangeRate: data.exchangeRate,
                playcards: data.playcards,
                intercardData: data.intercardData,
                locationName: data.locationName,
                collectionType: data.collectionType,
              }),
            );
            dispatch(setDraftSublocationDates(data.sublocationDates));
          }
        } catch (error) {
          dispatch(resetCollectionByLocationId(locationId));
          dispatch(reduceDraftDatesBySublocationIds(subLocationIds));
        }
      },

      providesTags: ['Drafts', 'Draft'],
    }),

    getReconciliationDraftDetailsById: build.query({
      query: ({ userId, locationId, transactionId }) =>
        `${apiUrl.drafts}/${apiUrl.reconciliation}/${userId}_${locationId}_${transactionId}`,
      transformResponse: (response) => {
        if (!Array.isArray(response) || response.length === 0) {
          return { error: 'Invalid or empty response data' };
        }

        const draft = response[0]; // Assuming the response always returns an array with a single object

        return {
          userId: draft.userId ?? null,
          locationId: draft.locationId ?? null,
          locationName: draft.locationName ?? '',
          formData: draft.formData ?? {},
          exchangeRate: draft.exchangeRate ?? 1,
          playcards: draft.playcards ?? {},
          intercardData: draft.intercardData ?? {},
          accountSummary: draft.accountSummary ?? {},
          collectionType: draft.collectionType ?? RECONCILIATION_COLLECTION_TYPE,
          transactionId: draft.transactionId ?? null,
          status: draft.status ?? '',
          sublocationDates: draft.sublocationDates ?? {},
        };
      },

      async onQueryStarted({ subLocationIds, transactionId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.status === 404) {
            dispatch(resetCollectionByTransactionIdInReconciliation(transactionId));
          } else {
            dispatch(
              setReconciliationCollection({
                locationId: data.locationId,
                locationName: data.locationName,
                formData: data.formData,
                exchangeRate: data.exchangeRate,
                playcards: data.playcards,
                intercardData: data.intercardData,
                accountSummary: data.accountSummary,
                collectionType: data.collectionType,
                transactionId: data.transactionId,
                status: data.status,
              }),
            );
            dispatch(setDraftSublocationDatesInReconciliation(data.sublocationDates));
          }
        } catch (error) {
          dispatch(resetCollectionByTransactionIdInReconciliation(transactionId));
          dispatch(reduceDraftDatesBySublocationIdsInReconciliation(subLocationIds));
        }
      },

      providesTags: ['Drafts', 'Draft'],
    }),

    addDraft: build.mutation({
      query: (data) => ({
        url: `${apiUrl.drafts}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Drafts', 'Draft'],
    }),
    addReconciliationDraft: build.mutation({
      query: (data) => ({
        url: `${apiUrl.drafts}/${apiUrl.reconciliation}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Drafts', 'Draft'],
    }),
    deleteDraftById: build.mutation({
      query: (id) => ({
        url: `${apiUrl.drafts}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Draft', 'Drafts'],
    }),
    deleteReconciliationDraftById: build.mutation({
      query: (id) => ({
        url: `${apiUrl.drafts}/${apiUrl.reconciliation}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Draft', 'Drafts'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDraftDetailsByIdQuery,
  useGetReconciliationDraftDetailsByIdQuery,
  useAddDraftMutation,
  useAddReconciliationDraftMutation,
  useDeleteDraftByIdMutation,
  useDeleteReconciliationDraftByIdMutation,
} = extendedApi;
