import { useCallback, useMemo } from 'react';
import { COLUMNS, SUBLOCATION_TABLE_DYNAMIC } from '../../../constants/tableColumns';
import ReactTable from '../../../components/shared/ReactTable';
import SublocationsAccordion from './SublocationsAccordion';
import { ControlledTooltips, Link, PositionedMenu } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getControlledTooltipValueAndCellValue } from '../../../utils/common-methods';
import { Add, PinDropOutlined } from '@mui/icons-material';
import useStyles from './style';

const SublocationTable = ({
  locationDetails = {},
  onUpdateSublocationDetail = () => {},
  onRemoveSublocationDetail = () => {},
  onAddNewBtnClick = () => {},
  disabled,
}) => {
  const { id } = useParams();
  const theme = useTheme();
  const classes = useStyles();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const COLUMN_SUB_LOCATION_NAME = {
    Header: 'SUB LOCATION NAME',
    accessor: 'name',
    width: 'auto',
    Cell: ({ row }) => (
      <Link underline="none" to={`/${routeConstants.SUBLOCATION_ROUTE}/${row.original.id}?locationId=${id}`}>
        <Box className={!row.original.status ? classes.archive : ''}>{row.original.name}</Box>
      </Link>
    ),
  };
  const COLUMN_MANAGER_NAME = {
    Header: 'MANAGER',
    accessor: 'tooltipValue',
    width: 'auto',
    Cell: ({ row }) => (
      <ControlledTooltips tooltipValue={row.original.tooltipValue}>
        <Box className={!row.original.status ? classes.archive : ''}>{row.original.manager}</Box>
      </ControlledTooltips>
    ),
  };
  const rowCell = (row, data) => (
    <Typography>
      <Box component="span" className={!row.original.status ? classes.archive : ''}>
        {row.original[data]}
      </Box>
    </Typography>
  );

  const METER_COLS = SUBLOCATION_TABLE_DYNAMIC(isBelowMD).map((col) => ({
    ...col,
    Cell: ({ row }) => rowCell(row, col.accessor),
  }));

  const COLUMN_POSITIONED_MENU = [
    {
      Header: () => null,
      id: 'more',
      width: 64,
      Cell: ({ row }) => (
        <PositionedMenu
          menuItemData={[
            {
              btnName: 'Edit',
              btnClickFunc: () => {
                onUpdateSublocationDetail(row.original);
              },
            },
            {
              btnName: !row.original.status ? 'Activate Sub Location' : 'Archive Sub Location',
              btnTypeStyle: !row.original.status ? 'successBtn' : 'dangerBtn',
              btnClickFunc: () => {
                const action = !row.original.status ? 'Activate' : 'Archive';
                onRemoveSublocationDetail(row.original, action);
              },
            },
          ]}
          disabled={disabled}
        />
      ),
    },
  ];

  const columns = useMemo(() => {
    const col = [
      COLUMNS.EXPAND_ICON_COLUMN,
      COLUMN_SUB_LOCATION_NAME,
      COLUMN_MANAGER_NAME,
      ...METER_COLS,
      ...COLUMNS.SUBLOCATION_TABLE_DEFAULT,
      ...COLUMN_POSITIONED_MENU,
    ];
    return col;
  }, [isBelowMD]);

  const viewManager = useCallback((users = []) => {
    const userNames = users?.map((value) => `${value.firstName} ${value?.lastName}`);
    return getControlledTooltipValueAndCellValue(userNames);
  }, []);

  const data = useMemo(() => {
    const tableData = [];
    locationDetails?.sublocations?.map((value) => {
      const { tooltipValue, cellValue } = viewManager(value?.users);
      tableData.push({
        ...value,
        noOfMachines: value?.assets?.length ?? 0,
        manager: cellValue,
        tooltipValue: tooltipValue,
        subLocationType: value.sublocationType?.name,
        legacysublocId: value?.legacysublocId,
      });
    });
    return tableData;
  }, [locationDetails]);

  const sublocationComponent = ({ row }) => {
    const original = row?.original;
    return original && <SublocationsAccordion sublocationInfo={original} />;
  };

  const AddBtn = () => (
    <Box className={classes.addBtn}>
      <Button
        variant="outlined"
        size="large"
        data-testid="add-sublocation"
        startIcon={<Add />}
        onClick={onAddNewBtnClick}
        disabled={disabled}
      >
        ADD NEW
      </Button>
    </Box>
  );

  return (
    <>
      {data && data.length > 0 && (
        <ReactTable
          data={data}
          columns={columns}
          stickyHeader
          tableHeight={500}
          expandable
          renderRowSubComponent={sublocationComponent}
          localFilter
          noContentIcon={<PinDropOutlined sx={{ color: theme.palette.primary.main }} />}
          searchPlaceHolder="Search by sub location and collection in change"
          extraBtn={<AddBtn />}
        />
      )}
    </>
  );
};

export default SublocationTable;
