import React, { useMemo, useState } from 'react';
import { Box, Grid, Chip, Paper } from '@mui/material';
import { Add } from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { emailValidationSchema } from '../../schema/validationSchemas';
import { MESSAGE } from '../../constants/message';
import { Breadcrumbs, Button, GridContainer, GridItem, TextField } from '../../components/shared';
import BREADCRUMB_NAMES from '../../constants/breadcrumbNames';
import { routeConstants } from '../../constants/routeConstants';
import { useNavigate } from 'react-router-dom';
import { useCreateVendorMutation } from '../../services/Vendors/VendorService';
import ConfirmationModal from '../../components/ConfirmationModel';

const AddVendor = () => {
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState('');
  const [customerNameError, setCustomerNameError] = useState('');
  const [vendorCode, setVendorCode] = useState('');
  const [vendorCodeError, setVendorCodeError] = useState('');
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState(null);

  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [createVendor] = useCreateVendorMutation();

  const {
    VENDOR: { VENDORS, ADD_VENDOR },
  } = BREADCRUMB_NAMES;

  const breadcrumbData = useMemo(
    () => [{ text: VENDORS, redirection: routeConstants.VENDORS_ROUTE }, { text: ADD_VENDOR }],
    [],
  );

  const handleAddEmail = () => {
    if (email.trim() === '') return;

    if (emails.includes(email.trim())) {
      setEmailErrorMessage('This email is already added.');
      return;
    }

    setEmails((prev) => [...prev, email.trim()]);
    setEmail('');
    setEmailErrorMessage('');
  };

  const handleDeleteEmail = (email) => {
    setEmailToDelete(email);
    setShowDeleteModal(true);
  };

  const onDeleteModalClickHandler = (value) => {
    if (value === MESSAGE.DELETE) {
      setEmails((prev) => prev.filter((e) => e !== emailToDelete));
    }
    setShowDeleteModal(false);
    setEmailToDelete(null);
  };

  const handleSubmit = async () => {
    if (email.trim() !== '') {
      setEmailErrorMessage('Please add the email before submitting.');
      return;
    }

    const createdVendor = {
      customerName: customerName.trim(),
      vendorCode: vendorCode.trim(),
      emails: emails.map((email) => ({ email })),
    };

    try {
      const response = await createVendor(createdVendor);

      if (response.error && response.error.status === 409) {
        if (response.error.data?.message.includes('VendorName')) {
          setCustomerNameError(response.error.data?.message || 'Vendor with this customer name already exists.');
        } else {
          setVendorCodeError(response.error.data?.message || 'Vendor with this vendor code already exists.');
        }
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.error('Error creating vendor:', error);
    }
  };

  const disableSubmit = useMemo(
    () => customerName.trim() === '' || vendorCode.trim() === '',
    [customerName, vendorCode],
  );

  const disableAddEmail = useMemo(() => {
    const trimmedEmail = email.trim();
    if (trimmedEmail === '') return true;
    return !emailValidationSchema.isValidSync(trimmedEmail);
  }, [email]);

  return (
    <div>
      <Breadcrumbs
        icon={<NavigateNextIcon fontSize="medium" />}
        variant="body2"
        underline="hover"
        data={breadcrumbData}
      />
      <Box component={Paper}>
        <Grid>
          <GridContainer spacing={{ xs: 1, sm: 2, md: 3 }}>
            <GridItem item xs={12} md={6}>
              <TextField
                label="Vendor Name"
                variant="outlined"
                InputProps={{ 'data-testid': 'form-elements' }}
                value={customerName}
                onChange={(e) => {
                  setCustomerName(e.target.value);
                  setCustomerNameError('');
                }}
                error={Boolean(customerNameError)}
                helperText={customerNameError}
              />
            </GridItem>
            <GridItem item xs={12} md={6}>
              <TextField
                label="Vendor Code"
                variant="outlined"
                InputProps={{ 'data-testid': 'form-elements' }}
                value={vendorCode}
                onChange={(e) => {
                  setVendorCode(e.target.value);
                  setVendorCodeError('');
                }}
                error={Boolean(vendorCodeError)}
                helperText={vendorCodeError}
              />
            </GridItem>
            <GridItem item xs={12} md={6}>
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailErrorMessage('');
                }}
                error={Boolean(emailErrorMessage)}
                helperText={emailErrorMessage}
              />
            </GridItem>
            <GridItem item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={handleAddEmail}
                style={{ marginLeft: '10px' }}
                disabled={disableAddEmail}
              >
                Add Email
              </Button>
            </GridItem>
            <GridItem xs={12}>
              {emails.map((email, index) => (
                <Chip key={index} label={email} onDelete={() => handleDeleteEmail(email)} sx={{ margin: '2px' }} />
              ))}
            </GridItem>
            <Box pl={3} pb={3}>
              <Button variant="contained" size="large" onClick={handleSubmit} disabled={disableSubmit}>
                {MESSAGE.SUBMIT}
              </Button>
            </Box>
          </GridContainer>
        </Grid>
      </Box>
      <ConfirmationModal
        isOpen={showDeleteModal}
        title={MESSAGE.CONFIRMATION}
        msg={`Are you sure you want to delete email ${emailToDelete}?`}
        buttons={[
          { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
          { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
        ]}
        onClick={onDeleteModalClickHandler}
      />
    </div>
  );
};

export default AddVendor;
