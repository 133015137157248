import { createSlice } from '@reduxjs/toolkit';
import { FILTER_VALUES } from '../../constants/FilterConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { LATEST_UPDATED } from '../../constants/SortConstants';

const initialState = {
  activeUsers: {
    [FILTER_VALUES.ROLE]: [],
    [FILTER_VALUES.LOCATION]: [],
    [FILTER_VALUES.COUNTRY]: null,
    [FILTER_VALUES.STATE]: null,
    [FILTER_VALUES.CITY]: [],
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
    sort: LATEST_UPDATED,
  },
  archiveUsers: {
    [FILTER_VALUES.ROLE]: [],
    [FILTER_VALUES.LOCATION]: [],
    [FILTER_VALUES.COUNTRY]: null,
    [FILTER_VALUES.STATE]: null,
    [FILTER_VALUES.CITY]: [],
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
    sort: LATEST_UPDATED,
  },
};

export const usersFilterSlice = createSlice({
  name: 'usersFilter',
  initialState,
  reducers: {
    setActiveUsersFilters(state, action) {
      state.activeUsers[FILTER_VALUES.ROLE] = action.payload?.role || [];
      state.activeUsers[FILTER_VALUES.LOCATION] = action.payload?.locationId || [];
      state.activeUsers[FILTER_VALUES.COUNTRY] = action.payload?.countryId || null;
      state.activeUsers[FILTER_VALUES.STATE] = action.payload?.stateId || null;
      state.activeUsers[FILTER_VALUES.CITY] = action.payload?.cityId || [];
      state.activeUsers.searchText = action.payload?.searchText || '';
      state.activeUsers.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.activeUsers.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
      state.activeUsers.sort = action.payload?.sort || LATEST_UPDATED;
    },
    setArchiveUsersFilters(state, action) {
      state.archiveUsers[FILTER_VALUES.ROLE] = action.payload?.role || [];
      state.archiveUsers[FILTER_VALUES.LOCATION] = action.payload?.locationId || [];
      state.archiveUsers[FILTER_VALUES.COUNTRY] = action.payload?.countryId || null;
      state.archiveUsers[FILTER_VALUES.STATE] = action.payload?.stateId || null;
      state.archiveUsers[FILTER_VALUES.CITY] = action.payload?.cityId || [];
      state.archiveUsers.searchText = action.payload?.searchText || '';
      state.archiveUsers.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.archiveUsers.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
      state.archiveUsers.sort = action.payload?.sort || LATEST_UPDATED;
    },
    resetActiveUsersFiltersState(state) {
      state.activeUsers = {
        ...initialState.activeUsers,
        searchText: state.activeUsers.searchText,
        sort: state.activeUsers.sort,
      };
    },
    resetArchiveUsersFiltersState(state) {
      state.archiveUsers = {
        ...initialState.archiveUsers,
        searchText: state.archiveUsers.searchText,
        sort: state.archiveUsers.sort,
      };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default usersFilterSlice;
