import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REHYDRATE } from 'redux-persist';

export const apiBasePath = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState, endpoint }) => {
      if (endpoint !== 'createDeposit') {
        headers.set('Content-Type', 'application/json; charset=utf-8');
        headers.set('Accept', 'application/json');
      }
      const { user } = getState();
      if (user && user.accessToken) {
        headers.set('authorization', `Bearer ${user.accessToken}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  tagTypes: [
    'Profile',
    'Users',
    'User',
    'Sublocation',
    'Locations',
    'Location',
    'SubLocations',
    'Modem',
    'Modems',
    'Machine',
    'Machines',
    'Models',
    'Model',
    'LocationOwner',
    'Collections',
    'Collection',
    'Drafts',
    'Draft',
    'Deposits',
    'Deposit',
    'ModemMachine',
    'Leases',
    'exchangeRate',
    'Intercards',
  ],
  endpoints: () => ({}),
});
