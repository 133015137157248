import React, { useEffect, useState } from 'react';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { useGetIntercardProcessesQuery } from '../../services/Intercard/IntercardSerice';
import { INTERCARD_TRACKINGS_TABLE, COLUMNS } from '../../constants/tableColumns';
import { getDateFormat, getFullName, snakeToTitleCase } from '../../utils/common-methods';
import LoadingSpinner from '../../components/LoadingSpinner';
import { NoContent, ReactTable } from '../../components/shared';
import { Box } from '@mui/material';
import useStyles from './style';
import IntercardAccordion from './IntercardAccordion';
import FeedIcon from '@mui/icons-material/Feed';
import dayjs from 'dayjs';
import Intercards3Table from './Intercards3Table';

const IntercardTrackingsTable = () => {
  const styles = useStyles();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [perPageNumber, setPerPageNumber] = useState(DEFAULT_PER_PAGE);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [intercards3TableData, setIntercards3TableData] = useState(null);
  const {
    data: latestData,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    error,
  } = useGetIntercardProcessesQuery({
    searchParams: '',
    page: currentPage,
    perPage: perPageNumber,
  });

  const onViewIntercardTableClick = (value) => {
    const data = value.intercardS3LocationData.map((s3Data) => ({
      ...s3Data,
      revenueDate: dayjs(s3Data.revenueDate).format('MM/DD/YYYY'),
      uploadedAt: dayjs(s3Data.uploadedAt?.split('T')[0]).format('MM/DD/YYYY'),
      revenueType: snakeToTitleCase(s3Data.revenueType),
    }));
    setIntercards3TableData(data);
  };

  const intercardColumns = INTERCARD_TRACKINGS_TABLE();
  const columns = [
    COLUMNS.EXPAND_ICON_COLUMN,
    ...intercardColumns,
    {
      Header: () => 'Action',
      id: 'view',
      width: 64,
      isPadding: true,
      padding: '20px 32px 20px 0px',
      Cell: (props) => {
        const { row } = props;
        return (
          <span
            data-testid="historyIcon"
            style={{
              width: '32px',
              height: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FeedIcon onClick={() => onViewIntercardTableClick(row?.original)} />
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (isSuccess && latestData) {
      const data = latestData.data.intercardTrackings.map((intercard) => {
        const { firstName, lastName } = intercard?.createdByUser || {};
        const createdByName = getFullName(firstName, lastName);
        const intercardS3LocationData = intercard?.s3FileInfo || [];
        const revenueDate = intercard?.data ? dayjs(intercard?.data.date[1]).format('MM/DD/YYYY') : 'N/A';
        const createdAt = intercard?.createdAt ? getDateFormat(intercard?.createdAt) : 'N/A';
        const transformProcessData = {
          ...intercard,
          revenueDate,
          createdAt,
          intercardS3LocationData,
          createdBy: createdByName,
        };
        return transformProcessData;
      });
      setTableData(data);
      let total = latestData?.pagination?.total || 0;
      setTotalCount(total);
    } else if (isError) {
      setTotalCount(0);
    }
  }, [isError, isSuccess, latestData]);
  const pageChangeHandler = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    setPerPageNumber(newPerPage);
  };

  const detailLocationComponent = ({ row }) => {
    const original = row?.original;
    return original && <IntercardAccordion intercardInfo={original} />;
  };

  return (
    <Box className={styles.intercardTable}>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Intercard Process Found"
          desc={(isError && error?.data?.message) || <>There are no intercard processes present with this filter.</>}
        />
      )}
      {!isLoading && !isError && !isFetching && tableData && (
        <ReactTable
          data={tableData}
          columns={columns}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
          expandable
          renderRows
          renderRowSubComponent={detailLocationComponent}
        />
      )}
      {intercards3TableData && (
        <Intercards3Table
          handleClose={() => setIntercards3TableData(null)}
          intercards3TableData={intercards3TableData}
        />
      )}
    </Box>
  );
};
export default IntercardTrackingsTable;
