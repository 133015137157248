import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../constants/FilterConstants';

const initialState = {
  states: {
    ...FILTER_CONSTANTS.FILTER_DATA.STATE.INITIAL_PARAMS,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
};
export const statesFilterSlice = createSlice({
  name: 'statesFilter',
  initialState,
  reducers: {
    setStateFilters(state, action) {
      state.states[FILTER_VALUES.COUNTRY] = action.payload?.countryId || {};
      state.states.searchText = action.payload?.searchText || '';
      state.states.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.states.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    resetStateFiltersState(state) {
      state.states = { ...initialState.states, searchText: state.searchText };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default statesFilterSlice;
