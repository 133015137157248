import React, { useMemo, useState } from 'react';
import ReactTable from '../../../components/shared/ReactTable/ReactTable';
import LoadingSpinner from '../../../components/LoadingSpinner';
import EditVendor from './EditVendor';
import AssignUnassignSublocation from './AssignUnassignSublocation';
import { useMediaQuery, useTheme } from '@mui/material';
import { VENDOR_TABLE_DYNAMIC } from '../../../constants/tableColumns';
import { PositionedMenu, NoContent } from '../../../components/shared';
import { useUpdateStatusVendorByIdMutation } from '../../../services/Vendors/VendorService';
import ConfirmationModal from '../../../components/ConfirmationModel';
import { MESSAGE } from '../../../constants/message';
import { VENDOR_STATUS } from '../../../constants/CommonConstants';

const MODALS = {
  EDIT_VENDOR: 'EDIT_VENDOR',
  ASSIGN_UNASSIGN_SUBLOCATION: 'ASSIGN_UNASSIGN_SUBLOCATION',
  CONFIRM_VENDOR_STATUS: 'CONFIRM_VENDOR_STATUS',
};

const VendorsTable = (props) => {
  const {
    vendorListResponse,
    tableData = [],
    totalCount,
    currentPage,
    pageChangeHandler,
    perPageChangeHandler,
    perPageNumber,
  } = props;
  const { isLoading, isFetching, isError, error } = vendorListResponse;
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [showModalFor, setShowModalFor] = useState('');
  const [updateStatusVendorById] = useUpdateStatusVendorByIdMutation();
  const [vendorToUpdate, setVendorToUpdate] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const toggleExpandRow = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };
  const handleOpenConfirmModal = (vendor) => {
    setVendorToUpdate(vendor);
    setShowModalFor(MODALS.CONFIRM_VENDOR_STATUS);
  };

  const handleCloseConfirmModal = () => {
    setShowModalFor('');
    setVendorToUpdate(null);
  };

  const onConfirmVendorStatusChange = async (value) => {
    if (value === MESSAGE.CONFIRM) {
      try {
        await updateStatusVendorById(vendorToUpdate.id);
      } catch (error) {
        console.error('Error updating vendor status:', error);
      }
    }
    handleCloseConfirmModal();
  };

  const columns = useMemo(() => {
    const dynamicColumns = VENDOR_TABLE_DYNAMIC(isBelowMD, expandedRows, toggleExpandRow);

    const updatedDynamicColumns = dynamicColumns.map((col) => {
      if (col.accessor === 'sublocation' || col.accessor === 'emails') {
        const originalCell = col.Cell;
        col.Cell = ({ value, row }) => {
          if (value && (Array.isArray(value) ? value.length > 0 : value)) {
            return originalCell ? originalCell({ value, row }) : value;
          }
          return 'N/A';
        };
      }
      return col;
    });

    return [
      ...updatedDynamicColumns,
      {
        Header: () => null,
        id: 'more',
        width: 1,
        Cell: ({ row }) => (
          <PositionedMenu
            menuItemData={[
              row.original?.status === 'ACTIVE' && {
                btnName: 'Edit Vendor',
                btnClickFunc: () => {
                  setSelectedVendor(row.original);
                  setShowModalFor(MODALS.EDIT_VENDOR);
                },
              },
              row.original?.status === 'ACTIVE' && {
                btnName: 'Assign/Unassign Sub Locations',
                btnClickFunc: () => {
                  setSelectedVendor(row.original);
                  setShowModalFor(MODALS.ASSIGN_UNASSIGN_SUBLOCATION);
                },
              },
              row.original?.status !== VENDOR_STATUS.ACTIVE || row.original?.status !== VENDOR_STATUS.ARCHIVE
                ? {
                    btnName: row.original?.status === VENDOR_STATUS.ACTIVE ? 'Archive Vendor' : 'Activate Vendor',
                    btnClickFunc: () => handleOpenConfirmModal(row.original),
                  }
                : null,
            ].filter(Boolean)}
          />
        ),
      },
    ];
  }, [isBelowMD, expandedRows]);

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Vendors Found"
          desc={(isError && error?.data?.message) || <>There are no user present with this filter.</>}
        />
      )}
      {!isLoading && !isError && !isFetching && tableData && (
        <ReactTable
          data={tableData}
          columns={columns}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
          maxWidth="xl"
          expandedRows={expandedRows}
          toggleExpandRow={toggleExpandRow}
        />
      )}

      {showModalFor === MODALS.EDIT_VENDOR && (
        <EditVendor
          selectedVendor={selectedVendor}
          handleClose={() => {
            setSelectedVendor(null);
            setShowModalFor('');
          }}
          handleConfirm={() => {
            setSelectedVendor(null);
            setShowModalFor('');
          }}
        />
      )}

      {showModalFor === MODALS.ASSIGN_UNASSIGN_SUBLOCATION && (
        <AssignUnassignSublocation
          selectedVendor={selectedVendor}
          locationIds={selectedVendor?.sublocationIds || []}
          handleClose={() => {
            setSelectedVendor(null);
            setShowModalFor('');
          }}
          handleConfirm={() => {
            setSelectedVendor(null);
            setShowModalFor('');
          }}
        />
      )}

      {showModalFor === MODALS.CONFIRM_VENDOR_STATUS && vendorToUpdate && (
        <ConfirmationModal
          isOpen={true}
          title={MESSAGE.CONFIRMATION}
          msg={`Are you sure you want to ${vendorToUpdate?.status === 'ACTIVE' ? 'archive' : 'activate'} ${
            vendorToUpdate?.customerName
          }?`}
          buttons={[
            { text: MESSAGE.CONFIRM, value: MESSAGE.CONFIRM },
            { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
          ]}
          onClick={onConfirmVendorStatusChange}
        />
      )}
    </>
  );
};

export default VendorsTable;
