import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import SearchBar from '../../../components/SearchBar';
import useStyles from '../style';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import TimerModal from '../../../components/TimerModal/TimerModal';
import ReconciliationCollectionForm from './ReconciliationCollectionForm';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Breadcrumbs, GridContainer, GridItem, InformationField, Button, Modal } from '../../../components/shared';
import {
  Container,
  Box,
  Chip,
  Typography,
  Stack,
  Paper,
  List,
  ListItem,
  ListItemText,
  Link,
  DialogContentText,
} from '@mui/material';
import { NavigateNext, CheckCircle, HighlightOff, Warning } from '@mui/icons-material';
import { sortIcon } from '../../../assets/images/icons';
import CircleIcon from '@mui/icons-material/Circle';

import FILTER_CONSTANTS from '../../../constants/FilterConstants';
import SORT_CONSTANT, { ASSET_ID_ASC, COLLECTION_SORT_PROPERTY } from '../../../constants/SortConstants';
import { MESSAGE } from '../../../constants/message';
import { COLLECTION_TYPES } from '../../../constants/CommonConstants';
const { RECONCILIATION: RECONCILIATION_COLLECTION_TYPE } = COLLECTION_TYPES;

import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';

import {
  getInitialFieldValueFromAsset,
  getApprovedSubmissionData,
  getValidationScheme,
  getCommentDataToDisplayOnReconciliation,
  getInitialIntercardState,
  allowPlaycardSubmission,
  getRunningTotalValue,
  getIntercardData,
  getPlayCardSalesRevenueInUsd,
  updateFormikWithStoredData,
  getDraftData,
  getRevenueFieldNameAndRevenueInUSD,
  getInitialPlaycardState,
  getInitialIndependentRepFieldValueFromSublocations,
} from '../../../utils/collection-methods';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  resetState,
  setAccountSummaryFormDirty,
  setIntercardDataForReconciliation,
  setSublocationDatesForReconciliation,
  setTaxExempt,
  setLocationPaysTax,
  setTransformedSublocations,
  setAccountSummaryData,
  setReadFromDraftAccountSummary,
  setStoredAccountSummaryData,
  setLocationCurrency,
  setRerender,
} from '../../../redux-slice/reconciliationCollection';
import {
  resetState as resetPlaycardState,
  setPlayCardSalesSummary,
  setPlayCards,
  setPlaycardFormDirty,
} from '../../../redux-slice/playcardsSummary';
import {
  checkObjectNotEmpty,
  dateFormatForApi,
  formatNumberOrReturnZero,
  getAddress,
  getCurrency,
  getDateFormat,
  getValidationSchemeYupObject,
  handledSpecialCharacterRegex,
  isArrayWithLength,
  roundOffValue,
  setPlayCardSummaryValToDecimal,
  sortArrayOfObjects,
} from '../../../utils/common-methods';
import {
  useGetCollectionsByTransactionIdQuery,
  useUpdateCollectionsByTransactionIdMutation,
  useDeleteCollectionsByTransactionIdMutation,
  useVerifyCollectionsByTransactionIdMutation,
  useDeleteSublocationFromCollectionMutation,
  useDeleteAssetFromCollectionMutation,
} from '../../../services/CollectionService/CollectionService';
import {
  COLLECTION_RECONCILIATION_APPROVED,
  COLLECTION_RECONCILIATION_SUBMITTED,
  COLLECTION_RECONCILIATION_VERIFIED,
} from '../../../constants/CommonConstants';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';
import { Comment } from '@material-ui/icons';
import { CURRENCY_CONSTANT, DEFAULT_EXCHANGE_RATE } from '../../../constants/CurrencyConstants';
import { usePrompt } from '../../../hooks/usePrompt';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';
import { getOnlyHasCreditAndTokenChangerEstCCRevenueWarning } from '../../../utils/reconciliation-childField-methods';
import {
  reduceDraftDatesBySublocationIdsInReconciliation,
  resetCollectionByTransactionIdInReconciliation,
} from '../../../redux-slice/storedReconciliationCollections';
import {
  useAddReconciliationDraftMutation,
  useGetReconciliationDraftDetailsByIdQuery,
  useDeleteReconciliationDraftByIdMutation,
} from '../../../services/DraftsService/DraftsService';

import { DraftSummaryBody } from '../AddCollection/DraftSummaryBody';
import { FIELD_NAME } from '../../../constants/MeterTypeFieldMap';

const displayName = 'Reconciliation Detail';
const ReconciliationDetail = () => {
  const {
    totalRevenue,
    totalRevenueInUSD,
    totalTicketDispensed,
    totalTokenCollected,
    totalTokenDispensed,
    averageTokenValueInUSD,
    totalPrizeDispensed,
    transformedSublocations,
    reconciliationWarningForModal,
    locationCurrency,
    accountSummary,
    sublocationDatesForReconciliation,
    intercardDataForReconciliation,
    accountSummaryFormDirty,
    formikData,
  } = useSelector((state) => state.reconciliationCollection);
  const { userDetails } = useSelector((state) => state.user);
  const { playCards, playcardSalesSummary, playcardFormDirty } = useSelector((state) => state.playCard);
  const { reconciliationCollections } = useSelector((state) => state.storedReconciliationCollections);
  const abbreviation = locationCurrency?.abbreviation || CURRENCY_CONSTANT.USD;
  const exchangeRate = locationCurrency?.exchangeRate || DEFAULT_EXCHANGE_RATE;
  const isAccountingUser = useHasRoles([ROLE.ACCOUNTING]);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const formRef = useRef();
  const [isVerified, setIsVerified] = useState(false);
  const isManagerUser = useHasRoles([ROLE.MANAGER, ROLE.DM, ROLE.REGIONAL_MANAGER, ROLE.EXECS, ROLE.VP]);
  const isAccountingAdminUser = useHasRoles([ROLE.ACCOUNTING, ROLE.SUPER_ADMIN_IT]);
  const isManagerUserAndSubmitted = Boolean(isManagerUser && !isVerified && !isAccountingAdminUser);

  const checkHasWarningModalError = () => {
    const onlyHasCreditEstCCRevenueWarning =
      getOnlyHasCreditAndTokenChangerEstCCRevenueWarning(reconciliationWarningForModal);
    return Object.values(reconciliationWarningForModal).includes(true) && !onlyHasCreditEstCCRevenueWarning;
  };

  const handleSubmitForm = (...args) => {
    setFormIsDirty(false);
    dispatch(setAccountSummaryFormDirty(false));
    dispatch(setPlaycardFormDirty(false));
    formRef.current?.handleSubmit(...args);
    if (Object.values(formRef.current.errors).length) {
      setWarningModalMessage(MESSAGE.INCOMPLETE_METER_DATA_ERROR_MESSAGE);
      setWarningModalVisibility(true);
    } else if (checkHasWarningModalError()) {
      setWarningModalMessage(MESSAGE.DEVIATED_METER_DATA_ERROR_MESSAGE);
      setWarningModalVisibility(true);
    } else if (accountSummary.errorState && !isManagerUserAndSubmitted) {
      const isErrorPresent = checkIsAnyAccountSummaryError();
      if (isErrorPresent) {
        setWarningModalMessage(MESSAGE.ACCOUNT_SUMMARY_ERROR_MESSAGE);
        setWarningModalVisibility(true);
      }
    }
  };
  const [sort, setSort] = useState(ASSET_ID_ASC);
  const [sortState, setSortState] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [showVerifySuccessModel, setShowVerifySuccessModel] = useState(false);
  const [showVerifyFailureModel, setShowVerifyFailureModel] = useState(false);
  const [showSuccessfulModel, setShowSuccessfulModel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAssetDeleteModal, setShowAssetDeleteModal] = useState(false);
  const [showSublocationtDeleteModal, setShowSublocationDeleteModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showFailedModel, setShowFailedModel] = useState(false);
  const [showFailedDraftModel, setShowFailedDraftModel] = useState(false);
  const [showClearDraftModal, setShowClearDraftModal] = useState(false);
  const [showDraftModel, setShowDraftModel] = useState(false);
  const [showClearDraftModel, setShowClearDraftModel] = useState(false);
  const [readFromDraft, setReadFromDraft] = useState(false);
  const [showSuccessClearDraftModel, setShowSuccessClearDraftModel] = useState(false);
  const [showSuccessfulDraftModel, setShowSuccessfulDraftModel] = useState(false);
  const [warningModalVisibility, setWarningModalVisibility] = useState(false);
  const [warningModalMessage, setWarningModalMessage] = useState('');
  const [locationsIds, setLocationIds] = useState({});
  const [data, setData] = useState({
    sublocations: [],
  });
  const [deleteAssetId, setDeleteAssetId] = useState('');
  const [deleteSublocationId, setDeletSublocationId] = useState('');
  const [subLocationData, setsubLocationData] = useState([]);
  const [searchRef, setSearchRef] = useState(data);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [draftData, setDraftData] = useState({});
  const [isLastAssetOrSublocation, setIsLastAssetOrSublocation] = useState(false);
  const [deletePopupMessage, setDeletePopupMessase] = useState('');
  const [isCurrentDraft, setIsCurrentDraft] = useState(false);
  const [addReconciliationDraftsApi] = useAddReconciliationDraftMutation();
  const [deleteReconciliationDraftApi] = useDeleteReconciliationDraftByIdMutation();
  // TODO: remove this ignore line after fixing the issue
  // eslint-disable-next-line no-unused-vars
  const [showSummary, setShowSummary] = useState(false);

  const { DRAWER_TYPES, TITLE } = FILTER_CONSTANTS;
  const {
    RECONCILIATION_COLLECTION: { RECONCILIATION_COLLECTION_LIST, RECONCILIATION_DETAIL },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = [
    {
      text: RECONCILIATION_COLLECTION_LIST,
      redirection: `${routeConstants.COLLECTIONS_ROUTE}?selectedTab=2`,
    },
    { text: RECONCILIATION_DETAIL },
  ];

  const {
    currentData: latestData = {},
    isError,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetCollectionsByTransactionIdQuery({ transactionId: params.id, skip: false });

  const subLocationIds = data?.sublocations.map((sublocation) => sublocation.id);
  const { data: draftsData } = useGetReconciliationDraftDetailsByIdQuery(
    {
      userId: userDetails.id,
      locationId: data?.location?.id,
      subLocationIds: subLocationIds,
      transactionId: params?.id,
    },
    { skip: !userDetails.id || !data?.location?.id || isCurrentDraft || !params?.id },
  );

  const [approveReconciliationApi] = useUpdateCollectionsByTransactionIdMutation();
  const [verifyReconciliationApi] = useVerifyCollectionsByTransactionIdMutation();
  const [deleteReconciliationApi] = useDeleteCollectionsByTransactionIdMutation();
  const [deleteAssetFromCollection, { isLoading: deleteAssetLoading }] = useDeleteAssetFromCollectionMutation();
  const [deleteSublocationFromCollection, { isLoading: deleteSublocationLoading }] =
    useDeleteSublocationFromCollectionMutation();

  const searchHandler = (params) => {
    if (params) {
      const patternValue = handledSpecialCharacterRegex(params);
      const searchGloballyRegex = new RegExp(patternValue, 'i');
      const data_filter = searchRef?.sublocations.filter(function (element) {
        return element.assets.some(function (subElement) {
          return (
            String(subElement.id).match(searchGloballyRegex) ||
            String(subElement?.legacyAssetId).match(searchGloballyRegex) ||
            subElement?.title?.match(searchGloballyRegex)
          );
        });
      });
      const filterAssets = data_filter.map(function (element) {
        return {
          ...element,
          assets: element.assets.filter(function (subElement) {
            return (
              String(subElement.id).match(searchGloballyRegex) ||
              String(subElement?.legacyAssetId).match(searchGloballyRegex) ||
              subElement?.title?.match(searchGloballyRegex)
            );
          }),
        };
      });

      setData((prev) => ({ ...prev, sublocations: filterAssets }));
      setsubLocationData(filterAssets);
      setSearchState(filterAssets);
    } else {
      if (sort) {
        const sublocations = searchRef?.sublocations?.map((sub) => {
          const sortedAssets = sortArrayOfObjects(sub?.assets, COLLECTION_SORT_PROPERTY[sort]);
          return { ...sub, assets: sortedAssets };
        });
        setsubLocationData(sublocations);
        setSearchState(sublocations);
        setData((prev) => ({ ...prev, sublocations: sublocations }));
      }
    }
  };

  const handleSort = (e) => {
    setSort(e.target.value);
    setSortState(true);
  };

  const checkIsAnyAccountSummaryError = () => {
    const sublocations = data?.sublocations || [];
    let isErrorPresent = false;
    for (let i = 0; i < sublocations.length; i++) {
      const subId = sublocations[i].id;
      const accountSummaryOfSubLocHasError = !!accountSummary.errorState[subId];
      let difference = 0;
      if (checkObjectNotEmpty(accountSummary?.data) && isArrayWithLength(accountSummary?.data[subId])) {
        const accountSummaryState = accountSummary?.data[subId];
        difference = getRunningTotalValue(accountSummaryState);
      }
      if (accountSummaryOfSubLocHasError || Number(roundOffValue(difference || 0)) !== 0) {
        isErrorPresent = true;
        break;
      }
    }
    return isErrorPresent;
  };

  const draftedCollections = useMemo(() => {
    let draftsArr = [];
    Object.keys(reconciliationCollections)?.map((objKey) => {
      const dataObj = reconciliationCollections[objKey];
      draftsArr.push({
        id: objKey,
        name: dataObj?.locationName,
      });
    });
    return draftsArr;
  }, [reconciliationCollections]);

  useEffect(() => {
    if (formRef.current?.values && Object.values(formRef.current?.values)?.length > 0) {
      setFormIsDirty(formRef.current?.dirty);
      setDraftData(getDraftData(formRef?.current?.values, '', '', true));
    }
  }, [formRef.current]);

  usePrompt(MESSAGE.LEAVE_PAGE_MESSAGE, formIsDirty || accountSummaryFormDirty || playcardFormDirty);

  useEffect(() => {
    if (
      draftsData &&
      reconciliationCollections &&
      !isCurrentDraft &&
      params?.id &&
      reconciliationCollections[params?.id]?.transactionId &&
      reconciliationCollections[params?.id]?.transactionId === params?.id
    ) {
      const temp = reconciliationCollections[params?.id];
      const status = isVerified ? COLLECTION_RECONCILIATION_APPROVED : COLLECTION_RECONCILIATION_VERIFIED;
      if (temp?.status === status) {
        setShowDraftModel(true);
      }
    } else {
      setShowDraftModel(false);
      if (!draftsData) {
        dispatch(resetCollectionByTransactionIdInReconciliation(params?.id));
      }
    }
    // setIsCurrentDraft(false);
  }, [draftsData, Object.keys(reconciliationCollections).length]);

  const submitHandler = (value) => {
    const isVerify = value === MESSAGE.VERIFY;
    setIsSubmittingForm(true);
    const submitData = {
      status: isVerify ? COLLECTION_RECONCILIATION_VERIFIED : COLLECTION_RECONCILIATION_APPROVED,
      exchangeRate: latestData?.currency?.exchangeRate || DEFAULT_EXCHANGE_RATE,
      sublocations: getApprovedSubmissionData(
        data?.sublocations,
        transformedSublocations,
        playCards,
        accountSummary.data,
        sublocationDatesForReconciliation,
        intercardDataForReconciliation,
      ),
    };
    if (isVerify) {
      verifyReconciliationApi({ transactionId: params.id, ...submitData }).then((response) => {
        setIsSubmittingForm(false);
        if (response.error) {
          setShowVerifyFailureModel(true);
          setTimeout(() => {
            setShowVerifyFailureModel(false);
          }, 2000);
        } else {
          setIsVerified(true);
          setShowVerifySuccessModel(true);
        }
      });
    } else {
      approveReconciliationApi({ transactionId: params.id, ...submitData }).then((response) => {
        setIsSubmittingForm(false);
        if (response.error) {
          setShowFailedModel(true);
          setTimeout(() => {
            setShowFailedModel(false);
          }, 2000);
        } else {
          dispatch(resetState());
          dispatch(resetPlaycardState());
          //reset collection by location id after approving collection
          dispatch(resetCollectionByTransactionIdInReconciliation(params?.id));
          setShowSuccessfulModel(true);
          setTimeout(() => {
            navigate(`/${routeConstants.COLLECTIONS_ROUTE}?selectedTab=3`, {
              redirect: true,
            });
          }, 2000);
        }
      });
    }
  };

  const readFromDraftHandler = () => {
    setShowDraftModel(false);
    setReadFromDraft(true);
  };

  const clearDraftHandler = () => {
    setShowClearDraftModel(false);
    setShowDraftModel(false);
    setReadFromDraft(false);
    dispatch(resetCollectionByTransactionIdInReconciliation(params?.id));
    dispatch(reduceDraftDatesBySublocationIdsInReconciliation(data?.sublocationIds));
  };

  const clearLocationsDraftHandler = () => {
    setShowClearDraftModal(false);
    Object.keys(locationsIds)?.map((key) => {
      if (locationsIds[key]) {
        dispatch(resetCollectionByTransactionIdInReconciliation(key));
      }
    });
    setLocationIds({});
    setShowSuccessClearDraftModel(true);
  };

  const saveAsDraftHandler = () => {
    setShowSummary(false);
    const apiData = {
      userId: userDetails.id,
      locationId: data?.location?.id,
      locationName: data?.location?.locationName,
      formData: draftData,
      exchangeRate: exchangeRate,
      playcards: playCards,
      intercardData: intercardDataForReconciliation,
      accountSummary: accountSummary,
      collectionType: RECONCILIATION_COLLECTION_TYPE,
      sublocationDates: sublocationDatesForReconciliation,
      transactionId: params.id,
      status: isVerified ? COLLECTION_RECONCILIATION_APPROVED : COLLECTION_RECONCILIATION_VERIFIED,
    };

    addReconciliationDraftsApi(apiData)
      .unwrap()
      .then(() => {
        setIsSubmittingForm(false);
        setFormIsDirty(false);
        dispatch(setPlaycardFormDirty(false));
        setIsCurrentDraft(true);
        setShowFailedDraftModel(false);
        setShowSuccessfulDraftModel(true);
      })
      .catch(() => {
        setIsSubmittingForm(false);
        setShowFailedDraftModel(true);
        setShowSuccessfulDraftModel(false);
      });
  };

  const deleteReconciliationDraftHandler = useCallback(
    async (id) => {
      try {
        setIsCurrentDraft(true); // Set to true before deleting to prevent API call
        await deleteReconciliationDraftApi(id).unwrap();
        clearDraftHandler();
      } catch (error) {
        error;
      }
    },
    [deleteReconciliationDraftApi],
  );

  useEffect(() => {
    dispatch(setTransformedSublocations([]));
    return () => {
      dispatch(resetState());
      dispatch(resetPlaycardState());
    };
  }, []);

  useEffect(() => {
    if (sort && data && data?.sublocations?.length > 0) {
      const sublocations = data?.sublocations?.map((sub) => {
        const sortedAssets = sortArrayOfObjects(sub?.assets, COLLECTION_SORT_PROPERTY[sort]);
        return { ...sub, assets: sortedAssets };
      });
      setsubLocationData(sublocations);
      setData((prev) => ({ ...prev, sublocations: sublocations }));
      if (readFromDraft) {
        setReadFromDraft(false);
      }
    }
  }, [JSON.stringify(sort)]);

  useEffect(() => {
    if (
      readFromDraft &&
      reconciliationCollections &&
      searchRef?.location?.id &&
      reconciliationCollections[params?.id] &&
      reconciliationCollections[params?.id].transactionId === params?.id
    ) {
      const storedCollectionType = RECONCILIATION_COLLECTION_TYPE;
      const status = isVerified ? COLLECTION_RECONCILIATION_APPROVED : COLLECTION_RECONCILIATION_VERIFIED;

      let collection = {};
      for (let i in reconciliationCollections) {
        if (i === params?.id) {
          const draft = reconciliationCollections[i];
          const checkForStatus = draft?.status === status;
          const isReconciliationDraft = draft?.collectionType === storedCollectionType;
          const isSameTransaction = String(draft?.transactionId) === String(params?.id);
          if (checkForStatus && isReconciliationDraft && isSameTransaction) {
            collection = { ...draft };
          }
        }
      }
      if (checkObjectNotEmpty(collection)) {
        const storedAccountSummary = collection['accountSummary'];
        const subLocFlags = Object?.keys(storedAccountSummary?.data)?.map((item) => {
          const obj = { [item]: true };
          return obj;
        });
        let flags = {};
        subLocFlags?.forEach((data) => {
          flags = { ...flags, ...data };
        });
        dispatch(setReadFromDraftAccountSummary({ ...flags }));
        dispatch(setStoredAccountSummaryData(storedAccountSummary?.data));
        dispatch(setAccountSummaryData(storedAccountSummary?.data));
      }
    }
  }, [readFromDraft]);

  useEffect(() => {
    if (
      readFromDraft &&
      reconciliationCollections &&
      searchRef?.location?.id &&
      reconciliationCollections[params?.id] &&
      reconciliationCollections[params?.id]?.transactionId === params?.id
    ) {
      const storedForm = reconciliationCollections[params?.id];
      if (storedForm && storedForm['playcards'] && Object.values(storedForm['playcards']).length > 0) {
        const storedPlayCardData = storedForm['playcards'];
        dispatch(setPlayCards(storedPlayCardData));
        const totalPlayCardSales = getPlayCardSalesRevenueInUsd(storedPlayCardData);
        dispatch(setPlayCardSalesSummary(totalPlayCardSales));
      }
    }
  }, [readFromDraft, isSuccess]);

  useEffect(() => {
    if (isSuccess && latestData) {
      dispatch(resetState());
      dispatch(resetPlaycardState());
      let currentData = latestData;
      const status = currentData?.status || COLLECTION_RECONCILIATION_SUBMITTED;
      setIsVerified(status === COLLECTION_RECONCILIATION_VERIFIED);
      if (sort) {
        const sublocations = latestData?.sublocations?.map((sub) => {
          const sortedAssets = sortArrayOfObjects(sub?.assets, COLLECTION_SORT_PROPERTY[sort]);
          return { ...sub, assets: sortedAssets };
        });
        currentData = { ...latestData, sublocations: sublocations };
      }
      setSearchRef(currentData);
      setsubLocationData(currentData.sublocations);
      setData(currentData);

      let storedPlayCardData = {};
      latestData?.sublocations?.map((item) => {
        const { id: sublocationId, locPaysTaxFlag } = item;
        dispatch(setTaxExempt(item?.taxExemptFlag));
        dispatch(setLocationPaysTax({ [sublocationId]: locPaysTaxFlag }));

        const { playCardSumDetails } = item;
        if (playCardSumDetails) {
          storedPlayCardData = Object.assign(storedPlayCardData, { [sublocationId]: playCardSumDetails });
        }
        dispatch(
          setSublocationDatesForReconciliation({
            [item?.id]: {
              dateOfReading: dateFormatForApi(item?.dateOfReading),
              dateEntered: dateFormatForApi(item?.dateEntered),
            },
          }),
        );
      });
      const intercardData = getIntercardData(latestData?.sublocations);
      dispatch(setIntercardDataForReconciliation(intercardData));
      dispatch(setPlayCards(setPlayCardSummaryValToDecimal(storedPlayCardData)));
      const totalPlayCardSales = getPlayCardSalesRevenueInUsd(storedPlayCardData);
      dispatch(setPlayCardSalesSummary(totalPlayCardSales));
      if (JSON.stringify(latestData?.currency)?.length > 2) {
        const locationCurrencyFromAPI = {
          id: latestData.currency?.id || null,
          abbreviation: latestData.currency?.abbreviation || null,
          exchangeRate: latestData.currency?.exchangeRate
            ? Number(latestData.currency?.exchangeRate)
            : DEFAULT_EXCHANGE_RATE,
        };
        dispatch(setLocationCurrency(locationCurrencyFromAPI));
      }
    }
  }, [isSuccess, JSON.stringify(latestData)]);

  const initialStateAndScheme = useMemo(() => {
    let validationSchemas = {};

    let dependencies = [];
    let states = {};
    const exchangeRate = latestData?.currency?.exchangeRate || DEFAULT_EXCHANGE_RATE;
    let currentAssets = [];
    if (searchRef?.sublocations?.length > 0) {
      currentAssets = searchRef?.sublocations?.flatMap((sub) => sub.assets);
    }

    if (isSuccess) {
      currentAssets = searchRef?.sublocations?.flatMap((sub) => sub.assets);
      const { states: independentRepStates, schema: independentRepSchema } =
        getInitialIndependentRepFieldValueFromSublocations(searchRef?.sublocations);
      states = { ...states, ...independentRepStates };
      validationSchemas = { ...validationSchemas, ...independentRepSchema };
      if (isArrayWithLength(currentAssets)) {
        currentAssets.map((asset) => {
          if (asset?.assetMeters) {
            states = {
              ...states,
              ...getInitialFieldValueFromAsset(asset, true, exchangeRate),
            };
            const schemas = getValidationScheme(asset);
            validationSchemas = { ...validationSchemas, ...schemas.schema };
            dependencies = [...dependencies, ...schemas.dependencies];
          }
          // Read intercard data from redux
          if (
            intercardDataForReconciliation &&
            Object.values(intercardDataForReconciliation).length > 0 &&
            intercardDataForReconciliation[asset?.id] &&
            intercardDataForReconciliation[asset?.id]?.intercardData.length > 0
          ) {
            const intercardInitialStates = getInitialIntercardState(
              intercardDataForReconciliation[asset?.id]?.intercardData,
              asset,
            );
            states = { ...states, ...intercardInitialStates };
          }
          // update using formikData to preserve
          const updateStates = updateFormikWithStoredData(formikData, states, asset, true);
          states = { ...updateStates };
        });
      }
    }
    const validationSchema = getValidationSchemeYupObject(validationSchemas, dependencies);
    if (
      readFromDraft &&
      reconciliationCollections &&
      searchRef?.location?.id &&
      reconciliationCollections[params?.id] &&
      reconciliationCollections[params?.id]?.transactionId === params?.id
    ) {
      const storedForm = reconciliationCollections[params?.id];
      const storedExchangeRate = storedForm?.exchangeRate;
      if (storedForm['playcards'] && Object.values(storedForm['playcards']).length > 0) {
        Object.keys(storedForm['playcards']).forEach((SubLocIdForDraftPlayCard) => {
          const playcardsInitialStates = getInitialPlaycardState(storedForm['playcards'][SubLocIdForDraftPlayCard]);
          states = { ...states, ...playcardsInitialStates };
        });
      }
      if (storedForm['intercardData'] && Object.values(storedForm['intercardData']).length > 0) {
        dispatch(setIntercardDataForReconciliation(storedForm['intercardData']));
        Object.keys(storedForm['intercardData']).forEach((assetIdForDraftIntercard) => {
          const sublocationIdForDraftIntercard = storedForm['intercardData'][assetIdForDraftIntercard].sublocationId;
          const intercardInitialStates = getInitialIntercardState(
            storedForm['intercardData'][assetIdForDraftIntercard].intercardData,
            { id: assetIdForDraftIntercard, subLocationId: sublocationIdForDraftIntercard },
          );
          states = { ...states, ...intercardInitialStates };
        });
      }
      Object.keys(storedForm).map((key) => {
        if (key in states && String(states[key]) !== String(storedForm[key])) {
          let value = String(storedForm[key]);
          if (storedExchangeRate !== exchangeRate && key.includes(FIELD_NAME.REVENUE_IN_USD)) {
            const { revenueInUSD } = getRevenueFieldNameAndRevenueInUSD(exchangeRate, storedForm[key], storedForm, key);
            value = revenueInUSD;
          }
          states[key] = value;
        }
        if (key.includes('comment') || key.includes('independent')) {
          states[key] = storedForm[key];
        }
      });
    }
    return { initialState: states, scheme: validationSchema };
  }, [searchRef, readFromDraft, intercardDataForReconciliation, isSuccess]);

  useEffect(() => {
    const shouldBeEnabled = allowPlaycardSubmission(playCards, playcardSalesSummary);
    const currentAssets = data?.sublocations?.flatMap((sub) => sub.assets);
    let intercards = [];
    if (isArrayWithLength(currentAssets)) {
      intercards = currentAssets?.flatMap((assets) => assets?.intercardTransactions);
    }
    if (
      (isArrayWithLength(transformedSublocations) || isArrayWithLength(intercards)) &&
      shouldBeEnabled &&
      playcardSalesSummary >= 0
    ) {
      if (isManagerUser && !isAccountingAdminUser && isVerified) {
        setDisabledSubmit(true);
      } else {
        setDisabledSubmit(false);
      }
    } else {
      setDisabledSubmit(true);
    }
  }, [transformedSublocations, playCards, playcardSalesSummary, data?.sublocations, isVerified]);

  const onApproveModalClickHandler = (value) => {
    if ([MESSAGE.APPROVE, MESSAGE.VERIFY].includes(value)) {
      submitHandler(value);
      deleteReconciliationDraftHandler(userDetails?.id + '_' + data?.location?.id + '_' + params?.id);
    }
    setShowApproveModal(false);
  };

  const onDeleteModalClickHandler = (value) => {
    if (value === MESSAGE.DELETE) {
      deleteHandler();
      deleteReconciliationDraftHandler(userDetails?.id + '_' + data?.location?.id + '_' + params?.id);
    } else {
      setShowDeleteModal(false);
    }
  };
  const deleteHandler = () => {
    deleteReconciliationApi(params.id).then((response) => {
      if (response.data) {
        setShowDeleteModal(false);
        setTimeout(() => {
          navigate(`/${routeConstants.COLLECTIONS_ROUTE}?selectedTab=2`, {
            redirect: true,
          });
        }, 1000);
      }
    });
  };
  //show sublocation delete modal box
  const showSublocationDeleteModalbox = (id) => {
    if (subLocationData.length === 1) {
      setIsLastAssetOrSublocation(true);
      setDeletePopupMessase(MESSAGE.LAST_SUBLOCATION_DELETE_MESSAGE);
    } else {
      setShowSublocationDeleteModal(true);
      setDeletSublocationId(id);
    }
  };
  //handle delete sublocation
  const handleSublocationDeleteClick = (value) => {
    if (value === MESSAGE.DELETE) {
      dispatch(setRerender(true));
      const deleteByCollectionId = subLocationData.filter((item) => item.collectionId !== deleteSublocationId);
      deleteSublocationFromCollection({ transactionId: params.id, collectionId: deleteSublocationId }).then(() => {
        setsubLocationData(deleteByCollectionId);
        let assetCount = 0;
        deleteByCollectionId.map((item) => {
          assetCount += item.assets?.length;
        });
        setData((prevData) => ({
          ...prevData, // Keep other state values unchanged
          totalAssets: assetCount,
          sublocations: deleteByCollectionId, // Set the new sublocations
        }));
        setSearchRef((prevData) => ({
          ...prevData, // Keep other state values unchanged
          totalAssets: assetCount,
          sublocations: deleteByCollectionId, // Set the new sublocations
        }));
        setShowSublocationDeleteModal(false);
      });
    } else {
      setShowSublocationDeleteModal(false);
    }
  };
  //show asset delete modal box
  const showAssetDeleteModalbox = (row) => {
    if (checkIsLastAsset(row)) {
      setIsLastAssetOrSublocation(true);
      setDeletePopupMessase(MESSAGE.LAST_ASSET_DELETE_MESSAGE);
    } else {
      setShowAssetDeleteModal(true);
      setDeleteAssetId(row);
    }
  };
  const checkIsLastAsset = (row) => {
    for (const sublocation of subLocationData) {
      const asset = sublocation.assets.find((asset) => asset.id === row);

      if (asset) {
        if (sublocation.assets.length === 1) {
          return true; // This is the last asset in the sublocation
        } else {
          return false; // It's not the last asset
        }
      }
    }
    return false;
  };
  const onDeleteAssetModalClickHandler = (value) => {
    if (value === MESSAGE.DELETE) {
      onDeleteSubLocationAssets();
      setShowAssetDeleteModal(false);
    } else {
      setShowAssetDeleteModal(false);
    }
  };
  //asset delete handler
  const onDeleteSubLocationAssets = () => {
    removeById(deleteAssetId);
  };

  // Function to remove by ID (without mutating state)
  function removeById(idToRemove) {
    const newSublocations = [...subLocationData]; // Shallow copy of sublocations

    for (let i = 0; i < newSublocations.length; i++) {
      // First, check if the id matches the main object's id
      if (newSublocations[i].id === idToRemove) {
        newSublocations.splice(i, 1);
        return newSublocations;
      }

      // Check within the subItems array (assets)
      if (newSublocations[i].assets && newSublocations[i].assets.length > 0) {
        const subItemIndex = newSublocations[i].assets.findIndex((subItem) => subItem.id === idToRemove);

        if (subItemIndex !== -1) {
          deleteAssetFromCollection({
            transactionId: params.id,
            collectionId: newSublocations[i]?.collectionId,
            assetId: idToRemove,
          })
            .then(() => {
              dispatch(setRerender(true));
              setData((prevData) => ({
                ...prevData,
                totalAssets: prevData.totalAssets - 1,
                sublocations: newSublocations, // Set the new sublocations
              }));
              setSearchRef((prevData) => ({
                ...prevData, // Keep other state values unchanged
                totalAssets: prevData.totalAssets - 1,
                sublocations: newSublocations, // Set the new sublocations
              }));
              setsubLocationData(newSublocations);
            })
            .catch((e) => {
              console.log('error', e);
            });
          const removedAsset = newSublocations[i].assets.splice(subItemIndex, 1);
          if (!newSublocations[i].missingAssets) {
            newSublocations[i].missingAssets = [];
          }
          newSublocations[i].missingAssets = [...newSublocations[i].missingAssets];
          newSublocations[i].missingAssets.push({
            id: removedAsset[0].id,
            legacyAssetId: removedAsset[0].legacyAssetId,
            title: removedAsset[0].title,
          });
        }
      }
    }
  }
  const comments = useMemo(() => {
    let allComments = [];
    searchRef?.sublocations?.forEach((subLoc) => {
      const subLocLevelComments = getCommentDataToDisplayOnReconciliation(subLoc);
      allComments.push(...subLocLevelComments);
    });
    return allComments;
  }, [isSuccess, latestData, searchRef]);

  if ((isLoading && !isError) || isFetching || !isSuccess || !latestData)
    return <LoadingSpinner containerHeight={300} />;
  return (
    <Container maxWidth="xl" disableGutters>
      {(deleteAssetLoading || deleteSublocationLoading) && (
        <div className="spinnerStyle">
          <LoadingSpinner containerHeight={90} withBackground={true} />
        </div>
      )}
      <Box mb={3}>
        <Breadcrumbs
          icon={<NavigateNext fontSize="medium" />}
          variant="body2"
          underline="hover"
          data={breadcrumbData}
          data-testid="breadcrumb"
        />
      </Box>

      <Box mb={3}>
        <Stack direction={'row'} alignItems="center" spacing={2}>
          <Typography variant="h1" data-testid="locationName" textAlign="center">
            {data?.location?.locationName}
          </Typography>
          <Chip
            label={data?.location?.status ? 'Active' : 'Inactive'}
            color={data?.location?.status ? 'success' : 'error'}
            variant="outlined"
          />
        </Stack>
      </Box>
      <Box mb={1} component={Paper}>
        <GridContainer data-testid="info-pad" spacing={2} px={3} py={1} display="flex" justifyContent="flex-start">
          <GridItem xs={12} sm={4} md={3} lg={2}>
            <InformationField value={'TYPE'} variant="subtitle2" />
            <InformationField value={data?.location?.locationType?.name} variant="subtitle1" />
          </GridItem>
          <GridItem xs={12} sm={4} md={3} lg={2}>
            <InformationField value={MESSAGE.TOTAL_ASSET} variant="subtitle2" />
            <InformationField value={data?.totalAssets} variant="subtitle1" />
          </GridItem>
          <GridItem xs={12} sm={4} md={3} lg={2}>
            <InformationField value={MESSAGE.SUBMISSION_DATE.toUpperCase()} variant="subtitle2" />
            <InformationField value={getDateFormat(data?.submissionDate)} variant="subtitle1" />
          </GridItem>
          <GridItem xs={12} sm={4} md={3} lg={2}>
            <InformationField value={MESSAGE.COLLECTION_LOCATION_CITY} variant="subtitle2" />
            <InformationField value={data?.location?.city?.name} variant="subtitle1" />
          </GridItem>
          <GridItem xs={12} sm={4} md={3} lg={2}>
            <InformationField value={MESSAGE.COLLECTION_LOCATION_ADDRESS} variant="subtitle2" />
            <InformationField value={getAddress(data?.location)} variant="subtitle1" />
          </GridItem>
          {abbreviation !== CURRENCY_CONSTANT.USD && (
            <GridItem xs={12} sm={4} md={3} lg={2}>
              <InformationField value={MESSAGE.COLLECTION_EXCHANGE_RATE} variant="subtitle2" />
              <InformationField value={exchangeRate} variant="subtitle1" />
            </GridItem>
          )}
        </GridContainer>
      </Box>

      {comments && comments?.length > 0 && (
        <Box pt={1}>
          <Box className={classes.commentListBox}>
            <Box display="flex" className={classes.displayCommentContainer}>
              <Comment fontSize="medium" className={classes.commentIcon} />
              <Typography variant="subtitle4" color="#2ca2df" pl={1}>
                Comments Added
              </Typography>
            </Box>
            <List>
              {comments?.map((asset) => (
                <ListItem key={asset?.assetId}>
                  <CircleIcon className={classes.listIcon} />
                  <ListItemText>
                    <Typography variant="subtitle3" pl={1}>
                      {`${asset?.users}`}
                      <Link
                        href={`#${asset?.assetId}`}
                        fontWeight="fontWeightMedium"
                      >{`Machine ${asset?.assetId}`}</Link>
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      )}
      {isSuccess && latestData?.descrepancyWarnings?.length > 0 && (
        <Box pt={1}>
          <Box className={classes.warningListBox}>
            <Box display="flex" className={classes.displayWarningContainer}>
              <Warning fontSize="medium" className={classes.warningIcon} />
              <Typography variant="subtitle4" color="#ffba00" pl={1}>
                Warning!
              </Typography>
            </Box>
            <List>
              {latestData?.descrepancyWarnings?.map((warning, index) => (
                <ListItem key={index}>
                  <CircleIcon className={classes.listLable} />
                  <ListItemText>
                    <Typography color="#ffbf00" variant="subtitle3" pl={1}>
                      {`${warning}`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      )}
      {isSuccess && !isLoading && !isError && !isFetching && (
        <Box component={Paper} className={classes.accordion}>
          <Box className={classes.searchFilterWrap}>
            <Box className={classes.searchWrap}>
              <SearchBar
                onSearch={searchHandler}
                onReset={() => {
                  setData(searchRef);
                }}
                isEnterDisabled
              />
            </Box>
            <Box pl={3}>
              <NewFilterDrawer
                name={TITLE.SORT_BY.toUpperCase()}
                title={TITLE.SORT_BY}
                drawerType={DRAWER_TYPES.SORT_DRAWER}
                startIcon={<>{sortIcon}</>}
                onSortSelect={handleSort}
                selectedSort={sort}
                sortData={SORT_CONSTANT.ADD_COLLECTION_SORT}
              />
            </Box>
          </Box>
          {JSON.stringify(initialStateAndScheme?.initialState)?.length > 2 && isSuccess && latestData && (
            <ReconciliationCollectionForm
              ref={formRef}
              sublocations={subLocationData}
              allSublocations={searchRef?.sublocations}
              initialStateAndScheme={initialStateAndScheme}
              isReconciliation
              searchState={searchState}
              onConfirm={() => {
                let allowToShowApproveModal = !isManagerUserAndSubmitted
                  ? !(checkHasWarningModalError() || checkIsAnyAccountSummaryError())
                  : !checkHasWarningModalError();
                if (allowToShowApproveModal) {
                  setShowApproveModal(true);
                }
              }}
              readFromDraft={readFromDraft}
              transactionId={params?.id}
              turnOffDraft={() => setReadFromDraft(false)}
              isVerified={isVerified}
              sortState={sortState}
              onDeleteSubLocationAssets={(id) => showAssetDeleteModalbox(id)}
              handleSublocationDeleteClick={(id) => showSublocationDeleteModalbox(id)}
            />
          )}
          {data?.sublocations?.length > 0 && (
            <Box mt={3} component={Paper}>
              <GridContainer data-testid="info-pad" spacing={2} display={'flex'} justifyContent="center" gap={2}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={7}
                  sx={{
                    minWidth: {
                      sm: '400px',
                      md: '560px',
                    },
                  }}
                >
                  <GridContainer
                    data-testid="info-pad"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="baseline"
                    columnSpacing={1.5}
                    rowSpacing={1}
                  >
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField
                          value={`${MESSAGE.COLLECTION_TOTAL_REVENUE} (${abbreviation})`}
                          variant="subtitle7"
                        />
                      </Box>
                      <InformationField
                        value={`${getCurrency(abbreviation || CURRENCY_CONSTANT.USD)} ${formatNumberOrReturnZero(
                          totalRevenue,
                        )}`}
                        variant="subtitle4"
                      />
                    </GridItem>
                    {abbreviation !== CURRENCY_CONSTANT.USD && (
                      <GridItem xs={6} sm={2}>
                        <Box>
                          <InformationField
                            value={`${MESSAGE.COLLECTION_TOTAL_REVENUE} (${CURRENCY_CONSTANT.USD})`}
                            variant="subtitle7"
                            data-testid="totalRevenueTitle"
                          />
                        </Box>
                        <InformationField
                          value={`$ ${roundOffValue(totalRevenueInUSD)}`}
                          variant="subtitle4"
                          data-testid="totalRevenue"
                        />
                      </GridItem>
                    )}
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField value={MESSAGE.TOTAL_ASSET} variant="subtitle7" />
                      </Box>
                      <InformationField value={data?.totalAssets} variant="subtitle4" />
                    </GridItem>
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField value={MESSAGE.COLLECTION_TICKET_DISPENSED} variant="subtitle7" />
                      </Box>
                      <InformationField value={totalTicketDispensed} variant="subtitle4" />
                    </GridItem>
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField value={MESSAGE.COLLECTION_TOTAL_TOKEN_COLLECTED} variant="subtitle7" />
                      </Box>
                      <InformationField value={totalTokenCollected} variant="subtitle4" />
                    </GridItem>
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField value={MESSAGE.COLLECTION_TOTAL_TOKEN_DISPENSED} variant="subtitle7" />
                      </Box>
                      <InformationField value={totalTokenDispensed} variant="subtitle4" />
                    </GridItem>
                    {averageTokenValueInUSD > 0 && (
                      <GridItem xs={6} sm={2}>
                        <Box>
                          <InformationField
                            value={`${MESSAGE.AVERAGE_TOKEN_VALUE} (${CURRENCY_CONSTANT.USD})`}
                            variant="subtitle7"
                          />
                        </Box>
                        <InformationField value={`$ ${averageTokenValueInUSD}`} variant="subtitle4" />
                      </GridItem>
                    )}
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField value={MESSAGE.COLLECTION_TOTAL_PRIZE_DISPENSED} variant="subtitle7" />
                      </Box>
                      <InformationField value={totalPrizeDispensed} variant="subtitle4" />
                    </GridItem>
                    {JSON.stringify(playCards).length > 2 && (
                      <GridItem xs={6} sm={2}>
                        <Box>
                          <InformationField
                            value={`${MESSAGE.PLAYCARD_SALES_SUMMARY} (${CURRENCY_CONSTANT.USD})`}
                            variant="subtitle7"
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <InformationField
                            value={formatNumberOrReturnZero(playcardSalesSummary)}
                            variant="subtitle4"
                          />
                        </Box>
                      </GridItem>
                    )}
                  </GridContainer>
                </GridItem>
                <GridItem
                  xs={5}
                  lg={2}
                  display="flex"
                  sx={{
                    justifyContent: {
                      xs: 'flex-start',
                      md: 'center',
                    },
                    height: 65,
                  }}
                >
                  <Button
                    disabled={disabledSubmit}
                    variant={'contained'}
                    onClick={() => handleSubmitForm()}
                    data-testid="submit-collection"
                    sx={{ padding: '10px 51px' }}
                  >
                    {isAccountingUser ? MESSAGE.APPROVE : isVerified ? MESSAGE.APPROVE : MESSAGE.VERIFY}
                  </Button>
                </GridItem>
                <GridItem
                  xs={5}
                  lg={2}
                  display="flex"
                  sx={{
                    justifyContent: {
                      xs: 'flex-start',
                      sm: 'flex-end',
                    },
                    height: 65,
                  }}
                >
                  <Button
                    disabled={disabledSubmit}
                    color={'error'}
                    variant="text"
                    items={[
                      {
                        label: MESSAGE.SAVE_AS_DRAFT,
                        onClick: () => {
                          saveAsDraftHandler();
                        },
                      },
                    ]}
                    onClick={() => {
                      setFormIsDirty(false);
                      dispatch(setAccountSummaryFormDirty(false));
                      dispatch(setPlaycardFormDirty(false));
                      setShowDeleteModal(true);
                    }}
                    data-testid="submit-collection"
                  >
                    {MESSAGE.DELETE_COLLECTION}
                  </Button>
                </GridItem>
              </GridContainer>

              <Modal open={isSubmittingForm} customWidth="395px">
                <LoadingSpinner containerHeight={300} />
              </Modal>
              <TimerModal
                isOpen={showSuccessfulModel}
                timer={2}
                activate={showSuccessfulModel}
                onTimeComplete={() => setShowSuccessfulModel(false)}
                icon={<CheckCircle color="success" sx={{ fontSize: '4rem' }} />}
                title={MESSAGE.SUCCESSFUL}
                msg={`Your submission for ${data?.location?.locationName} collection has submitted successfully. You will be redirected in`}
              />
              <TimerModal
                isOpen={showFailedModel}
                timer={2}
                activate={showFailedModel}
                onTimeComplete={() => setShowFailedModel(false)}
                icon={<HighlightOff color="error" sx={{ fontSize: '4rem' }} />}
                title={MESSAGE.FAILED}
                msg={`Your submission for ${data?.location?.locationName} collection is failed. Please try again. This message will be closed in`}
              />
              <TimerModal
                isOpen={showVerifySuccessModel}
                timer={2}
                activate={showVerifySuccessModel}
                onTimeComplete={() => setShowVerifySuccessModel(false)}
                icon={<CheckCircle color="success" sx={{ fontSize: '4rem' }} />}
                title={MESSAGE.SUCCESSFUL}
                msg={`Your verification for ${data?.location?.locationName} collection has submitted successfully. This message will be closed in`}
              />
              <TimerModal
                isOpen={showVerifyFailureModel}
                timer={2}
                activate={showVerifyFailureModel}
                onTimeComplete={() => setShowVerifyFailureModel(false)}
                icon={<HighlightOff color="error" sx={{ fontSize: '4rem' }} />}
                title={MESSAGE.FAILED}
                msg={`Your verification for ${data?.location?.locationName} collection is failed. Please try again. This message will be closed in`}
              />
              <TimerModal
                isOpen={showSuccessfulDraftModel}
                timer={2}
                activate={showSuccessfulDraftModel}
                onTimeComplete={() => setShowSuccessfulDraftModel(false)}
                icon={<CheckCircle color="success" sx={{ fontSize: '4rem' }} />}
                title={MESSAGE.SUCCESSFUL}
                msg={`Your input for ${data?.location?.locationName} collection has stored as a reconciliation draft`}
              />
              <TimerModal
                isOpen={showFailedDraftModel}
                timer={3}
                activate={showFailedDraftModel}
                onTimeComplete={() => setShowFailedDraftModel(false)}
                icon={<HighlightOff color="error" sx={{ fontSize: '4rem' }} />}
                title={MESSAGE.FAILED}
                msg={`${MESSAGE.DRAFT_NOT_SUBMITTED_WARNING} This message will be closed in`}
              />
              <TimerModal
                isOpen={showSuccessClearDraftModel}
                timer={3}
                activate={showSuccessClearDraftModel}
                onTimeComplete={() => setShowSuccessClearDraftModel(false)}
                icon={<CheckCircle color="success" sx={{ fontSize: '4rem' }} />}
                title={MESSAGE.SUCCESSFUL}
                msg={`${MESSAGE.DRAFT_DATA_CLEAR_SUCCESSFUL} This message will be closed in`}
              />
              <ConfirmationModal
                key={'delete-modal'}
                isOpen={showDeleteModal}
                title={MESSAGE.CONFIRMATION}
                msg={MESSAGE.RECONCILIATION_DELETE_DESCRIPTION}
                buttons={[
                  { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
                  { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
                ]}
                onClick={onDeleteModalClickHandler}
              />
              <ConfirmationModal
                key={'delete-asset-modal'}
                isOpen={showAssetDeleteModal}
                title={MESSAGE.CONFIRMATION}
                msg={MESSAGE.RECONCILIATION_DELETE_DESCRIPTION}
                buttons={[
                  { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
                  { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
                ]}
                onClick={onDeleteAssetModalClickHandler}
              />
              <ConfirmationModal
                key={'delete-sublocation-modal'}
                isOpen={showSublocationtDeleteModal}
                title={MESSAGE.CONFIRMATION}
                msg={MESSAGE.RECONCILIATION_DELETE_DESCRIPTION}
                buttons={[
                  { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
                  { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
                ]}
                onClick={handleSublocationDeleteClick}
              />
              <ConfirmationModal
                key={'approve-modal'}
                isOpen={showApproveModal}
                title={MESSAGE.CONFIRMATION}
                msg={
                  isAccountingUser
                    ? MESSAGE.RECONCILIATION_APPROVE_DESCRIPTION
                    : isVerified
                    ? MESSAGE.RECONCILIATION_APPROVE_DESCRIPTION
                    : MESSAGE.RECONCILIATION_VERIFY_DESCRIPTION
                }
                buttons={[
                  {
                    text: isAccountingUser ? MESSAGE.APPROVE : isVerified ? MESSAGE.APPROVE : MESSAGE.VERIFY,
                    value: isAccountingUser ? MESSAGE.APPROVE : isVerified ? MESSAGE.APPROVE : MESSAGE.VERIFY,
                  },
                  { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
                ]}
                onClick={onApproveModalClickHandler}
              />
              <Modal
                open={warningModalVisibility}
                actionButtons={
                  <Box my={3}>
                    <Button variant="contained" size="large" onClick={() => setWarningModalVisibility(false)}>
                      {MESSAGE.OK}
                    </Button>
                  </Box>
                }
              >
                {warningModalMessage}
              </Modal>
              <Modal
                title={MESSAGE.DRAFT_SUMMARY}
                titleVariant="subtitle10"
                open={showClearDraftModal}
                onClose={() => setShowClearDraftModal(false)}
                actionButtons={
                  <Box my={3} display={'flex'} justifyContent="center" gap={2}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={clearLocationsDraftHandler}
                      disabled={Object.values(locationsIds)?.every((item) => item === false)}
                    >
                      {MESSAGE.CLEAR}
                    </Button>
                    <Button
                      size="large"
                      onClick={() => {
                        setShowClearDraftModal(false);
                      }}
                      variant={warningModalVisibility ? 'contained' : 'text'}
                    >
                      {MESSAGE.CANCEL}
                    </Button>
                  </Box>
                }
              >
                <DraftSummaryBody
                  draftsData={draftedCollections}
                  locationsIds={locationsIds}
                  setLocationIds={setLocationIds}
                />
              </Modal>
              <Modal
                children={
                  <DialogContentText variant="h2">
                    {MESSAGE.DRAFT_FORM_EXIST_MESSAGE.replace(/draft collection/gi, function (matched) {
                      const replacement = 'reconciliation draft collection';
                      if (matched === matched.toUpperCase()) {
                        return replacement.toUpperCase();
                      } else if (matched[0] === matched[0].toUpperCase()) {
                        return replacement[0].toUpperCase() + replacement.slice(1);
                      } else {
                        return replacement;
                      }
                    })}
                  </DialogContentText>
                }
                open={showDraftModel}
                onClose={() => setShowDraftModel(false)}
                buttonPosition="center"
                actionButtons={
                  <Box my={3} textAlign="center">
                    <Button variant="contained" size="large" onClick={readFromDraftHandler}>
                      {MESSAGE.OPEN_DRAFT.replace(/draft/gi, function (matched) {
                        const replacement = 'reconciliation draft';
                        if (matched === matched.toUpperCase()) {
                          return replacement.toUpperCase();
                        } else if (matched[0] === matched[0].toUpperCase()) {
                          return replacement[0].toUpperCase() + replacement.slice(1);
                        } else {
                          return replacement;
                        }
                      })}
                    </Button>
                    <Button
                      size="large"
                      onClick={() => {
                        setShowDraftModel(false);
                        setShowClearDraftModel(true);
                      }}
                    >
                      {MESSAGE.USE_EXISTING_SAVED_DATA}
                    </Button>
                  </Box>
                }
              ></Modal>
              <Modal
                children={<DialogContentText variant="h2">{MESSAGE.CLEAR_DRAFT_CONFIRMATION} </DialogContentText>}
                open={showClearDraftModel}
                onClose={() => {
                  setShowClearDraftModel(false);
                  setShowDraftModel(true);
                }}
                actionButtons={
                  <Box my={3}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => {
                        deleteReconciliationDraftHandler(userDetails?.id + '_' + data?.location?.id + '_' + params?.id);
                      }}
                    >
                      {MESSAGE.OK}
                    </Button>
                    <Button
                      size="large"
                      onClick={() => {
                        setShowDraftModel(true);
                        setShowClearDraftModel(false);
                      }}
                    >
                      {MESSAGE.CANCEL}
                    </Button>
                  </Box>
                }
              ></Modal>
              <Modal
                children={<DialogContentText variant="h2">{deletePopupMessage}</DialogContentText>}
                open={isLastAssetOrSublocation}
                onClose={() => {
                  setIsLastAssetOrSublocation(false);
                }}
                actionButtons={
                  <Box my={3}>
                    <Button variant="contained" size="large" onClick={() => setIsLastAssetOrSublocation(false)}>
                      {MESSAGE.OK}
                    </Button>
                    <Button
                      size="large"
                      onClick={() => {
                        setIsLastAssetOrSublocation(false);
                      }}
                    >
                      {MESSAGE.CANCEL}
                    </Button>
                  </Box>
                }
              ></Modal>
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
};

ReconciliationDetail.displayName = displayName;
export default ReconciliationDetail;
