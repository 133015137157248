import { createSlice } from '@reduxjs/toolkit';
import { FILTER_VALUES } from '../../constants/FilterConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

const initialState = {
  openCollection: {
    [FILTER_VALUES.LOCATION]: [],
    [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
    [FILTER_VALUES.PRODUCT_TYPE]: [],
    [FILTER_VALUES.ACCOUNTING_USER]: [],
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
  reconciliation: {
    [FILTER_VALUES.LOCATION]: [],
    [FILTER_VALUES.LOCATION_TYPE]: [],
    [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
    [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
    [FILTER_VALUES.PRODUCT_TYPE]: [],
    [FILTER_VALUES.USER]: [],
    [FILTER_VALUES.ACCOUNTING_USER]: [],
    isQuickSearch: false,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
  approvedCollection: {
    [FILTER_VALUES.LOCATION]: [],
    [FILTER_VALUES.LOCATION_TYPE]: [],
    [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
    [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
    [FILTER_VALUES.PRODUCT_TYPE]: [],
    [FILTER_VALUES.USER]: [],
    [FILTER_VALUES.ACCOUNTING_USER]: [],
    isQuickSearch: false,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
};

export const collectionFilterSlice = createSlice({
  name: 'collectionFilter',
  initialState,
  reducers: {
    setOpenCollectionFilters(state, action) {
      state.openCollection[FILTER_VALUES.LOCATION] = action.payload?.locationId || [];
      state.openCollection[FILTER_VALUES.COLLECTION_SUBLOCATION] = action.payload?.sublocation || [];
      state.openCollection[FILTER_VALUES.ACCOUNTING_USER] = action.payload?.accountingUser || [];
      state.openCollection[FILTER_VALUES.PRODUCT_TYPE] = action.payload?.productType || [];
      state.openCollection.searchText = action.payload?.searchText || '';
      state.openCollection.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.openCollection.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    setReconciliationFilters(state, action) {
      state.reconciliation[FILTER_VALUES.LOCATION] = action.payload?.locationId || [];
      state.reconciliation[FILTER_VALUES.LOCATION_TYPE] = action.payload?.locationType || [];
      state.reconciliation[FILTER_VALUES.DATE_RANGE] = action.payload?.dateRange || { from: '', to: '' };
      state.reconciliation[FILTER_VALUES.COLLECTION_SUBLOCATION] = action.payload?.sublocation || [];
      state.reconciliation[FILTER_VALUES.PRODUCT_TYPE] = action.payload?.productType || [];
      state.reconciliation[FILTER_VALUES.USER] = action.payload?.user || [];
      state.reconciliation[FILTER_VALUES.ACCOUNTING_USER] = action.payload?.accountingUser || [];
      state.reconciliation.isQuickSearch = action.payload?.isQuickSearch || false;
      state.reconciliation.searchText = action.payload?.searchText || '';
      state.reconciliation.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.reconciliation.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    setApprovedCollectionFilters(state, action) {
      state.approvedCollection[FILTER_VALUES.LOCATION] = action.payload?.locationId || [];
      state.approvedCollection[FILTER_VALUES.LOCATION_TYPE] = action.payload?.locationType || [];
      state.approvedCollection[FILTER_VALUES.DATE_RANGE] = action.payload?.dateRange || { from: '', to: '' };
      state.approvedCollection[FILTER_VALUES.COLLECTION_SUBLOCATION] = action.payload?.sublocation || [];
      state.approvedCollection[FILTER_VALUES.PRODUCT_TYPE] = action.payload?.productType || [];
      state.approvedCollection[FILTER_VALUES.USER] = action.payload?.user || [];
      state.approvedCollection[FILTER_VALUES.ACCOUNTING_USER] = action.payload?.accountingUser || [];
      state.approvedCollection.isQuickSearch = action.payload?.isQuickSearch || false;
      state.approvedCollection.searchText = action.payload?.searchText || '';
      state.approvedCollection.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.approvedCollection.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    resetOpenCollectionFiltersState(state) {
      state.openCollection = { ...initialState.openCollection, searchText: state.openCollection.searchText };
    },
    resetReconciliationFilterState(state) {
      state.reconciliation = {
        ...initialState.reconciliation,
        isQuickSearch: state.reconciliation.isQuickSearch,
        searchText: state.reconciliation.searchText,
      };
    },
    resetApprovedCollectionFiltersState(state) {
      state.approvedCollection = {
        ...initialState.approvedCollection,
        isQuickSearch: state.approvedCollection.isQuickSearch,
        searchText: state.approvedCollection.searchText,
      };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default collectionFilterSlice;
