import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { Container, Box, Chip, Typography, Stack, Paper, Button, DialogContentText } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { Accordion, Breadcrumbs, Modal, NoContent, PositionedMenu } from '../../../components/shared';
import useStyles from './style';
import MachinesTable from './MachinesTable';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {
  useGetSublocationByIdQuery,
  useGetSublocationMachinesByIdQuery,
  useUpdateSubLocationByIdMutation,
} from '../../../services/Sublocation/SublocationService';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';
import SubLocationDetailAccordion from './SubLocationDetailAccordion';
import LocationOwnerDetailAccordion from '../../../components/LocationOwnerDetailAccordion';
import AssignNewMachine from './AssignNewMachine';
import { machineIcon, leaseIcon } from '../../../assets/images/icons/index';
import AssignNewLease from './AssignNewLease';
import LeaseDetailAccordion from './LeaseDetailAccordion';
import { useDispatch } from 'react-redux';
import { leaseDetailsFormAction } from '../../../redux-slice/lease';
import { useHasRoles } from '../../../hooks';
import { ROLE } from '../../../constants/roles';
import RemitDetailsAccordion from './RemitDetailsAccordion';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';
import { MESSAGE } from '../../../constants/message';

const SubLocationDetails = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [openAssignNew, setOpenAssignNew] = useState(false);
  const [openAssignNewLease, setOpenAssignNewLease] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const dispatch = useDispatch();
  const canEdit = useHasRoles([
    ROLE.BUSDEV_ADMINISTRATION,
    ROLE.SUPER_ADMIN_IT,
    ROLE.CUST_SUPPORT,
    ROLE.SALES,
    ROLE.ACCOUNTING,
  ]);

  const {
    LOCATION: { LOCATION, LOCATION_DETAIL, SUB_LOCATION_DETAIL },
  } = BREADCRUMB_NAMES;
  const apiSearchParams = 'includeUsers=true&includeAssets=true&includeContracts=true';
  const apiSearchParamsForMachine = 'includeAssetsSublocation=true&includeAssetsModel=true';
  const {
    currentData: subLocationDetailsResponse,
    isLoading,
    isFetching,
    refetch: refetchSublocationDetails,
  } = useGetSublocationByIdQuery({ id: Number(id), apiSearchParams });

  const {
    currentData: subLocationMachineListResponse,
    isLoading: isSubLocationMachineListLoading,
    isFetching: isSubLocationMachineListFetching,
    isSuccess: isSubLocationMachineListSuccess,
    refetch: refetchSublocationMachineById,
  } = useGetSublocationMachinesByIdQuery({
    id: Number(id),
    apiSearchParams: apiSearchParamsForMachine,
  });
  const [updateSubLocationApi, { isLoading: updating }] = useUpdateSubLocationByIdMutation();

  const navigate = useNavigate();
  const { data: detailsResponse } = subLocationDetailsResponse ?? {};

  const classes = useStyles();

  const breadcrumbData = [
    { text: LOCATION, redirection: `${routeConstants.LOCATION_ROUTE}` },
    {
      text: LOCATION_DETAIL,
      redirection: `${routeConstants.LOCATION_ROUTE}/${Number(searchParams.get('locationId'))}`,
    },
    { text: SUB_LOCATION_DETAIL },
  ];

  const editSubLocationClickHandler = () => {
    navigate(
      `/${routeConstants.SUBLOCATION_ROUTE}/${routeConstants.EDIT_SUB_LOCATION_ROUTE}/${Number(
        id,
      )}?locationId=${searchParams.get('locationId')}`,
    );
  };
  const editRemitClickHandler = () => {
    navigate(
      `/${routeConstants.SUBLOCATION_ROUTE}/${routeConstants.EDIT_REMIT_ROUTE}/${Number(
        id,
      )}?locationId=${searchParams.get('locationId')}`,
    );
  };

  const machineTableDisplay = () => {
    if (isSubLocationMachineListSuccess && subLocationMachineListResponse && subLocationMachineListResponse.data) {
      if (subLocationMachineListResponse?.data?.assets?.length <= 0 && !openAssignNew) {
        return (
          <Box className={classes.subLocDetailNoContent}>
            <NoContent
              icon={machineIcon}
              title="No Machines Associated"
              desc={
                <>
                  There is no machine associated to this sublocation.
                  <br />
                  Please add new machine
                </>
              }
              buttonName="Assign New"
              disabled={!canEdit}
              onBtnClickHandler={() => {
                setOpenAssignNew(true);
              }}
              className={classes.subLocationNoContentContainer}
            />
          </Box>
        );
      }

      return (
        <Box className={classes.subLocAssignedMachineWrap}>
          <Paper>
            {!openAssignNew ? (
              <Box className={classes.subLocAssignedMachineTable}>
                {isSubLocationMachineListLoading || isSubLocationMachineListFetching ? (
                  <LoadingSpinner containerHeight={400} />
                ) : (
                  <MachinesTable
                    machineList={subLocationMachineListResponse?.data?.assets}
                    openAssignNew={setOpenAssignNew}
                  />
                )}
              </Box>
            ) : (
              <AssignNewMachine
                sublocationId={detailsResponse?.id}
                openAssignNew={setOpenAssignNew}
                machineList={subLocationMachineListResponse?.data?.assets}
              />
            )}
          </Paper>
        </Box>
      );
    }
  };

  const onSaveAssignLeaseClickHandler = () => {
    setOpenAssignNewLease(false);
  };

  const onCancelAssignLeaseHandler = () => {
    setOpenAssignNewLease(false);
  };

  const dispatchLeaseValues = async () => {
    dispatch(
      leaseDetailsFormAction({
        leaseName: '',
        location: {
          id: detailsResponse?.location?.id,
          name: detailsResponse?.location?.locationName,
        },
        subLocation: [
          {
            id: detailsResponse?.id,
            name: detailsResponse?.name,
          },
        ],
        city: detailsResponse?.location?.city?.id || '',
        state: detailsResponse?.location?.state?.id || '',
        zipCode: detailsResponse?.location?.postcode || '',
        address1: detailsResponse?.location?.address1 || '',
        address2: detailsResponse?.location?.address2 || '',
        country: detailsResponse?.location?.country?.id || '',
        boothInfo: '',
        space: '',
        dba: '',
        salesPerson: '',
        leaseType: '',
        status: '',
        leaseBegins: null,
        leaseEnds: null,
      }),
    );
  };

  const onAddNewLeaseClickHandler = () => {
    dispatchLeaseValues()
      .then(() => {
        navigate(
          `/${routeConstants.ADD_LEASE_ROUTE}?location=${Number(searchParams.get('locationId'))}&subLocation=${Number(
            id,
          )}`,
        );
      })
      .catch((err) => {
        console.log('error in dispatching action', err);
      });
  };

  const EditLeaseAndRentClickHandler = () => {
    const lease = detailsResponse?.contractSublocations[0];
    const leaseId = lease?.contract?.id;

    navigate(
      `/${routeConstants.SUBLOCATION_ROUTE}/${routeConstants.EDIT_LEASE_AND_RENT}/${Number(leaseId)}?location=${Number(
        searchParams.get('locationId'),
      )}&subLocation=${Number(id)}`,
    );
  };

  const onArchiveClickHandler = () => {
    const status = detailsResponse?.status;
    updateSubLocationApi({
      id: id,
      status: !status,
    }).then(() => {
      setShowArchiveModal(false);
      refetchSublocationDetails();
      refetchSublocationMachineById();
    });
  };

  const leaseTableDisplay = () => {
    const lease = detailsResponse?.contractSublocations[0];
    if (detailsResponse?.contractSublocations?.length <= 0 && !openAssignNewLease) {
      return (
        <Box className={classes.subLocDetailNoContent} component={Paper}>
          <NoContent
            icon={leaseIcon}
            title="No Lease Found"
            desc={
              <>
                There is no Lease & Rent Agreement for this Sub Location.
                <br />
                Please add new or assign new Lease & Rent Agreement
              </>
            }
            buttonName="Assign New"
            secondBtnName="Add New"
            isTwoBtn={true}
            disabled={!canEdit}
            onBtnClickHandler={() => setOpenAssignNewLease(true)}
            onSecondBtnClickHandler={onAddNewLeaseClickHandler}
            className={classes.subLocationNoContentContainer}
          />
        </Box>
      );
    }
    return (
      <>
        {!openAssignNewLease ? (
          <Accordion
            marginBottom={3}
            title={'Lease & Rent Details'}
            content={
              isLoading || isFetching ? (
                <LoadingSpinner containerHeight={400} />
              ) : (
                <LeaseDetailAccordion leaseInfo={lease} />
              )
            }
            showMore
            subComponent={
              <PositionedMenu
                menuItemData={[
                  {
                    btnName: 'Edit',
                    btnClickFunc: EditLeaseAndRentClickHandler,
                  },
                ]}
                disabled={!canEdit}
              />
            }
          />
        ) : (
          <AssignNewLease onSaveClick={onSaveAssignLeaseClickHandler} onCancel={onCancelAssignLeaseHandler} />
        )}
      </>
    );
  };
  const assetStatus = detailsResponse?.assets.find((asset) => asset.statusId === 5);
  return (
    <Container maxWidth="xl" disableGutters className={classes.root}>
      <Box>
        <Breadcrumbs
          icon={<NavigateNext fontSize="medium" />}
          variant="body2"
          underline="hover"
          data={breadcrumbData}
        />
      </Box>
      <Box mb={4}>
        <Stack direction={'row'} alignItems="center" spacing={1.5}>
          <Typography variant="h1" data-testid="locationName">
            {detailsResponse?.name}
          </Typography>
          <Chip
            label={detailsResponse?.status ? 'Active' : 'Inactive'}
            color={detailsResponse?.status ? 'success' : 'error'}
            variant="outlined"
          />
        </Stack>
      </Box>
      <Box mb={3}>
        <Accordion
          defaultExpanded
          marginBottom={3}
          title={'Sub Location Details'}
          content={
            isLoading || isFetching ? (
              <LoadingSpinner containerHeight={400} />
            ) : (
              <SubLocationDetailAccordion subLocationInfo={detailsResponse} />
            )
          }
          showMore
          subComponent={
            <PositionedMenu
              menuItemData={[
                {
                  btnName: 'Edit',
                  btnClickFunc: editSubLocationClickHandler,
                },
                {
                  btnName: detailsResponse?.status ? 'Archive Sub-location' : 'Activate Sub-location',
                  btnClickFunc: () =>
                    assetStatus === undefined ? setShowArchiveModal(true) : setShowWarningModal(true),
                  btnTypeStyle: detailsResponse?.status ? 'dangerBtn' : 'successBtn',
                },
              ]}
              disabled={!canEdit}
            />
          }
        />
      </Box>
      <Box mb={3}>
        <Accordion
          marginBottom={3}
          title={'Remit Details'}
          content={
            isLoading || isFetching ? (
              <LoadingSpinner containerHeight={400} />
            ) : (
              <RemitDetailsAccordion subLocationInfo={detailsResponse} />
            )
          }
          showMore
          subComponent={
            <PositionedMenu
              menuItemData={[
                {
                  btnName: 'Edit',
                  btnClickFunc: editRemitClickHandler,
                },
              ]}
              disabled={!canEdit}
            />
          }
        />
      </Box>
      <Box mb={3}>{leaseTableDisplay()}</Box>
      <Box mb={3} className={classes.subLocationOwnerWrapper}>
        <Accordion
          title={'Sub Location Owner'}
          content={
            isLoading || isFetching ? (
              <LoadingSpinner />
            ) : (
              <LocationOwnerDetailAccordion ownerInfo={detailsResponse?.location?.owner} />
            )
          }
        />
      </Box>

      <Box component={Paper}>{machineTableDisplay()}</Box>
      <ConfirmationModal
        isLoading={updating}
        isOpen={showArchiveModal}
        title="Confirmation"
        msg={`Are you sure you want to ${
          detailsResponse?.status ? 'archive' : 'active'
        } this sub location? This process cannot be undone.`}
        buttons={[
          { text: detailsResponse?.status ? 'Archive' : 'Active', value: true },
          { text: 'Cancel', value: false },
        ]}
        onClick={(value) => {
          if (value) onArchiveClickHandler();
          else setShowArchiveModal(false);
        }}
      />
      <Modal
        title={MESSAGE.ARCHIVE_SUBLOCATION__WARNING_TITLE} // Replace with your warning message title
        titleVariant="subtitle10"
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        actionButtons={
          <Box my={3}>
            <Button
              size="large"
              onClick={() => {
                setShowWarningModal(false);
              }}
              variant="contained"
            >
              {MESSAGE.OK} {/* Replace with your OK message */}
            </Button>
          </Box>
        }
      >
        <Box>
          <DialogContentText variant="h2">
            {MESSAGE.ARCHIVE_SUBLOCATION_WARNING_DESCRIPTION} {/* Replace with your warning description */}
          </DialogContentText>
        </Box>
      </Modal>
    </Container>
  );
};

export default SubLocationDetails;
