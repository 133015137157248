import storedReconciliationCollectionsSlice from './storedReconciliationCollections-slice';
export const {
  setReconciliationCollection,
  resetStateOfReconciliation,
  resetCollectionByLocationIdInReconciliation,
  resetCollectionByTransactionIdInReconciliation,
  setDraftSublocationDatesInReconciliation,
  reduceDraftDatesBySublocationIdsInReconciliation,
} = storedReconciliationCollectionsSlice.actions;

export default storedReconciliationCollectionsSlice;
