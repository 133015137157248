import React, { useEffect, useMemo, useState } from 'react';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { UPDATE_STATE_TAX_RATE_TABLE } from '../../constants/tableColumns';
import LoadingSpinner from '../../components/LoadingSpinner';
import { NoContent, Breadcrumbs, ReactTable } from '../../components/shared';
import { Box, Tooltip, Typography } from '@mui/material';
import useStyles from './style';
import {
  useGetAllStatesQuery,
  useLazyGetAllCountriesQuery,
  useUpdateStateMutation,
} from '../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import BREADCRUMB_NAMES from '../../constants/breadcrumbNames';
import { routeConstants } from '../../constants/routeConstants';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../../components/SearchBar';
import { resetStateFiltersState, setStateFilters } from '../../redux-slice/statesFilter';
import { useSearchFilterParam } from '../../hooks';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditStateTaxRateModal from './editStateTaxRateModal';
import NewFilterDrawer from '../../components/shared/FilterDrawer/NewFilterDrawer';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../constants/FilterConstants';

const UpdateStateTaxrate = () => {
  const styles = useStyles();
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [perPageNumber, setPerPageNumber] = useState(DEFAULT_PER_PAGE);
  const [filterParams, setFilterParams] = useState(FILTER_DATA.STATE.INITIAL_PARAMS);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const {
    SETTINGS: { UPDATE_STATE_TAX_RATE, SETTINGS },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = useMemo(
    () => [
      { text: SETTINGS, redirection: routeConstants.SETTING_ROUTE },
      { text: UPDATE_STATE_TAX_RATE, redirection: routeConstants.UPDATE_STATE_TAX_RATE },
    ],
    [],
  );
  const { userDetails } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { states } = useSelector((state) => state.statesFilter);
  const [updateStateTax] = useUpdateStateMutation();
  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.STATE,
    filterParams,
    searchText,
  });

  const {
    data: latestData,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useGetAllStatesQuery({
    searchParams: searchParams,
    page: currentPage,
    perPage: perPageNumber,
  });

  const [getAllCountries] = useLazyGetAllCountriesQuery();

  useEffect(() => {
    const storedFilterParams = { ...FILTER_DATA.STATE.INITIAL_PARAMS };
    storedFilterParams.countryId = states[FILTER_VALUES.COUNTRY] ?? {};
    setSearchText(states.searchText);
    setFilterParams(storedFilterParams);
  }, [states]);

  const getAPI = (value) => {
    switch (value) {
      case FILTER_VALUES.COUNTRY:
        return getAllCountries;
      default:
        return [];
    }
  };

  const stateColumns = UPDATE_STATE_TAX_RATE_TABLE();
  const customColumn = [
    {
      Header: () => null,
      id: 'editRate',
      Cell: (row) => (
        <Tooltip title="Edit" arrow>
          <span data-testid="expandIcon">
            <ModeEditIcon onClick={() => handleEditClick(row?.row?.original)} />
          </span>
        </Tooltip>
      ),
    },
  ];
  const columns = [...stateColumns, ...customColumn];
  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRowData(null);
  };
  const handleConfirmModal = (rate) => {
    setIsModalOpen(false);
    setSelectedRowData(null);
    updateStateTax({ id: Number(rate.name), stateTaxRate: rate.value, updatedBy: userDetails?.id }).then(() => {
      refetch();
    });
  };

  useEffect(() => {
    if (isSuccess && latestData) {
      setTableData(latestData?.data?.states);
      let total = latestData?.pagination?.total || 0;
      setTotalCount(total);
    } else if (isError) {
      setTotalCount(0);
    }
  }, [isError, isSuccess, latestData]);
  const pageChangeHandler = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    setPerPageNumber(newPerPage);
  };
  useEffect(() => {
    setCurrentPage(states?.pageNumber);
    setPerPageNumber(states?.rowsPerPage);
  }, [searchParams]);

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setStateFilters({
        ...states,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };
  const onReset = () => {
    setSearchText('');
    dispatch(
      setStateFilters({
        ...states,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };
  const filterData = useMemo(
    () =>
      FILTER_DATA.STATE.FILTER.map((item) => {
        const api = getAPI(item.value);

        return {
          ...item,
          api,
        };
      }),
    [],
  );
  const applyFilterHandler = (filterValues) => {
    dispatch(
      setStateFilters({
        ...states,
        ...filterValues,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setFilterParams(filterValues);
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.STATE.INITIAL_PARAMS);
    dispatch(resetStateFiltersState());
  };
  return (
    <>
      <Box>
        <Breadcrumbs variant="body2" underline="hover" data={breadcrumbData} />
      </Box>
      <React.Fragment>
        <Typography mt={'34px'} variant="h1">
          Update State Tax Rate
        </Typography>
        <Box className={styles.stateTable} pt={2}>
          <Box className={styles.searchFilterWrap} px={3} alignItems={'center'}>
            <Box className={styles.searchWrap}>
              <SearchBar
                onSearch={searchHandler}
                placeholder={'Search by state'}
                onReset={onReset}
                searchText={searchText}
              />
            </Box>
            <Box pl={3} sx={{ mb: '10px' }}>
              <NewFilterDrawer
                filteredValues={filterParams}
                name={TITLE.FILTERS.toUpperCase()}
                title={TITLE.FILTERS}
                drawerType={DRAWER_TYPES.FILTER_DRAWER}
                onReset={resetFilterParams}
                onApplyFilter={(v) => {
                  applyFilterHandler(v);
                }}
                filterData={filterData}
                emptyFilterLength={JSON.stringify(FILTER_DATA.STATE.INITIAL_PARAMS).length}
              />
            </Box>
          </Box>
          <Box>
            {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
            {isError && (
              <NoContent title="No States Found" desc={(isError && error?.data?.message) || <>No Data Found</>} />
            )}

            <>
              {!isLoading && !isError && !isFetching && tableData && (
                <ReactTable
                  data={tableData}
                  columns={columns}
                  pagination
                  manualPagination
                  totalCount={totalCount}
                  searchParams={searchParams}
                  pageNumberForTablePagination={currentPage - 1}
                  onPageChange={pageChangeHandler}
                  onPerPageChange={perPageChangeHandler}
                  perPage={perPageNumber}
                />
              )}
            </>
            {isModalOpen && (
              <EditStateTaxRateModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={(rate) => handleConfirmModal(rate)}
                rowData={selectedRowData}
              />
            )}
          </Box>
        </Box>
      </React.Fragment>
    </>
  );
};
export default UpdateStateTaxrate;
