export const LOADING_STATE = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
  SIGNED_OUT: 'SIGNED_OUT',
  BACKEND_ISSUE: 'BACKEND_ISSUE',
};

export const COLLECTION_TYPES = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  RECONCILIATION: 'RECONCILIATION',
};

export const INDEPENDENT_REP_OF_SUBLOCATION = {
  REP_ID: 'independentRepId',
  PAY_CODE: 'independentRepsPaycode',
  FEE: 'independentRepsFee',
  RATE: 'independentRepsRate',
};

export const TAB_TYPES = {
  ACTIVE: 'Active',
  ARCHIVE: 'Archive',
  PENDING: 'Pending',
  OPEN_COLLECTION: 'Open Collections',
  RECONCILIATION: 'Reconciliation',
  APPROVED: 'Approved',
  PAST_COLLECTIONS: 'Past Collections',
  OPEN_DEPOSIT: 'Open Deposit',
  SUBMITTED_COLLECTIONS: 'Submitted',
  SUBMITTED_LOCATION_COLLECTIONS: 'Collection for Location',
  SUBMITTED_SUB_LOCATION_COLLECTIONS: 'Collection for Sublocation',
};

export const SYNC_STATUS = {
  IDLE: 'IDLE',
  SYNCING: 'SYNCING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const VENDOR_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVE: 'INACTIVE',
};

export const COLLECTION_RECONCILIATION_VERIFIED = 'VERIFIED';
export const COLLECTION_RECONCILIATION_APPROVED = 'APPROVED';
export const COLLECTION_RECONCILIATION_SUBMITTED = 'SUBMITTED';

export const RECONCILIATION_WARNING_COMPARING_VALUE = 25;
export const DRAFT_COLLECTION_LOCAL_STORAGE_LIMIT = 4;
export const KB = 1024;

export const ZERO_VALUE = 0;
