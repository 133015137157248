import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  CheckBox,
  Datepicker,
  FieldWarning,
  GridContainer,
  GridItem,
  Link,
  PercentTextField,
  Select,
  TextField,
  Timepicker,
} from '../../../components/shared';
import { TextMaskCustom } from '../../../components/TextMaskCustom/TextMaskCustom';
import { routeConstants } from '../../../constants/routeConstants';
import { subLocationDetailsAction } from '../../../redux-slice/location';
import { useAddLocationMutation } from '../../../services/Location/LocationService';
import {
  useAddSubLocationMutation,
  useGetAreaTaxLocationsQuery,
  useGetProductCategoriesQuery,
  useGetSubLocationTypesQuery,
} from '../../../services/Sublocation/SublocationService';
import { dateFormatForApi, get24HoursTimeFormat, getDateFormat, getOptions } from '../../../utils/common-methods';
import useStyles from './style';
import { LoadingButton } from '@mui/lab';
import { FormikProvider, useFormik } from 'formik';
import { AddNewLocationSchema } from '../../../schema/validationSchemas';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../../hooks';

const AddSubLocationsFrom = (props) => {
  const { onBackClick } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openHrsClockPopup, setOpenHrsClockPopup] = useState(false);
  const [openCloseHrsClockPopup, setOpenCloseHrsClockPopup] = useState(false);
  const [areaTaxLocationSearchText, setAreaTaxLocationSearchText] = useState('');

  const [addNewLocationApi, { isLoading: locationLoading }] = useAddLocationMutation();
  const [addNewSubLocation, { isLoading: subLocationLoading }] = useAddSubLocationMutation();
  const locationState = useSelector((state) => state?.location);
  const { apiData, addNewLocationForms } = locationState;
  const { locationDetails, subLocationDetails, ownerDetails } = addNewLocationForms;
  const { countryData, statesData, citiesData } = apiData;
  const { currentData: locationTypesData, isSuccess: typeSuccess } = useGetSubLocationTypesQuery();

  const taxExemptOptions = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  const { currentData: productTypes } = useGetProductCategoriesQuery();
  const productTypeOptions = getOptions(productTypes);
  const locationTypeOptions = getOptions(locationTypesData);

  const debouncedTaxLocationSearchText = useDebounce(areaTaxLocationSearchText, 300);

  const { currentData: areaTaxLocations } = useGetAreaTaxLocationsQuery({
    page: 1,
    perPage: 100,
    searchParams: `q=${debouncedTaxLocationSearchText}`,
  });
  const formik = useFormik({
    initialValues: {
      subLocationName: subLocationDetails?.subLocationName,
      subLocationType: subLocationDetails?.subLocationType,
      address1: subLocationDetails?.address1,
      address2: subLocationDetails?.address2,
      phone1: subLocationDetails?.phone1,
      phone2: subLocationDetails?.phone2,
      openHours: subLocationDetails?.openHours?.from,
      closeHours: subLocationDetails?.openHours?.to,
      startDate: subLocationDetails?.startDate,
      endDate: subLocationDetails?.endDate,
      uninstallDate: subLocationDetails?.uninstallDate,
      productType: subLocationDetails?.productType || null,
      areaTaxLocation:
        areaTaxLocations?.find((location) => location.text === subLocationDetails?.areaTaxLocation) || null,
      stateTaxRate: subLocationDetails?.stateTaxRate || '',
      areaTaxRate: subLocationDetails?.areaTaxRate || '',
      miscTax: subLocationDetails?.miscTax || '',
      locPaysTaxFlag: subLocationDetails?.locPaysTaxFlag || false,
      calculateRent: subLocationDetails?.calculateRent || false,
      taxExemptFlag: subLocationDetails?.taxExemptFlag || false,
    },
    validationSchema: AddNewLocationSchema.addSubLocationSchema,
    onSubmit: () => {
      callAddLocationApi('add');
    },
  });

  const { errors, touched, setValues, handleSubmit } = formik;

  const autoCompleteChangeHandler = (name, value) => {
    if (name === 'areaTaxLocation') {
      setAreaTaxLocationSearchText(value ? value.text : ''); // Update the areaTaxLocationSearchText for fetching data
    }
    const subLocationObj = {
      ...subLocationDetails,
      [name]: value,
    };
    dispatch(subLocationDetailsAction(subLocationObj));
  };
  // update value in formik when component mount.
  useEffect(() => {
    setValues({
      subLocationName: subLocationDetails?.subLocationName,
      subLocationType: subLocationDetails?.subLocationType,
      address1: subLocationDetails?.address1,
      address2: subLocationDetails?.address2,
      phone1: subLocationDetails?.phone1,
      phone2: subLocationDetails?.phone2,
      openHours: subLocationDetails?.openHours?.from,
      closeHours: subLocationDetails?.openHours?.to,
      startDate: subLocationDetails?.startDate,
      endDate: subLocationDetails?.endDate,
      uninstallDate: subLocationDetails?.uninstallDate ?? null,
      productType: subLocationDetails?.productType || null,
      areaTaxLocation: subLocationDetails?.areaTaxLocation || 'None',
      stateTaxRate: subLocationDetails?.stateTaxRate || '',
      areaTaxRate: subLocationDetails?.areaTaxRate || '',
      miscTax: subLocationDetails?.miscTax || '',
      locPaysTaxFlag: subLocationDetails?.locPaysTaxFlag || false,
      calculateRent: subLocationDetails?.calculateRent || false,
      taxExemptFlag: subLocationDetails?.taxExemptFlag,
    });
  }, [subLocationDetails]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target || e;

    const subLocationObj = {
      ...subLocationDetails,
      [name]: value,
    };
    dispatch(subLocationDetailsAction(subLocationObj));
  };

  const setHours = (key, value) => {
    if (dayjs(value).toString() !== 'Invalid Date') {
      const subLocationObj = {
        ...subLocationDetails,
        openHours: {
          ...subLocationDetails.openHours,
          [key]: dayjs(value).toString(),
        },
      };
      dispatch(subLocationDetailsAction(subLocationObj));
    }
  };

  const openHourFromChangeHandler = (value) => {
    setHours('from', value);
  };

  const openHourToChangeHandler = (value) => {
    setHours('to', value);
  };

  const setDates = (key, value) => {
    const subLocationObj = {
      ...subLocationDetails,
      [key]: getDateFormat(value),
    };
    dispatch(subLocationDetailsAction(subLocationObj));
  };

  const startDateChangeHandler = (value) => {
    setDates('startDate', value);
  };

  const endDateChangeHandler = (value) => {
    setDates('endDate', value);
  };

  const uninstallDateChangeHandler = (value) => {
    setDates('uninstallDate', value);
  };

  const callAddLocationApi = (status) => {
    const data = {
      locationName: locationDetails?.locationName,
      cityId: locationDetails?.city ? Number(locationDetails?.city) : null,
      stateId: locationDetails?.state ? Number(locationDetails?.state) : null,
      countryId: locationDetails?.country ? Number(locationDetails?.country) : null,
      postcode: locationDetails?.zipCode,
      address1: locationDetails?.address1,
      address2: locationDetails?.address2,
      phone1: locationDetails?.phone1.replaceAll('-', ''),
      phone2: locationDetails?.phone2.replaceAll('-', ''),
      ownerId: ownerDetails?.ownerId?.id,
      locationTypeId: locationDetails?.locationType,
      currencyId: locationDetails?.currency ? Number(locationDetails?.currency) : null,
      X3_LocationsID: locationDetails?.X3_LocationsID,
      X3_LocationName: locationDetails?.X3_LocationName,
      X3_DepartmentID: locationDetails?.X3_DepartmentID,
      X3_IndustryID: locationDetails?.X3_IndustryID,
      X3_OwnerID: locationDetails?.X3_OwnerID,
    };
    addNewLocationApi(data).then((res) => {
      if (status === 'add') {
        if (res?.data?.data?.id) {
          let openHours = '';
          let closeHours = '';
          const productCategaries = subLocationDetails?.productType?.value
            ? [subLocationDetails?.productType?.value]
            : [];
          if (subLocationDetails?.openHours?.from) {
            openHours = get24HoursTimeFormat(subLocationDetails.openHours.from);
          }
          if (subLocationDetails?.openHours?.to) {
            closeHours = get24HoursTimeFormat(subLocationDetails.openHours.to);
          }
          const subLocationData = {
            locationId: res?.data?.data?.id,
            type: subLocationDetails?.subLocationType,
            code: subLocationDetails?.zipCode,
            startDate: subLocationDetails?.startDate ? dateFormatForApi(subLocationDetails?.startDate) : null,
            endDate: subLocationDetails?.endDate ? dateFormatForApi(subLocationDetails?.endDate) : null,
            uninstallDate: subLocationDetails?.uninstallDate
              ? dateFormatForApi(subLocationDetails?.uninstallDate)
              : null,
            name: subLocationDetails?.subLocationName,
            spaceIdentifier: subLocationDetails?.spaceIdentifier,
            address1: subLocationDetails?.address1,
            address2: subLocationDetails?.address2,
            phone1: subLocationDetails?.phone1.replaceAll('-', ''),
            phone2: subLocationDetails?.phone2.replaceAll('-', ''),
            openHours: openHours,
            closeHours: closeHours,
            productCategories: productCategaries || [],
            areaTaxLocation: subLocationDetails?.areaTaxLocation?.value || 'None',
            stateTaxRate: subLocationDetails?.stateTaxRate || '',
            areaTaxRate: subLocationDetails?.areaTaxRate || '',
            miscTax: subLocationDetails?.miscTax || '',
            locPaysTaxFlag: subLocationDetails?.locPaysTaxFlag,
            calculateRent: subLocationDetails?.calculateRent,
            taxExemptFlag: subLocationDetails?.taxExemptFlag,
          };
          addNewSubLocation(subLocationData).then((res1) => {
            if (res1.data) {
              navigate(`/${routeConstants.LOCATION_ROUTE}`);
            }
          });
        }
      }
      if (status === 'skip') {
        navigate(`/${routeConstants.LOCATION_ROUTE}`);
      }
    });
  };

  const onSkipClickHandler = () => {
    callAddLocationApi('skip');
  };
  const openHrsPopupHandler = () => {
    setOpenHrsClockPopup(true);
  };
  const closeHrsPopupHandler = () => {
    setOpenCloseHrsClockPopup(true);
  };
  const calculateRentChangeHandler = (e) => {
    const subLocationObj = {
      ...subLocationDetails,
      calculateRent: e.target.checked,
    };
    dispatch(subLocationDetailsAction(subLocationObj));
  };
  const locPaysTaxFlagChangeHandler = (e) => {
    const subLocationObj = {
      ...subLocationDetails,
      locPaysTaxFlag: e.target.checked,
    };
    dispatch(subLocationDetailsAction(subLocationObj));
  };

  return (
    <FormikProvider value={formik}>
      <Box className={classes.locationDetailsForm} data-testid="subLocationComponent">
        <GridContainer rowSpacing={3} columnSpacing={{ xs: 0, sm: 0, md: 7 }}>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Sub Location Name"
              name="subLocationName"
              value={subLocationDetails?.subLocationName}
              onChange={onChangeHandler}
              error={Boolean(touched.subLocationName && errors.subLocationName)}
              helperText={touched.subLocationName && errors.subLocationName}
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Zip Code"
              name="zipCode"
              value={subLocationDetails?.zipCode}
              onChange={onChangeHandler}
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Phone 1"
              name="phone1"
              value={subLocationDetails?.phone1}
              onChange={onChangeHandler}
              InputProps={{
                inputComponent: TextMaskCustom,
                'data-testid': 'locationDetailForms',
              }}
              error={Boolean(touched.phone1 && errors.phone1)}
              helperText={touched.phone1 && errors.phone1}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Phone 2"
              name="phone2"
              value={subLocationDetails?.phone2}
              onChange={onChangeHandler}
              InputProps={{
                inputComponent: TextMaskCustom,
                'data-testid': 'locationDetailForms',
              }}
              error={Boolean(touched.phone2 && errors.phone2)}
              helperText={touched.phone2 && errors.phone2}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Stack direction="row" spacing={2}>
              <Timepicker
                label="Open Hours"
                name="openHours"
                open={openHrsClockPopup}
                onOpen={() => setOpenHrsClockPopup(true)}
                onClose={() => setOpenHrsClockPopup(false)}
                value={subLocationDetails?.openHours?.from}
                onChange={openHourFromChangeHandler}
                InputProps={{
                  'data-testid': 'locationDetailForms',
                  onClick: openHrsPopupHandler,
                  disabled: true,
                }}
                inputProps={{
                  readOnly: true,
                }}
                error={Boolean(subLocationDetails?.openHours?.from && errors.openHours)}
                helperText={subLocationDetails?.openHours?.from && errors.openHours}
              />
              <Timepicker
                label="Close Hours"
                name="closeHours"
                open={openCloseHrsClockPopup}
                onOpen={() => setOpenCloseHrsClockPopup(true)}
                onClose={() => setOpenCloseHrsClockPopup(false)}
                value={subLocationDetails?.openHours?.to}
                onChange={openHourToChangeHandler}
                InputProps={{
                  'data-testid': 'locationDetailForms',
                  onClick: closeHrsPopupHandler,
                  disabled: true,
                }}
                inputProps={{
                  readOnly: true,
                }}
                error={Boolean(subLocationDetails?.openHours?.to && errors.closeHours)}
                helperText={subLocationDetails?.openHours?.to && errors.closeHours}
              />
            </Stack>
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            {locationTypesData && typeSuccess && (
              <Select
                options={locationTypeOptions}
                inputLabel="Sub Location Type"
                name="subLocationType"
                value={subLocationDetails?.subLocationType}
                onChange={onChangeHandler}
                error={Boolean(touched.subLocationType && errors.subLocationType)}
                helperText={touched.subLocationType && errors.subLocationType}
                data-testid="locationDetailForms"
              />
            )}
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Location"
              name="location"
              value={locationDetails?.locationName}
              disabled
              onChange={onChangeHandler}
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              options={countryData}
              inputLabel="Country"
              name="country"
              value={locationDetails?.country}
              disabled
              data-testid="locationDetailForms"
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              options={statesData}
              inputLabel="State"
              name="state"
              value={locationDetails?.state}
              data-testid="locationDetailForms"
              disabled
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              options={citiesData}
              inputLabel="City"
              disabled
              name="city"
              value={locationDetails?.city}
              data-testid="locationDetailForms"
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Datepicker
              label="Installed Date"
              name="startDate"
              value={subLocationDetails?.startDate}
              onChange={startDateChangeHandler}
              inputFormat="MM/DD/YYYY"
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
              error={Boolean(touched.startDate && errors.startDate)}
              helperText={touched.startDate && errors.startDate}
            />
            {!formik.values.startDate ? <FieldWarning field="Installed Date"></FieldWarning> : null}
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Datepicker
              label="Lease End Date"
              name="endDate"
              value={subLocationDetails?.endDate}
              onChange={endDateChangeHandler}
              inputFormat="MM/DD/YYYY"
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
              error={Boolean(touched.endDate && errors.endDate)}
              helperText={touched.endDate && errors.endDate}
            />
            {!formik.values.endDate ? <FieldWarning field="Lease End Date"></FieldWarning> : null}
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Datepicker
              label="Uninstall Date"
              name="uninstallDate"
              value={subLocationDetails?.uninstallDate}
              onChange={uninstallDateChangeHandler}
              inputFormat="MM/DD/YYYY"
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
              error={Boolean(touched.uninstallDate && errors.uninstallDate)}
              helperText={touched.uninstallDate && errors.uninstallDate}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Space Identifier"
              name="spaceIdentifier"
              value={subLocationDetails?.spaceIdentifier}
              onChange={onChangeHandler}
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Address 1"
              name="address1"
              value={subLocationDetails?.address1}
              onChange={onChangeHandler}
              multiline
              error={Boolean(touched.address1 && errors.address1)}
              helperText={touched.address1 && errors.address1}
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <TextField
              label="Address 2"
              name="address2"
              value={subLocationDetails?.address2}
              onChange={onChangeHandler}
              multiline
              error={Boolean(touched.address2 && errors.address2)}
              helperText={touched.address2 && errors.address2}
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Autocomplete
              label="Product Type"
              name="productType"
              data-testid="form-elements"
              value={formik.values.productType || null}
              options={productTypeOptions || []}
              getOptionLabel={(option) => option.text || ''}
              onChange={(e, value) => {
                onChangeHandler({ target: { name: 'productType', value } });
              }}
              onBlur={() => formik.setFieldTouched('productType')}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              clearOnBlur={false}
              error={Boolean(touched.productType && errors.productType)}
              helperText={touched.productType && errors.productType}
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <PercentTextField
              label="State tax"
              name="stateTaxRate"
              allowDecimal
              onChange={onChangeHandler}
              value={subLocationDetails?.stateTaxRate}
              error={Boolean(touched.stateTaxRate && errors.stateTaxRate)}
              helperText={touched.stateTaxRate && errors.stateTaxRate}
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
            />
            {!formik.values.stateTaxRate ? <FieldWarning field="State tax"></FieldWarning> : null}
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <PercentTextField
              label="Area tax"
              name="areaTaxRate"
              allowDecimal
              onChange={onChangeHandler}
              value={subLocationDetails?.areaTaxRate}
              error={Boolean(touched.areaTaxRate && errors.areaTaxRate)}
              helperText={touched.areaTaxRate && errors.areaTaxRate}
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
            />
            {!formik.values.areaTaxRate ? <FieldWarning field="Area tax"></FieldWarning> : null}
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <PercentTextField
              label="Misc tax"
              name="miscTax"
              allowDecimal
              onChange={onChangeHandler}
              value={subLocationDetails?.miscTax}
              error={Boolean(touched.miscTax && errors.miscTax)}
              helperText={touched.miscTax && errors.miscTax}
              InputProps={{
                'data-testid': 'locationDetailForms',
              }}
            />
            {!formik.values.miscTax ? <FieldWarning field="Misc Tax"></FieldWarning> : null}
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Select
              options={taxExemptOptions}
              inputLabel="Tax Exempt"
              name="taxExemptFlag"
              value={subLocationDetails?.taxExemptFlag ? subLocationDetails?.taxExemptFlag : false}
              onChange={onChangeHandler}
              data-testid="locationDetailForms"
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <Autocomplete
              label="Area Tax Location"
              name="areaTaxLocation"
              data-testid="form-elements"
              value={formik.values.areaTaxLocation || null}
              options={areaTaxLocations || []}
              getOptionLabel={(option) => option.text || ''}
              onChange={(e, value) => autoCompleteChangeHandler('areaTaxLocation', value)}
              onBlur={() => formik.setFieldTouched('areaTaxLocation')}
              onInputChange={(event, value) => setAreaTaxLocationSearchText(value)}
            />
            {!formik.values.areaTaxLocation?.value ? <FieldWarning field="Area Tax Location"></FieldWarning> : null}
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <CheckBox
              label="Calculate Rent"
              labelPlacement="end"
              checked={subLocationDetails?.calculateRent}
              onChange={calculateRentChangeHandler}
              data-testid="editSubLocation"
            />
          </GridItem>
          <GridItem xs={12} md={6} pl={0}>
            <CheckBox
              label="Location Pays Tax"
              labelPlacement="end"
              checked={subLocationDetails?.locPaysTaxFlag}
              onChange={locPaysTaxFlagChangeHandler}
              data-testid="editSubLocation"
            />
          </GridItem>
        </GridContainer>
        <Box className={classes.skipThisStepWrap}>
          <Link
            component="button"
            loading={locationLoading ? locationLoading : false}
            underline="none"
            className={classes.skipThisStep}
            onClick={onSkipClickHandler}
          >
            Skip This Step For Now!
          </Link>
        </Box>
        <Box mt={6}>
          <Stack direction="row" spacing={3}>
            <LoadingButton
              loading={subLocationLoading ? subLocationLoading : false}
              variant="contained"
              data-testid="submitBtn"
              onClick={() => handleSubmit()}
            >
              Add
            </LoadingButton>
            <Button onClick={onBackClick} data-testid="backBtn">
              Back
            </Button>
          </Stack>
        </Box>
      </Box>
    </FormikProvider>
  );
};

export default AddSubLocationsFrom;
