import React, { useState, useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import { sortIcon } from '../../../assets/images/icons';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';

import SearchBar from '../../../components/SearchBar';
import UsersTable from './UsersTable';

import {
  useLazyGetAllCountriesQuery,
  useLazyGetCountryStatesByCountryIdQuery,
  useLazyGetStateCitiesByStateIdQuery,
} from '../../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import { useLazyGetAllRolesQuery } from '../../../services/UserRoles/UserRolesService';
import { useLazyGetLocationsQuery } from '../../../services/Location/LocationService';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../../constants/FilterConstants';
import SORT_CONSTANT, { LATEST_UPDATED } from '../../../constants/SortConstants';
import { DEFAULT_PER_PAGE, DEFAULT_PAGE } from '../../../constants/pagination';

import useStyles from './style';
import { useSearchFilterParam } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { resetArchiveUsersFiltersState, setArchiveUsersFilters } from '../../../redux-slice/usersFilters';

const ArchiveTab = ({ onTotalUsers }) => {
  const classes = useStyles();
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const dispatch = useDispatch();
  const { archiveUsers } = useSelector((state) => state.usersFilter);

  const [searchText, setSearchText] = useState('');
  const [filterParams, setFilterParams] = useState(FILTER_DATA.USER.INITIAL_PARAMS);
  const [sort, setSort] = useState(LATEST_UPDATED);

  const [getLocations] = useLazyGetLocationsQuery();
  const [getAllRole] = useLazyGetAllRolesQuery();
  const [getAllCountries] = useLazyGetAllCountriesQuery();
  const [getStatesByCountryId] = useLazyGetCountryStatesByCountryIdQuery();
  const [getCitiesByStateId] = useLazyGetStateCitiesByStateIdQuery();

  const getAPI = (value) => {
    switch (value) {
      case FILTER_VALUES.ROLE:
        return getAllRole;
      case FILTER_VALUES.LOCATION:
        return getLocations;
      case FILTER_VALUES.COUNTRY:
        return getAllCountries;
      case FILTER_VALUES.STATE:
        return getStatesByCountryId;
      case FILTER_VALUES.CITY:
        return getCitiesByStateId;

      default:
        return [];
    }
  };

  const filterData = useMemo(
    () =>
      FILTER_DATA.USER.FILTER.map((item) => {
        const api = getAPI(item.value);

        return {
          ...item,
          api,
        };
      }),
    [],
  );

  useEffect(() => {
    const storedFilterParams = {
      [FILTER_VALUES.ROLE]: [],
      [FILTER_VALUES.LOCATION]: [],
      [FILTER_VALUES.COUNTRY]: null,
      [FILTER_VALUES.STATE]: null,
      [FILTER_VALUES.CITY]: [],
    };

    if (archiveUsers[FILTER_VALUES.ROLE] && archiveUsers[FILTER_VALUES.ROLE]?.length > 0) {
      storedFilterParams.role = archiveUsers[FILTER_VALUES.ROLE];
    }
    if (archiveUsers[FILTER_VALUES.LOCATION] && archiveUsers[FILTER_VALUES.LOCATION]?.length > 0) {
      storedFilterParams.locationId = archiveUsers[FILTER_VALUES.LOCATION];
    }
    if (archiveUsers[FILTER_VALUES.COUNTRY] && Object.keys(archiveUsers[FILTER_VALUES.COUNTRY]).length > 0) {
      storedFilterParams.countryId = archiveUsers[FILTER_VALUES.COUNTRY];
    }
    if (archiveUsers[FILTER_VALUES.STATE] && Object.keys(archiveUsers[FILTER_VALUES.STATE]).length > 0) {
      storedFilterParams.stateId = archiveUsers[FILTER_VALUES.STATE];
    }
    if (archiveUsers[FILTER_VALUES.CITY] && archiveUsers[FILTER_VALUES.CITY]?.length > 0) {
      storedFilterParams.cityId = archiveUsers[FILTER_VALUES.CITY];
    }
    setSearchText(archiveUsers.searchText);
    setSort(archiveUsers.sort);
    setFilterParams(storedFilterParams);
  }, [archiveUsers]);

  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.USER,
    filterParams,
    sort,
    searchText,
    extraParam: 'status=false',
  });

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setArchiveUsersFilters({
        ...archiveUsers,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const onReset = () => {
    setSearchText('');
    dispatch(
      setArchiveUsersFilters({
        ...archiveUsers,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const applyFilterHandler = (filterValues) => {
    dispatch(
      setArchiveUsersFilters({
        ...archiveUsers,
        ...filterValues,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setFilterParams(filterValues);
  };

  const handleSort = (e) => {
    dispatch(
      setArchiveUsersFilters({
        ...archiveUsers,
        sort: e.target.value,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setSort(e.target.value);
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.USER.INITIAL_PARAMS);
    dispatch(resetArchiveUsersFiltersState());
  };

  return (
    <>
      <Box className={classes.searchFilterWrap}>
        <Box className={classes.searchWrap}>
          <SearchBar searchText={searchText} onSearch={searchHandler} onReset={onReset} />
        </Box>
        <Box px={3}>
          <NewFilterDrawer
            name={TITLE.SORT_BY.toUpperCase()}
            title={TITLE.SORT_BY}
            drawerType={DRAWER_TYPES.SORT_DRAWER}
            startIcon={<>{sortIcon}</>}
            onSortSelect={handleSort}
            selectedSort={sort}
            sortData={SORT_CONSTANT.USER_SORT}
          />
        </Box>
        <Box pr={3}>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            onApplyFilter={(v) => {
              applyFilterHandler(v);
            }}
            filterData={filterData}
            emptyFilterLength={JSON.stringify(FILTER_DATA.USER.INITIAL_PARAMS).length}
          />
        </Box>
      </Box>
      <UsersTable
        searchParams={searchParams}
        page={DEFAULT_PAGE}
        perPage={DEFAULT_PER_PAGE}
        onTotalUser={onTotalUsers}
        archiveUsers={archiveUsers}
        tab="archive"
      />
    </>
  );
};

export default ArchiveTab;
