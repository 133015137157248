export const FILTER_VALUES = {
  ROLE: 'role',
  LOCATION: 'locationId',
  COUNTRY: 'countryId',
  STATE: 'stateId',
  CITY: 'cityId',
  TYPE: 'type',
  LOCATION_TYPE: 'locationType',
  LOCATION_TYPE_ID: 'locationTypeId',
  OWNER: 'ownerId',
  CATEGORY: 'categoryId',
  THEME: 'modelThemeId',
  MANUFACTURER: 'manufactureId',
  STATUS: 'statusId',
  MACHINE_TYPE: 'typeId',
  SUBLOCATION: 'subLocationId',
  MODEL: 'modelId',
  COLLECTION_SUBLOCATION: 'sublocation',
  DATE_RANGE: 'dateRange',
  UNASSIGNED_ASSET: 'unassignedModems',
  LEASE_STATUS: 'processStatus',
  ACCOUNTING_USER: 'accountingUser',
  SUBLOCATION_ID: 'sublocationId',
  PRODUCT_TYPE: 'productType',
  USER: 'user',
  COLLECTION_STATUS: 'status',
  SEEDLIVE_MISSING_DATES: 'showMissingDate',
};

// Need to change if DB value changes
export const FILTER_ACCOUNTING_ROLE = 10;
export const FILTER_REGINAL_MANAGER_ROLE = 3;

export const FILTER_CONTENT_TYPE = {
  SELECT: 'SELECT',
  DATE_RANGE_PICKER: 'DATE_RANGE_PICKER',
  TOGGLE: 'TOGGLE',
};

const FILTER_CONSTANTS = {
  DIRECTION: {
    RIGHT: 'right',
    LEFT: 'left',
    TOP: 'top',
    BOTTOM: 'bottom',
  },
  TITLE: {
    FILTERS: 'Filters',
    SORT_BY: 'Sort By',
  },
  DRAWER_TYPES: {
    SORT_DRAWER: 'SORT_DRAWER',
    FILTER_DRAWER: 'FILTER_DRAWER',
  },
  FILTER_DATA: {
    USER: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.COUNTRY]: null,
        [FILTER_VALUES.STATE]: null,
        [FILTER_VALUES.CITY]: [],
        [FILTER_VALUES.LOCATION]: [],
        [FILTER_VALUES.ROLE]: [],
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Roles',
          value: FILTER_VALUES.ROLE,
          apikey: 'roles',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Location',
          value: FILTER_VALUES.LOCATION,
          apikey: 'locations',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Country',
          value: FILTER_VALUES.COUNTRY,
          apikey: 'countries',
          multiple: false,
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'State',
          value: FILTER_VALUES.STATE,
          apikey: 'states',
          multiple: false,
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'City',
          value: FILTER_VALUES.CITY,
          apikey: 'cities',
          options: [],
        },
      ],
    },
    LOCATION: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.LOCATION_TYPE_ID]: [],
        [FILTER_VALUES.OWNER]: [],
        [FILTER_VALUES.COUNTRY]: null,
        [FILTER_VALUES.STATE]: null,
        [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
        [FILTER_VALUES.PRODUCT_TYPE]: [],
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Location Type',
          value: FILTER_VALUES.LOCATION_TYPE_ID,
          apikey: 'types',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Location Owner',
          value: FILTER_VALUES.OWNER,
          apikey: 'owners',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Country',
          value: FILTER_VALUES.COUNTRY,
          apikey: 'countries',
          multiple: false,
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'State',
          value: FILTER_VALUES.STATE,
          apikey: 'states',
          multiple: false,
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Sub Location',
          value: FILTER_VALUES.COLLECTION_SUBLOCATION,
          apikey: 'sublocations',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Product Type',
          value: FILTER_VALUES.PRODUCT_TYPE,
          apikey: 'productTypes',
          options: [],
        },
      ],
    },
    STATE: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.COUNTRY]: {},
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Country',
          value: FILTER_VALUES.COUNTRY,
          apikey: 'countries',
          multiple: false,
          options: [],
        },
      ],
    },
    MODEL: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.CATEGORY]: [],
        [FILTER_VALUES.THEME]: [],
        [FILTER_VALUES.MANUFACTURER]: [],
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Category',
          value: FILTER_VALUES.CATEGORY,
          apikey: 'modelCategories',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Theme',
          value: FILTER_VALUES.THEME,
          apikey: 'modelThemes',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Manufacturer',
          value: FILTER_VALUES.MANUFACTURER,
          apikey: 'modelManufacturers',
          options: [],
        },
      ],
    },
    MACHINE: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.STATUS]: [],
        [FILTER_VALUES.MACHINE_TYPE]: [],
        [FILTER_VALUES.SUBLOCATION]: [],
        [FILTER_VALUES.MODEL]: [],
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Status',
          value: FILTER_VALUES.STATUS,
          apikey: 'assetStatuses',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Sub Location',
          value: FILTER_VALUES.SUBLOCATION,
          apikey: 'sublocations',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Model',
          value: FILTER_VALUES.MODEL,
          apikey: 'models',
          options: [],
        },
      ],
    },
    COLLECTION_RECONCILIATION: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.LOCATION]: [],
        [FILTER_VALUES.LOCATION_TYPE]: [],
        [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
        [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
        [FILTER_VALUES.PRODUCT_TYPE]: [],
        [FILTER_VALUES.USER]: [],
      },
      ACCOUNTING_INITIAL_PARAMS: {
        [FILTER_VALUES.ACCOUNTING_USER]: [],
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.DATE_RANGE_PICKER,
          name: 'Date Range',
          value: FILTER_VALUES.DATE_RANGE,
          rangeKey: 'submitted',
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Location',
          value: FILTER_VALUES.LOCATION,
          apikey: 'locations',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Location Type',
          value: FILTER_VALUES.LOCATION_TYPE,
          apikey: 'types',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Sub Location',
          value: FILTER_VALUES.COLLECTION_SUBLOCATION,
          apikey: 'sublocations',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Product Type',
          value: FILTER_VALUES.PRODUCT_TYPE,
          apikey: 'productTypes',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Submitted By',
          value: FILTER_VALUES.USER,
          apikey: 'users',
          options: [],
        },
      ],
      ACCOUNTING_FILTER: [
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Accounting User',
          value: FILTER_VALUES.ACCOUNTING_USER,
          apikey: 'users',
          options: [],
        },
      ],
    },
    SEEDLIVE_TRACKINGS: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
        [FILTER_VALUES.SEEDLIVE_MISSING_DATES]: false,
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.DATE_RANGE_PICKER,
          name: 'Date Range',
          value: FILTER_VALUES.DATE_RANGE,
          rangeKey: 'submitted',
        },
        {
          type: FILTER_CONTENT_TYPE.TOGGLE,
          name: 'Show Missing Date',
          value: FILTER_VALUES.SEEDLIVE_MISSING_DATES,
          disabled: true,
        },
      ],
    },
    SUB_LOCATION_COLLECTION_SUBMITTED: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
        [FILTER_VALUES.LOCATION]: [],
        [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
        [FILTER_VALUES.COLLECTION_STATUS]: [],
        [FILTER_VALUES.PRODUCT_TYPE]: [],
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.DATE_RANGE_PICKER,
          name: 'Date of Submission Range',
          value: FILTER_VALUES.DATE_RANGE,
          rangeKey: 'submitted',
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Location',
          value: FILTER_VALUES.LOCATION,
          apikey: 'locations',
          options: [],
          skipAPI: true,
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Sub Location',
          value: FILTER_VALUES.COLLECTION_SUBLOCATION,
          apikey: 'sublocations',
          options: [],
          skipAPI: true,
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Status',
          value: FILTER_VALUES.COLLECTION_STATUS,
          apikey: 'status',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Product Type',
          value: FILTER_VALUES.PRODUCT_TYPE,
          apikey: 'productTypes',
          options: [],
        },
      ],
    },
    LOCATION_COLLECTION_SUBMITTED: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
        [FILTER_VALUES.LOCATION]: [],
        [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
        [FILTER_VALUES.COLLECTION_STATUS]: [],
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.DATE_RANGE_PICKER,
          name: 'Date of Reading Range',
          value: FILTER_VALUES.DATE_RANGE,
          rangeKey: 'submitted',
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Location',
          value: FILTER_VALUES.LOCATION,
          apikey: 'locations',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Sub Location',
          value: FILTER_VALUES.COLLECTION_SUBLOCATION,
          apikey: 'sublocations',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Status',
          value: FILTER_VALUES.COLLECTION_STATUS,
          apikey: 'status',
          options: [],
        },
      ],
    },
    OPEN_COLLECTION: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.LOCATION]: [],
        [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
        [FILTER_VALUES.PRODUCT_TYPE]: [],
      },
      ACCOUNTING_INITIAL_PARAMS: {
        [FILTER_VALUES.ACCOUNTING_USER]: [],
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Location',
          value: FILTER_VALUES.LOCATION,
          apikey: 'locations',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Sub Location',
          value: FILTER_VALUES.COLLECTION_SUBLOCATION,
          apikey: 'sublocations',
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Product Type',
          value: FILTER_VALUES.PRODUCT_TYPE,
          apikey: 'productTypes',
          options: [],
        },
      ],
      ACCOUNTING_FILTER: [
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Accounting User',
          value: FILTER_VALUES.ACCOUNTING_USER,
          apikey: 'users',
          options: [],
        },
      ],
    },
    ADD_COLLECTION: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Sub Location',
          value: FILTER_VALUES.COLLECTION_SUBLOCATION,
          apikey: 'sublocations',
          options: [],
        },
      ],
    },
    DEPOSIT: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.SUBLOCATION_ID]: [],
        [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.DATE_RANGE_PICKER,
          name: 'Date Range',
          value: FILTER_VALUES.DATE_RANGE,
          rangeKey: 'deposit',
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Sublocation',
          value: FILTER_VALUES.SUBLOCATION_ID,
          apikey: 'sublocations',
          options: [],
        },
      ],
    },
    LEASE: {
      PENDING_LEASE: {
        INITIAL_PARAMS: {
          [FILTER_VALUES.LEASE_STATUS]: [],
          [FILTER_VALUES.LOCATION]: [],
          [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
          [FILTER_VALUES.PRODUCT_TYPE]: [],
        },
        FILTER: [
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Status',
            value: FILTER_VALUES.LEASE_STATUS,
            options: [],
            multiple: false,
          },
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Location',
            value: FILTER_VALUES.LOCATION,
            apikey: 'locations',
            options: [],
          },
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Sub Location',
            value: FILTER_VALUES.COLLECTION_SUBLOCATION,
            apikey: 'sublocations',
            options: [],
          },
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Product Type',
            value: FILTER_VALUES.PRODUCT_TYPE,
            apikey: 'productTypes',
            options: [],
          },
        ],
      },
      ACTIVE_LEASE: {
        INITIAL_PARAMS: {
          [FILTER_VALUES.LOCATION]: [],
          [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
          [FILTER_VALUES.PRODUCT_TYPE]: [],
        },
        FILTER: [
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Location',
            value: FILTER_VALUES.LOCATION,
            apikey: 'locations',
            options: [],
          },
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Sub Location',
            value: FILTER_VALUES.COLLECTION_SUBLOCATION,
            apikey: 'sublocations',
            options: [],
          },
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Product Type',
            value: FILTER_VALUES.PRODUCT_TYPE,
            apikey: 'productTypes',
            options: [],
          },
        ],
      },
      ARCHIVE_LEASE: {
        INITIAL_PARAMS: {
          [FILTER_VALUES.LEASE_STATUS]: [],
          [FILTER_VALUES.LOCATION]: [],
          [FILTER_VALUES.COLLECTION_SUBLOCATION]: [],
          [FILTER_VALUES.PRODUCT_TYPE]: [],
        },
        FILTER: [
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Status',
            value: FILTER_VALUES.LEASE_STATUS,
            options: [],
            multiple: false,
          },
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Location',
            value: FILTER_VALUES.LOCATION,
            apikey: 'locations',
            options: [],
          },
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Sub Location',
            value: FILTER_VALUES.COLLECTION_SUBLOCATION,
            apikey: 'sublocations',
            options: [],
          },
          {
            type: FILTER_CONTENT_TYPE.SELECT,
            name: 'Product Type',
            value: FILTER_VALUES.PRODUCT_TYPE,
            apikey: 'productTypes',
            options: [],
          },
        ],
      },
    },
    LOCATION_OWNER: {
      INITIAL_PARAMS: {},
      FILTER: [],
    },
    MODEM: {
      INITIAL_PARAMS: {
        [FILTER_VALUES.TYPE]: [],
        [FILTER_VALUES.UNASSIGNED_ASSET]: [],
      },
      FILTER: [
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Type',
          value: FILTER_VALUES.TYPE,
          options: [],
        },
        {
          type: FILTER_CONTENT_TYPE.SELECT,
          name: 'Unassigned Asset',
          multiple: false,
          value: FILTER_VALUES.UNASSIGNED_ASSET,
          options: [],
        },
      ],
    },
  },
};

export default FILTER_CONSTANTS;
