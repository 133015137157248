import React, { useState, useEffect } from 'react';
import { Datepicker, GridContainer, GridItem } from '../../../components/shared';
import { MESSAGE } from '../../../constants/message';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import useStyles from '../AddCollection/style';
import { getLastCollectionDate } from '../../../utils/collection-methods';
import { checkValueNotNullUndefinedBlank, getDateFormat } from '../../../utils/common-methods';

const ApprovedSublocationDateModule = ({ sublocation }) => {
  const [dateOfReading, setDateOfReading] = useState(sublocation?.dateOfReading);
  const [dateEntered, setDateEntered] = useState(sublocation?.dateEntered);
  const classes = useStyles();
  const theme = useTheme();
  const isBelowLG = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    setDateOfReading(sublocation?.dateOfReading);
    setDateEntered(sublocation?.dateEntered);
  }, [sublocation]);
  const lastCollectionDate = getLastCollectionDate(sublocation, true);
  return (
    <GridContainer className={classes.dateContainer}>
      <GridItem xs={12} sm={12} md={12} lg={4} py={isBelowLG ? 1 : 0}>
        <Typography data-testid="accordionTitle" variant="subtitle5">
          {sublocation?.name}
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={3.5} md={4} lg={2} mr={3} my={1} className={classes.datePointerEvent}>
        <Datepicker
          label={MESSAGE.DATE_OF_READING}
          name="dateOfReading"
          id="dateOfReading"
          onChange={() => {}}
          value={dateOfReading}
          inputFormat="MM/DD/YYYY"
          disabled
        />
      </GridItem>
      <GridItem
        xs={12}
        sm={3.5}
        md={4}
        lg={2}
        mr={3}
        my={1}
        className={classes.datePointerEvent}
        onClick={(e) => e.stopPropagation()}
      >
        <Datepicker
          label={MESSAGE.DATE_ENTERED}
          name="dateEntered"
          onChange={() => {}}
          value={dateEntered}
          id="dateEntered"
          inputFormat="MM/DD/YYYY"
          disabled
        />
      </GridItem>
      <GridItem xs={12} sm={3.5} md={3} lg={3}>
        <Typography variant="subtitle2">{`Last Collection - ${
          checkValueNotNullUndefinedBlank(lastCollectionDate) ? getDateFormat(lastCollectionDate) : 'N/A'
        }`}</Typography>
      </GridItem>
    </GridContainer>
  );
};

export default React.memo(ApprovedSublocationDateModule);
