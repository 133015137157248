import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';
import {
  checkNumberIsNaNAndInfinity,
  formatNumberOrReturnZero,
  getDateFormat,
  getTruncatedDateFormat,
  isValueValid,
} from '../../utils/common-methods';
import { getAdjustedPlaycardSales, getNetCashTotal, getNetCreditCardTotal } from '../../utils/collection-methods';
import { DEFAULT_EXCHANGE_RATE } from '../../constants/CurrencyConstants';
import { INDEPENDENT_REP_OF_SUBLOCATION } from '../../constants/CommonConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { MESSAGE } from '../../constants/message';

// Export a constant that injects endpoints to the API base path.
export const extendedApi = apiBasePath.injectEndpoints({
  // All endpoints are created in the "endpoints" object.
  endpoints: (build) => ({
    // This queries the API to GET all collections based on the provided args.
    getAllCollections: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '', status } = args;
          if (status) {
            return `${apiUrl.collections}?status=${status}&perPage=${perPage || DEFAULT_PER_PAGE}&page=${
              page || DEFAULT_PAGE
            }&${searchParams}`;
          }
          return `${apiUrl.collections}?perPage=${perPage}&page=${page}&${searchParams}`;
        }
        return `${apiUrl.collections}`;
      },
      // Transforms the response data after getting all collections.
      transformResponse: (responseData) => {
        // Transformation logic
        const rawCollections = responseData?.data?.collections;
        const collections = rawCollections.map((collection) => {
          const transformedCollection = { ...collection };
          transformedCollection.serviceRep =
            transformedCollection?.user?.firstName || transformedCollection?.user?.lastName
              ? `${transformedCollection?.user?.firstName} ${transformedCollection?.user?.lastName}`
              : 'null';
          if (isValueValid(collection?.submittedDate)) {
            const submissionDate = getTruncatedDateFormat(collection.submittedDate);
            transformedCollection.submissionDate = submissionDate ?? 'N/A';
          }
          const productTypes = collection?.productCategories?.map((productCategory) => productCategory?.name);
          transformedCollection.productTypes = productTypes ?? [];
          return transformedCollection;
        });
        return {
          pagination: responseData?.pagination,
          collections: collections,
        };
      },
      providesTags: ['Locations', 'Collections'],
    }),
    // This mutation adds a new collection by making a POST request.
    addCollection: build.mutation({
      // Query logic
      query: (data) => ({
        url: `${apiUrl.collections}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Locations', 'Collections', 'Collection'],
    }),
    // This queries the API to get collections by a specific transactionId.
    getCollectionsByTransactionId: build.query({
      // Query logic
      query: (args) => {
        // Destructure transactionId from args.
        const { transactionId } = args;
        return `${apiUrl.collections}/${apiUrl.reconciliation}/${transactionId}`;
      },
      // Parameter to control how long to keep unused data.
      keepUnusedDataFor: 0,
      // Transformation logic when getting collections by a specific transactionId.
      transformResponse: (responseData) => {
        // This function transforms the response from the API.
        // Extracts the collections from the API response data.
        const { collections } = responseData.data;
        // Other variables extraction...
        const descrepancyWarnings = [];
        const location = collections[0]?.location;
        const status = collections[0]?.status;
        const currency = collections[0]?.currency;
        const exchangeRate = collections[0]?.currency?.exchangeRate?.exchangeRate || DEFAULT_EXCHANGE_RATE;
        const approvedExchangeRate = collections[0]?.exchangeRate || DEFAULT_EXCHANGE_RATE;
        // ... more extraction...
        let totalRevenue = 0;
        const transformedSublocations = collections.map((collection) => {
          // Creating a playCardSummary object for each collection based on the API response.
          let playCardSummary = {
            // Each field in the playCardSummary is formatted and derived from the collection.
            // Cash
            cashPlaycardSales: collection?.playcardCashSales ? Number(collection?.playcardCashSales) : null,
            cashRefunds: collection?.playcardCashRefunds ? Number(collection?.playcardCashRefunds) : null,
            netCashTotal: collection?.playcardCashNetTotal ? Number(collection?.playcardCashNetTotal) : null,
            // Credit Card
            salesFromCreditCard: collection?.playcardCCSales ? Number(collection?.playcardCCSales) : null,
            creditCardRefunds: collection?.playcardCCRefunds ? Number(collection?.playcardCCRefunds) : null,
            netCreditCardTotal: collection?.playcardCCNetTotal ? Number(collection?.playcardCCNetTotal) : null,
            // Total
            complimentaryPlaycards: collection?.playcardComps ? Number(collection?.playcardComps) : null,
            bulkEncodedPlaycards: collection?.playcardBulkEncoded ? Number(collection?.playcardBulkEncoded) : null,
            adjustments: collection?.playcardAdjustments ? Number(collection?.playcardAdjustments) : null,
            adjustedPlaycardSales: collection?.playcardAdjSales ? Number(collection?.playcardAdjSales) : null,
          };
          // More fields are added or updated in the playCardSummary object based on certain conditions.
          playCardSummary.netCashTotal = getNetCashTotal(playCardSummary);
          playCardSummary.netCreditCardTotal = getNetCreditCardTotal(playCardSummary);
          playCardSummary.adjustedPlaycardSales = getAdjustedPlaycardSales(playCardSummary);
          if (Object.values(playCardSummary).includes(null)) {
            playCardSummary = null;
          }
          if (isValueValid(collection?.locationRevenue)) {
            totalRevenue += checkNumberIsNaNAndInfinity(collection?.locationRevenue);
          }
          // The account summaries for each account related to a collection are mapped and saved as objects in an array.
          let accountSummary = collection?.collectionAccountNumberSummaries
            ?.filter((item) => String(item?.sublocationId) === String(collection?.sublocationId))
            ?.map((account) => ({
              id: account?.id || null,
              accountNumber: { text: account?.accountNumber?.accountNumber, value: account?.accountNumber?.id },
              name: account?.accountNumber?.accountName || '',
              description: account?.description || '',
              debit: formatNumberOrReturnZero(account?.debit) || '0.00',
              credit: formatNumberOrReturnZero(account?.credit) || '0.00',
              type: account?.accountNumber?.type || '',
              total: account?.total || 0,
              collectionId: account?.collectionId,
              sublocationId: account?.sublocationId,
            }));
          // DateOfReading and dateEntered are formatted from the collection data.
          let dateOfReading = '';
          let dateEntered = '';
          if (isValueValid(collection?.dateOfReading) && getDateFormat(collection?.dateOfReading)) {
            dateOfReading = getDateFormat(collection?.dateOfReading);
          }
          if (isValueValid(collection?.dateEntered) && getDateFormat(collection?.dateEntered)) {
            dateEntered = getDateFormat(collection?.dateEntered);
          }

          const userDepositAccountNumber = collection?.user?.depositAccountNumber || {};

          // Update subLocationId using collection sublocation id to avoid asset assigned to other sublocations
          const updatedAssets = collection?.sublocation.assets.map((asset) => {
            const transformedAssetMeter = asset.assetMeters.map((meter) => {
              const warnings = [];
              const meterName = meter?.meter?.name || '';
              if (['Credit', 'Token changer'].includes(meterName) && meter?.modemId === null) {
                if (meter?.meter?.name !== 'Token changer' || meter?.machineAcceptedPaymentType !== 'CASH') {
                  descrepancyWarnings.push(`${meter?.meter?.name} meter of Asset ${asset.id} has no modem attached`);
                  warnings.push(`• ${MESSAGE.MODEMS_NOT_ATTACHED_MESSAGE}`);
                }
              }
              if (['Credit', 'Cash'].includes(meterName) && (asset.costPerPlay === '0.00' || asset.costPerPlay == 0)) {
                warnings.push('• The cost per play is set to 0 for this asset');
                descrepancyWarnings.push(`The cost per play is set to 0 for the asset ${asset.id}`);
              }
              if (
                ['Credit', 'Cash'].includes(meterName) &&
                (asset.clicksPerPlay === '0.00' || asset.clicksPerPlay == 0)
              ) {
                warnings.push('• The clicks per play is set to 0 for this asset');
                descrepancyWarnings.push(`The clicks per play is set to 0 for the asset ${asset.id}`);
              }
              return { ...meter, warnings };
            });
            const transformedAsset = { ...asset };
            transformedAsset.subLocationId = collection?.sublocation?.id;
            transformedAsset.assetMeters = transformedAssetMeter;
            return transformedAsset;
          });

          // Returns detailed sublocation object inserted in transformedSublocations array for each collection in the input collections array.
          return {
            collectionId: collection?.id,
            ...collection?.sublocation,
            assets: updatedAssets,
            lastCollection: collection?.lastCollection,
            dateOfReading: dateOfReading,
            dateEntered: dateEntered,
            playCardSumDetails: playCardSummary,
            accountSumDetails: accountSummary,
            salesTax: {
              areaTaxRate: collection?.sublocation?.areaTaxRate || 0,
              miscTax: collection?.sublocation?.miscTax || 0,
              stateTaxRate: collection?.sublocation?.stateTaxRate || 0,
            },
            variableRent: collection?.variableRent || '',
            userDepositAccountNumber,
            transactionId: collection?.transactionId,
            isIntercardSublocation: collection?.isIntercardSublocation,
            independentRep: collection?.independentRep,
            [`${INDEPENDENT_REP_OF_SUBLOCATION.REP_ID}_${collection?.sublocation?.id}`]: collection?.independentRepId,
            [`${INDEPENDENT_REP_OF_SUBLOCATION.PAY_CODE}_${collection?.sublocation?.id}`]:
              collection?.independentRepsPaycode,
            [`${INDEPENDENT_REP_OF_SUBLOCATION.RATE}_${collection?.sublocation?.id}`]: collection?.independentRepsRate,
            [`${INDEPENDENT_REP_OF_SUBLOCATION.FEE}_${collection?.sublocation?.id}`]: collection?.independentRepsFee,
          };
        });
        const totalAssets = transformedSublocations.flatMap((sub) => sub.assets)?.length;
        let submissionDate = 'N/A';
        if (isValueValid(collections[0]?.submittedDate) && getTruncatedDateFormat(collections[0]?.submittedDate)) {
          submissionDate = getTruncatedDateFormat(collections[0]?.submittedDate);
        }
        return {
          location: location,
          status: status,
          descrepancyWarnings: [...new Set(descrepancyWarnings)],
          currency: { ...currency, exchangeRate },
          approvedExchangeRate: approvedExchangeRate,
          totalAssets: totalAssets,
          totalRevenue: totalRevenue,
          submissionDate: submissionDate,
          sublocations: transformedSublocations,
          pagination: responseData?.pagination,
        };
      },
      providesTags: ['Locations', 'Collections', 'Collection'],
    }),
    // This mutation updates collections by a specific transactionId via a PUT request.
    updateCollectionsByTransactionId: build.mutation({
      // Mutation logic
      query: ({ transactionId, ...rest }) => ({
        url: `${apiUrl.collections}/${apiUrl.reconciliation}/${transactionId}`,
        method: 'PUT',
        body: rest,
      }),
    }),
    // This mutation deletes collections by a specific transactionId via a DELETE request.
    deleteCollectionsByTransactionId: build.mutation({
      // Mutation logic
      query: (transactionId) => ({
        url: `${apiUrl.collections}/${apiUrl.reconciliation}/${transactionId}`,
        method: 'DELETE',
      }),
    }),
    // This queries the API to get collections by a specific sublocationId.
    getCollectionsBySublocationId: build.query({
      // Query logic
      query: (args) => {
        const { sublocationId, dateOfReading, transactionId } = args;
        return `${apiUrl.collections}/sublocation/${sublocationId}?dateOfReading=${dateOfReading}&transactionId=${transactionId}`;
      },
      transformResponse: (responseData) => responseData?.data,
    }),
    // This queries the API to GET all report issues.
    getAllReportIssues: build.query({
      query: () => `${apiUrl.collections}/report/issues`,
    }),
    // This mutation verifies collections by a transactionId via a PUT request.
    // Mutation logic
    verifyCollectionsByTransactionId: build.mutation({
      query: ({ transactionId, ...rest }) => ({
        url: `${apiUrl.collections}/${transactionId}/verify`,
        method: 'PUT',
        body: rest,
      }),
    }),
    // This mutation updates a report by a specific issueId via a PUT request.
    updateReportByIssueId: build.mutation({
      // Mutation logic
      query: ({ id, ...rest }) => ({
        url: `${apiUrl.collections}/report/${id}`,
        method: 'PUT',
        body: rest,
      }),
    }),
    // This mutation reopens collections by a transactionId via a PUT request.
    reOpenCollectionsByTransactionId: build.mutation({
      // Mutation logic
      query: (transactionId) => ({
        url: `${apiUrl.collections}/${transactionId}/reopen`,
        method: 'PUT',
      }),
    }),
    // This mutation updates missing assets by a transactionId and sublocationId via a PUT request.
    updateMissingAssetsByTransactionId: build.mutation({
      // Mutation logic
      query: ({ transactionId, sublocationId, ...rest }) => ({
        url: `${apiUrl.collections}/${transactionId}/${sublocationId}/add-asset`,
        method: 'PUT',
        body: rest,
      }),
    }),
    // Update Collection Comment
    updateComment: build.mutation({
      query: ({ transactionId, commentId, ...rest }) => ({
        url: `${apiUrl.collections}/${transactionId}/comment/${commentId}/update`,
        method: 'PUT',
        body: rest,
      }),
    }),
    // Delete Collection Comment
    deleteComment: build.mutation({
      query: ({ transactionId, commentId }) => ({
        url: `${apiUrl.collections}/${transactionId}/comment/${commentId}/remove`,
        method: 'DELETE',
      }),
    }),
    // Delete Asset from Collection
    deleteAssetFromCollection: build.mutation({
      query: ({ transactionId, collectionId, assetId }) => ({
        url: `${apiUrl.collections}/${apiUrl.reconciliation}/${transactionId}/sublocation/${collectionId}/asset/${assetId}`,
        method: 'DELETE',
      }),
    }),
    // Delete sublocation from Collection
    deleteSublocationFromCollection: build.mutation({
      query: ({ transactionId, collectionId }) => ({
        url: `${apiUrl.collections}/${apiUrl.reconciliation}/${transactionId}/sublocation/${collectionId}`,
        method: 'DELETE',
      }),
    }),
    getSubmittedCollectionsForSubLocations: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;

          return `${apiUrl.collections}/${apiUrl.sublocationbyIR}?&perPage=${perPage || DEFAULT_PER_PAGE}&page=${
            page || DEFAULT_PAGE
          }&${searchParams}`;
        }
      },
      // Transforms the response data after getting all collections.
      transformResponse: (responseData) => responseData,
    }),
    getSubmittedCollectionsForLocations: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;

          return `${apiUrl.collections}/${apiUrl.locationbyIR}?&perPage=${perPage || DEFAULT_PER_PAGE}&page=${
            page || DEFAULT_PAGE
          }&${searchParams}`;
        }
      },
      // Transforms the response data after getting all collections.
      transformResponse: (responseData) => responseData,
    }),
    bulkUpdateDateEntered: build.mutation({
      // Query logic
      query: (data) => ({
        url: `${apiUrl.collections}/bulk-update-date-entered`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Locations', 'Collections', 'Collection'],
    }),

    // Update Approved Collection AccountSummaryNotes
    updateNotes: build.mutation({
      query: (accountSummaryState) => ({
        url: `${apiUrl.collections}/${apiUrl.accountSummaryNotes}`,
        method: 'PUT',
        body: accountSummaryState,
      }),
    }),

    //Get Collection Account Summary Notes History by collectionId and sublocationID
    getAccountSummaryNotesHistory: build.query({
      query: (args) => {
        const { collectionId, sublocationId } = args;
        return `${apiUrl.collections}/${apiUrl.accountSummaryNoesHistory}/${collectionId}/${sublocationId}`;
      },
      transformResponse: (responseData) => {
        const accountSummaryNotesHistory = [...responseData.accountHistoryData]
          //sorting data according to createdAt and deletedAt
          .sort((a, b) => {
            const isADeleted = a[0]?.deletedAt === null;
            const isBDeleted = b[0]?.deletedAt === null;

            // If one has deletedAt: null, put it before the one that doesn't
            if (isADeleted && !isBDeleted) return -1;
            if (!isADeleted && isBDeleted) return 1;

            // Otherwise, sort by createdAt (newest first)
            const timeA = new Date(a[0]?.createdAt || 0).getTime();
            const timeB = new Date(b[0]?.createdAt || 0).getTime();

            return timeB - timeA;
          })
          .map((array) =>
            array.map((item) => ({
              id: item.id || null,
              accountNumber: {
                text: item?.accountNumber?.accountNumber || '',
                value: item?.accountNumberId || null,
              },
              name: item?.accountNumber?.accountName || '',
              description: item.description || '',
              debit: (item.debit || 0).toFixed(2),
              credit: (item.credit || 0).toFixed(2),
              type: '',
              total: item.total || 0,
              createdAt: item.createdAt || '',
              updatedAt: item.updatedAt || '',
              updatedBy: item.updatedById || '',
              userName: `${item?.user?.firstName || ''} ${item?.user?.lastName || ''}`,
            })),
          );
        return accountSummaryNotesHistory;
      },
    }),

    overrideExisting: false,
  }),
});

export const {
  useAddCollectionMutation,
  useGetAllCollectionsQuery,
  useGetCollectionsByTransactionIdQuery,
  useUpdateCollectionsByTransactionIdMutation,
  useDeleteCollectionsByTransactionIdMutation,
  useGetCollectionsBySublocationIdQuery,
  useGetAllReportIssuesQuery,
  useVerifyCollectionsByTransactionIdMutation,
  useUpdateReportByIssueIdMutation,
  useReOpenCollectionsByTransactionIdMutation,
  useUpdateMissingAssetsByTransactionIdMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useGetSubmittedCollectionsForSubLocationsQuery,
  useGetSubmittedCollectionsForLocationsQuery,
  useDeleteAssetFromCollectionMutation,
  useDeleteSublocationFromCollectionMutation,
  useBulkUpdateDateEnteredMutation,
  useUpdateNotesMutation,
  useGetAccountSummaryNotesHistoryQuery,
} = extendedApi;
