import React, { useState, useEffect } from 'react';
import { Autocomplete, Button, GridContainer, GridItem, Modal } from '../../../components/shared';
import { Box, Grid, Chip, Typography } from '@mui/material';
import { MESSAGE } from '../../../constants/message';
import {
  useGetAllSublocationsQuery,
  useUpdateSublocationVendorByIdMutation,
} from '../../../services/Sublocation/SublocationService';
import { useDebounce } from '../../../hooks';
import ConfirmationModal from '../../../components/ConfirmationModel';

const AssignUnassignSublocation = (props) => {
  const { selectedVendor, handleClose, handleConfirm } = props;
  const [sublocationSearchText, setSublocationSearchText] = useState('');
  const [allSelectedSublocations, setAllSelectedSublocations] = useState([]);
  const [previousSublocations, setPreviousSublocations] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sublocationToDelete, setSublocationToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const debouncedSearchText = useDebounce(sublocationSearchText, 600);
  const [updateSublocationVendorById] = useUpdateSublocationVendorByIdMutation();
  const { currentData: sublocationsResponse } = useGetAllSublocationsQuery({
    searchParams: `q=${debouncedSearchText}`,
    perPage: 100,
  });

  useEffect(() => {
    const isSame =
      allSelectedSublocations.length === previousSublocations.length &&
      allSelectedSublocations.every((s) => previousSublocations.some((p) => p.id === s.id));
    setIsSaveDisabled(isSame);
  }, [allSelectedSublocations, previousSublocations]);

  useEffect(() => {
    if (selectedVendor) {
      const vendorSublocations = selectedVendor?.sublocations || [];
      setAllSelectedSublocations(vendorSublocations);
      setPreviousSublocations(vendorSublocations);
    }
  }, [selectedVendor]);

  const handleSave = async () => {
    try {
      const sublocationIds = allSelectedSublocations.map((s) => s.id);

      const response = await updateSublocationVendorById({
        id: selectedVendor.id,
        body: {
          id: selectedVendor.id,
          vendorCode: selectedVendor.vendorCode,
          sublocationIds,
        },
      });

      if (response.error && response.error.status === 409) {
        setErrorMessage(response.error.data?.message || 'Conflict error: Sublocation already assigned.');
        setShowErrorModal(true);
      } else {
        handleConfirm();
      }
    } catch (error) {
      console.error('Error updating vendor:', error);
    }
  };

  const handleDeleteSublocation = (sublocation) => {
    setSublocationToDelete(sublocation);
    setShowDeleteModal(true);
  };

  const onDeleteModalClickHandler = (value) => {
    if (value === MESSAGE.DELETE) {
      setAllSelectedSublocations((prev) => prev.filter((s) => s.id !== sublocationToDelete.id));
    }
    setShowDeleteModal(false);
    setSublocationToDelete(null);
  };

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
    setAllSelectedSublocations(previousSublocations);
  };

  return (
    <>
      <Modal
        open={Boolean(selectedVendor)}
        onClose={handleClose}
        title={
          <Typography variant="subtitle10" sx={{ whiteSpace: 'pre-line', lineHeight: 1.5 }}>
            Assign/Unassign Sublocation for {selectedVendor?.customerName}
          </Typography>
        }
        titleVariant="subtitle10"
        actionButtons={
          <Box my={3}>
            <Button variant="contained" size="large" onClick={handleSave} disabled={isSaveDisabled}>
              {MESSAGE.CONFIRM}
            </Button>
            <Button size="large" onClick={handleClose} variant="text">
              {MESSAGE.CANCEL}
            </Button>
          </Box>
        }
        maxWidth={'sm'}
      >
        <Grid>
          <GridContainer>
            <GridItem xs={12}>
              <Autocomplete
                value={allSelectedSublocations}
                options={[...allSelectedSublocations, ...(sublocationsResponse || [])]}
                onChange={(_, value) => {
                  if (_.code === 'Backspace') return;
                  setAllSelectedSublocations((prev) =>
                    Array.from(new Map([...prev, ...value].map((s) => [s.id, s])).values()),
                  );
                  setSublocationSearchText('');
                }}
                data-testid="leaseDetailsForm"
                label="Sub Location"
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                multiple
                inputValue={sublocationSearchText}
                onInputChange={(_, value, reason) => {
                  if (reason !== 'reset' && reason !== 'clear') {
                    setSublocationSearchText(value);
                  }
                }}
                filterSelectedOptions
                renderTags={() => {}}
              />
            </GridItem>
            <GridItem xs={12} mt={2}>
              {allSelectedSublocations.map((sublocation) => (
                <Chip
                  key={sublocation.id}
                  label={sublocation.name}
                  onDelete={() => handleDeleteSublocation(sublocation)}
                  sx={{ margin: '2px' }}
                />
              ))}
            </GridItem>
          </GridContainer>
        </Grid>
      </Modal>

      <ConfirmationModal
        isOpen={showDeleteModal}
        title={MESSAGE.CONFIRMATION}
        msg={`Are you sure you want to delete ${sublocationToDelete?.name}?`}
        buttons={[
          { text: MESSAGE.DELETE, value: MESSAGE.DELETE },
          { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
        ]}
        onClick={onDeleteModalClickHandler}
      />
      <ConfirmationModal
        isOpen={showErrorModal}
        title="Already Assigned"
        msg={errorMessage}
        buttons={[{ text: MESSAGE.OK, value: MESSAGE.OK }]}
        onClick={handleErrorModalClose}
      />
    </>
  );
};

export default React.memo(AssignUnassignSublocation);
