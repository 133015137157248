import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography, Paper, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridItem, GridContainer, Button, Link } from '../../components/shared';
import SearchBar from '../../components/SearchBar';
import { routeConstants } from '../../constants/routeConstants';
import VendorsTable from './ChildComponents/VendorsTable';
import { useGetVendorsQuery, useUpdateStatusVendorByIdMutation } from '../../services/Vendors/VendorService';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import EditVendor from './ChildComponents/EditVendor';
import ConfirmationModal from '../../components/ConfirmationModel';
import { MESSAGE } from '../../constants/message';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchText } from '../../redux-slice/vendor';

const displayName = 'Vendors';

const Vendors = () => {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [perPageNumber, setPerPageNumber] = useState(DEFAULT_PER_PAGE);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorToUpdate, setVendorToUpdate] = useState(null);
  const [updateStatusVendorById] = useUpdateStatusVendorByIdMutation();
  const dispatch = useDispatch();
  const [searchTextState, setSearchTextState] = useState('');
  const searchText = useSelector((state) => state.vendor.searchText);
  const searchParams = useMemo(() => {
    let searchParams = '&sort=customerName&order=AESC';
    if (searchText) {
      searchParams += `&q=${searchText}`;
    }
    return searchParams;
  }, [searchText]);

  useEffect(() => {
    setSearchTextState(searchText);
  }, [searchText]);

  const vendorListResponse = useGetVendorsQuery({
    searchParams: searchParams,
    page: currentPage,
    perPage: perPageNumber,
  });

  const handleOpenEditModal = useCallback((vendor) => {
    if (vendor && vendor.status === 'ACTIVE') {
      setSelectedVendor({ ...vendor });
    } else {
      setVendorToUpdate(vendor);
    }
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setSelectedVendor(null);
  }, []);

  const handleConfirmVendorStatusChange = async () => {
    try {
      await updateStatusVendorById(vendorToUpdate.id);
    } catch (error) {
      console.error('Error updating vendor status:', error);
    }
    setVendorToUpdate(null);
  };

  const viewLink = useCallback(
    (name, vendor) => (
      <Link>
        <Box
          onClick={() => handleOpenEditModal(vendor)}
          sx={{
            cursor: 'pointer',
            color: vendor.status === 'ACTIVE' ? theme.palette.common.black : theme.palette.error.main,
          }}
        >
          <Typography variant="subtitle2">{name}</Typography>
        </Box>
      </Link>
    ),
    [handleOpenEditModal, theme.palette.common.black, theme.palette.error.main],
  );

  useEffect(() => {
    if (vendorListResponse?.isSuccess && vendorListResponse?.currentData) {
      const latestData = vendorListResponse.currentData;
      const vendors = latestData.vendors.map((vendor) => ({
        ...vendor,
        customerName: vendor.customerName,
        customerNameElement: viewLink(vendor.customerName, vendor),
      }));
      setTableData(vendors);
      setTotalCount(latestData?.pagination?.total || 0);
    } else if (vendorListResponse?.isError) {
      setTotalCount(0);
    }
  }, [vendorListResponse, viewLink]);

  const searchHandler = (params) => {
    dispatch(setSearchText(params));
  };

  const onReset = () => {
    dispatch(setSearchText(''));
  };

  const pageChangeHandler = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    setPerPageNumber(newPerPage);
  };

  return (
    <React.Fragment>
      <GridContainer marginBottom={1} justifyContent={'space-between'}>
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant="h1">{displayName}</Typography>
          </GridItem>
          <Typography variant="subtitle2">{`${totalCount ? `${totalCount} Vendors` : ''}`}</Typography>
        </GridContainer>
      </GridContainer>

      <Box component={Paper}>
        <Box display={'flex'} marginBottom={1} padding={2} gap={2}>
          <Box width={'100%'}>
            <SearchBar searchText={searchTextState} onSearch={searchHandler} onReset={onReset} />
          </Box>
          <Box>
            <Button
              component={Link}
              to={`/${routeConstants.VENDORS_ROUTE}/add`}
              variant="contained"
              data-testid="add-location-btn"
              startIcon={<AddIcon />}
              size="large"
            >
              ADD NEW
            </Button>
          </Box>
        </Box>
        <VendorsTable
          vendorListResponse={vendorListResponse}
          tableData={tableData}
          totalCount={totalCount}
          currentPage={currentPage}
          perPageNumber={perPageNumber}
          pageChangeHandler={pageChangeHandler}
          perPageChangeHandler={perPageChangeHandler}
        />
      </Box>

      {selectedVendor && (
        <EditVendor
          selectedVendor={selectedVendor}
          handleClose={handleCloseEditModal}
          handleConfirm={() => setSelectedVendor(null)}
        />
      )}

      {vendorToUpdate && (
        <ConfirmationModal
          isOpen={true}
          title={MESSAGE.CONFIRMATION}
          msg={`This vendor is inactive. Do you want to activate ${vendorToUpdate?.customerName}?`}
          buttons={[
            { text: MESSAGE.CONFIRM, value: MESSAGE.CONFIRM },
            { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
          ]}
          onClick={(value) => {
            if (value === 'CONFIRM') {
              handleConfirmVendorStatusChange(vendorToUpdate);
            }
            setVendorToUpdate(null);
          }}
        />
      )}
    </React.Fragment>
  );
};

Vendors.displayName = displayName;
export default Vendors;
